import { useTranslation } from 'react-i18next';
import purchasesTranslateImg from 'assets/images/buy/buyPurchasesTranslate.png';
import React from 'react';
import shape1 from 'assets/images/buy/shapes/purchasesTranslate/shape1.svg';
import shape2 from 'assets/images/buy/shapes/purchasesTranslate/shape2.svg';
import shape3 from 'assets/images/buy/shapes/purchasesTranslate/shape3.svg';
import shape4 from 'assets/images/buy/shapes/purchasesTranslate/shape4.svg';
import PhotoTextSection from '../../components/commons/PhotoTextSection';
import ShortSectionContainer from '../../components/containers/ShortSectionContainer';

const EveryDollarSection = () => {
  const { t } = useTranslation('buy');

  return (
    <ShortSectionContainer id={'buy-purchases-translate'}>
      <PhotoTextSection
        sectionClassName={'buy-every-dollar'}
        title={t('every-dollar.title')}
        description={t('every-dollar.description')}
        image={{ source: purchasesTranslateImg, dimensions: { height: 550, width: 720 } }}
        reversed={true}
        margin={'medium'}
        shapes={[
          { source: shape1, dimensions: { left: 507, top: 172, width: 426, height: 494 } },
          { source: shape2, dimensions: { left: 409, top: 496, width: 179, height: 178 } },
          { source: shape3, dimensions: { left: -153, top: -109, width: 255, height: 277 } },
          { source: shape4, dimensions: { left: 83, top: -84, width: 64, height: 63 } },
        ]}
      />
    </ShortSectionContainer>
  );
};

export default EveryDollarSection;
