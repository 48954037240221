import { useTranslation } from 'react-i18next';
import colors from 'assets/styles/colors';
import styled from 'styled-components';
import savePlanetImg from '../../../assets/images/home/savePlanet/sectionImg-mobile.png';
import { refFontSize, refFontSizeLandscape, refWidth, refWidthLandscape } from '../../utils/dimensions';
import { mq } from '../../../assets/styles/mediaQuery';
import MainPageButton from '../../components/buttons/MainPageButton';
import { fonts } from '../../../assets/styles/fonts';
import { useSingleElementOrphanPrevention } from '../../../commons/utils/useOrphanPrevention';
import { useNavigation } from '../../utils/useNavigation';

const Container = styled.div`
  padding: ${refWidth(50)}vw 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.landscape} {
    padding: ${refWidthLandscape(50)}vw 0;
  }
`;

const ImageWrapper = styled.div`
  margin-bottom: ${refWidth(60)}vw;

  ${mq.landscape} {
    margin-bottom: ${refWidthLandscape(60)}vw;
  }
`;

const Image = styled.img`
  width: ${refWidth(315)}vw;

  ${mq.landscape} {
    width: ${refWidthLandscape(315)}vw;
  }
`;

const TextWrapper = styled.div`
  width: ${refWidth(315)}vw;

  ${mq.landscape} {
    width: ${refFontSizeLandscape(315)}vw;
  }
`;

const Title = styled.div`
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(40)}vw;
  line-height: 1em;
  color: ${colors.cello};
  text-align: center;
  margin-bottom: ${refWidth(33)}vw;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(40)}vw;
    margin-bottom: ${refWidthLandscape(33)}vw;
  }
`;

const Description = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(14)}vw;
  line-height: 1.72em;
  color: ${colors.fadedJade};
  text-align: center;
  margin-bottom: ${refWidth(33)}vw;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(14)}vw;
    margin-bottom: ${refWidthLandscape(33)}vw;
  }
`;

const SaveSection = () => {
  const { t } = useTranslation('home');
  const { navigate } = useNavigation();
  useSingleElementOrphanPrevention('save-planet-desc');

  const onLearnMoreClick = () => navigate('/save-the-planet');

  return (
    <Container>
      <ImageWrapper>
        <Image src={savePlanetImg} />
      </ImageWrapper>
      <TextWrapper>
        <Title>{t('save-planet.title')}</Title>
        <Description id={'save-planet-desc'}>{t('save-planet.description')}</Description>
      </TextWrapper>
      <MainPageButton color={colors.red} onClick={onLearnMoreClick} translation={t('learn-more')} />
    </Container>
  );
};

export default SaveSection;
