import React from 'react';
import styled from 'styled-components';
import { isTablet } from 'react-device-detect';
import { refHeight, refWidth } from '../utils/dimensions';
import { mq } from '../../assets/styles/mediaQuery';
import colors from '../../assets/styles/colors';
import { AppStoreButton, GooglePlayButton } from '../components/buttons/StoreButtons';
import PhoneInputSection from '../components/inputs/PhoneInputSection';
import BulletDeviceDownload from '../components/download/BulletDeviceDownload';

const Container = styled.div`
  padding: ${refHeight(30)}vh ${refWidth(isTablet ? 110 : 30)}vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.landscape} {
    padding: ${refHeight(30)}vh ${refWidth(60)}vw;
  }

  background-color: ${colors.red};
`;

const ButtonsWrapper = styled.div`
  padding-top: ${refHeight(30)}vh;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: ${refHeight(26)}vh;
`;

interface Props {
  title: string;
  subtitle: string;
  downloadTitle: string;
}

const DownloadSection = ({ title, subtitle, downloadTitle }: Props) => {
  return (
    <Container>
      <BulletDeviceDownload title={title} description={subtitle} downloadTitle={downloadTitle} />
      <ButtonsWrapper>
        <AppStoreButton width={150} />
        <GooglePlayButton width={150} />
      </ButtonsWrapper>
      <PhoneInputSection buttonColor={colors.cello} />
    </Container>
  );
};

export default DownloadSection;
