import styled from 'styled-components';
import { refWidth } from 'desktop/utils/dimensions';
import bodyRed from 'assets/images/mouse/red/mouse-body.svg';
import buttonRed from 'assets/images/mouse/red/mouse-button.svg';
import bodyTransparent from 'assets/images/mouse/transparent/mouse-body.svg';
import buttonTransparent from 'assets/images/mouse/transparent/mouse-button.svg';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { MouseVariant } from './types';

const Container = styled.div`
  width: ${refWidth(20)}vw;
  height: ${refWidth(32)}vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MouseElement = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
`;

interface Props {
  id?: string;
  variant: MouseVariant;
}

const MouseIcon = ({ id, variant }: Props) => {
  const buttonRef = useRef<HTMLImageElement>(null);

  const body = variant === 'red' ? bodyRed : bodyTransparent;
  const button = variant === 'red' ? buttonRed : buttonTransparent;

  useEffect(() => {
    const timeline = gsap.timeline({ repeat: -1, yoyo: true });
    timeline.to(buttonRef.current, { yPercent: 15, ease: 'power1.inOut', duration: 1 });

    return () => {
      timeline.kill();
    };
  }, []);

  return (
    <Container id={id}>
      <MouseElement src={body} />
      <MouseElement ref={buttonRef} src={button} />
    </Container>
  );
};

export default MouseIcon;
