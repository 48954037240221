import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    common: require('./en/common.json'),
    home: require('./en/home.json'),
    fundraise: require('./en/fundraise.json'),
    buy: require('./en/buy.json'),
    contribute: require('./en/contribute.json'),
    about: require('./en/about.json'),
    faq: require('./en/faq.json'),
    saveThePlanet: require('./en/saveThePlanet.json'),
    privacyPolicy: require('./en/privacy.json'),
    terms: require('./en/terms.json'),
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  defaultNS: 'common',
  interpolation: { escapeValue: false },
  react: { useSuspense: false },
});

export default i18n;
