import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Loupe } from 'assets/images/loupe.svg';
import colors from 'assets/styles/colors';
import { animations } from 'assets/styles/animations';
import { fonts } from 'assets/styles/fonts';
import { mq } from 'assets/styles/mediaQuery';
import { refFontSize, refFontSizeLandscape, refWidth, refWidthLandscape } from '../../utils/dimensions';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIcon = styled(Loupe)`
  position: absolute;
  right: ${refWidth(10)}vw;
  width: ${refWidth(25)}vw;
  fill: ${colors.gullGray};
  transition-duration: ${animations.standardDuration};
  cursor: pointer;

  ${mq.landscape} {
    right: ${refWidth(60)}vw;
  }
`;

const StyledInput = styled.input`
  width: ${refWidth(300)}vw;
  height: ${refWidth(30)}vw;
  border: 1px solid transparent;
  border-radius: ${refWidth(30)}vw;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(14)}vw;
  line-height: 1.5em;
  padding-left: ${refWidth(16)}vw;
  color: ${colors.cello};
  outline: none;
  background-color: ${colors.porcelain};
  transition-duration: ${animations.standardDuration};

  ${mq.landscape} {
    width: ${refWidthLandscape(300)}vw;
    height: ${refWidthLandscape(30)}vw;
    font-size: ${refFontSizeLandscape(14)}vw;
    padding-left: ${refWidthLandscape(16)}vw;
  }
`;

interface Props {
  value: string;
  onChange: (value: string) => void;
  onIconClick: () => void;
}

const SearchInput = (props: Props) => {
  return (
    <Container>
      <StyledInput value={props.value} onChange={e => props.onChange(e.target.value)} />
      <StyledIcon onClick={props.onIconClick} />
    </Container>
  );
};

export default SearchInput;
