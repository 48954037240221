import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import img1 from 'assets/images/download/walkthrough-1.png';
import img2 from 'assets/images/download/walkthrough-2.png';
import img3 from 'assets/images/download/walkthrough-3.png';
import img4 from 'assets/images/download/walkthrough-4.png';
import img5 from 'assets/images/download/walkthrough-5.png';
import arrow from 'assets/images/arrow.svg';
import { refWidth } from 'desktop/utils/dimensions';
import ScrollTrigger from 'gsap/ScrollTrigger';
import DeviceMockup from '../device/DeviceMockup';
import { useScrollbar } from '../containers/ScrollbarProvider';
import useWindowDimensions from '../../utils/useWindowDimensions';

const DEVICE_WIDTH = 352;
const DEVICE_HEIGHT = 715;

const DeviceContainer = styled.div<{ topOffset: number }>`
  position: relative;
  width: ${refWidth(DEVICE_WIDTH)}vw;
  height: ${refWidth(DEVICE_HEIGHT)}vw;
  left: ${refWidth(305)}vw;
  transform: translateY(${props => props.topOffset}px);
`;

const SlidesList = styled.div`
  position: relative;
  width: ${refWidth(310, DEVICE_WIDTH)}%;
  margin-left: ${refWidth(22)}vw;
  height: 100%;
  overflow: hidden;
`;

const SlidesListItem = styled.img`
  position: absolute;
  opacity: 0;
`;

const ArrowButton = styled.div`
  border-radius: 50%;
  width: ${refWidth(72)}vw;
  height: ${refWidth(72)}vw;
  cursor: pointer;
  position: absolute;
  transition: 0.1s;
  top: ${refWidth(210)}vw;
`;

const NextButton = styled(ArrowButton)`
  right: -${refWidth(105)}vw;

  &:hover {
    width: ${refWidth(82)}vw;
    top: ${refWidth(205)}vw;
    right: -${refWidth(110)}vw;
  }
`;

const PrevButton = styled(ArrowButton)`
  left: -${refWidth(105)}vw;
  transform: rotate(180deg);

  &:hover {
    width: ${refWidth(82)}vw;
    top: ${refWidth(215)}vw;
    left: -${refWidth(110)}vw;
  }
`;

const slides = [img1, img2, img3, img4, img5];

const SLIDE_TIME = 1;
const TRANSITION_TIME = 3;

const WalkthroughSlider = () => {
  const slideImgRefList = useRef<HTMLDivElement>(null);
  const activeSlideRef = useRef<number>(0);
  const prevButtonRef = useRef<HTMLDivElement>(null);
  const nextButtonRef = useRef<HTMLDivElement>(null);
  const [topOffset, setTopOffset] = useState<number>(0);
  const { useScrollReady } = useScrollbar();
  const { width, height } = useWindowDimensions();

  const [sliderTimeline] = useState<gsap.core.Timeline>(
    gsap.timeline({
      repeat: -1,
      onStart: () => {
        for (let i = 0; i < slides.length; i++) {
          slideImgRefList.current &&
            sliderTimeline.set(
              slideImgRefList.current.children[i],
              { xPercent: i === 0 ? 0 : 105, opacity: 1, duration: 0 },
              0
            );
        }
      },
    })
  );

  useEffect(() => {
    const sectionContainer = document.getElementById('download-section-container');
    const deviceContainer = document.getElementById('download-device-container');
    if (sectionContainer && deviceContainer)
      setTopOffset(sectionContainer.clientHeight - deviceContainer.clientHeight * 0.92);
  }, [width, height]);

  const setupSliderAnimation = () => {
    const slideNext = (index: number) => {
      const nextIndex = index === slides.length - 1 ? 0 : index + 1;
      const timeline = gsap.timeline({
        onReverseComplete: () => {
          const label = `slide_${index}-=${TRANSITION_TIME - SLIDE_TIME}`;
          sliderTimeline.seek(label);
          sliderTimeline.play();
        },
        onStart: () => {
          activeSlideRef.current = nextIndex;
        },
      });
      slideImgRefList.current &&
        timeline
          .fromTo(
            slideImgRefList.current.children[index],
            { xPercent: 0 },
            { xPercent: -100, duration: SLIDE_TIME, ease: 'power3.inOut' },
            0
          )
          .to(slideImgRefList.current.children[index], { xPercent: 105, duration: 0 });
      slideImgRefList.current &&
        timeline.fromTo(
          slideImgRefList.current.children[nextIndex],
          { xPercent: 100 },
          { xPercent: 0, duration: SLIDE_TIME, ease: 'power3.inOut' },
          0
        );
      return timeline;
    };
    for (let i = 0; i < slides.length; i++) {
      sliderTimeline.addLabel(`slide_${i}`, `>${TRANSITION_TIME}`);
      sliderTimeline.add(slideNext(i), `>${TRANSITION_TIME}`);
    }
    sliderTimeline.pause();
  };

  const setupAnimations = () => {
    setupSliderAnimation();

    const buttonsTimeline = gsap
      .timeline({
        scrollTrigger: { trigger: '#download-section', start: '20% center', end: '50% center', scrub: true },
      })
      .fromTo(
        prevButtonRef.current,
        { opacity: 0, duration: 0.5, yPercent: 20 },
        { opacity: 1, duration: 0.5, yPercent: 0 }
      )
      .fromTo(
        nextButtonRef.current,
        { opacity: 0, duration: 0.5, yPercent: 20 },
        { opacity: 1, duration: 0.5, yPercent: 0 }
      );

    const sliderStart = () => sliderTimeline.play();
    const sliderStop = () => sliderTimeline.pause();

    const triggerGoal = ScrollTrigger.create({
      trigger: '#download-section',
      start: '-20% center',
      end: '100% center',
      onEnter: sliderStart,
      onLeave: sliderStop,
      onEnterBack: sliderStart,
      onLeaveBack: sliderStop,
    });

    return () => {
      buttonsTimeline.kill();
      sliderTimeline.kill();
      triggerGoal.kill();
    };
  };

  useScrollReady(() => setupAnimations());

  const onNextClick = () => {
    if (sliderTimeline) {
      sliderTimeline.seek(`slide_${activeSlideRef.current}`);
      sliderTimeline.play();
    }
  };

  const onPrevClick = () => {
    if (sliderTimeline) {
      const transitionSlide =
        activeSlideRef.current > 0 ? `slide_${activeSlideRef.current}-=${TRANSITION_TIME}` : `slide_${slides.length}`;
      sliderTimeline.seek(transitionSlide);
      sliderTimeline.reverse();
      activeSlideRef.current = activeSlideRef.current > 0 ? activeSlideRef.current - 1 : slides.length - 1;
    }
  };

  return (
    <>
      <DeviceContainer id={'download-device-container'} topOffset={topOffset}>
        <DeviceMockup />
        <SlidesList ref={slideImgRefList}>
          {slides.map((slide, index) => {
            return <SlidesListItem key={index} alt={'walkthrough-img'} src={slide} width={'100%'} />;
          })}
        </SlidesList>
        <PrevButton ref={prevButtonRef} onClick={onPrevClick}>
          <img alt='prev-button' src={arrow} width={'100%'} />
        </PrevButton>
        <NextButton ref={nextButtonRef} onClick={onNextClick}>
          <img alt='next-button' src={arrow} width={'100%'} />
        </NextButton>
      </DeviceContainer>
    </>
  );
};

export default WalkthroughSlider;
