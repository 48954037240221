import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const appRoot = (
  <StrictMode>
    <App />
  </StrictMode>
);

ReactDOM.render(appRoot, document.getElementById('root'));
