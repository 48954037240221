import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { refWidth } from 'mobile/utils/dimensions';
import helpEnvironmentImg from 'assets/images/saveThePlanet/help-environment.png';
import { mq } from '../../../assets/styles/mediaQuery';
import { refWidthLandscape } from '../../utils/dimensions';
import BulletPhotoSection from '../BulletPhotoSection';

const Container = styled.div`
  padding-top: ${refWidth(35)}vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.landscape} {
    padding-top: ${refWidthLandscape(35)}vw;
  }
`;

const HelpEnvironmentSection = () => {
  const { t } = useTranslation('saveThePlanet');

  return (
    <Container>
      <BulletPhotoSection
        title={t('help-environment.title')}
        description={t('help-environment.description')}
        image={{ source: helpEnvironmentImg, dimensions: { width: 398, height: 218 } }}
        hasTopPadding={false}
        fontSizeType={'big'}
      />
    </Container>
  );
};

export default HelpEnvironmentSection;
