import { useTranslation } from 'react-i18next';
import findDealsImg from 'assets/images/buy/buyFindDeals.png';
import React from 'react';
import shape1 from 'assets/images/buy/shapes/findDeals/shape1.svg';
import shape2 from 'assets/images/buy/shapes/findDeals/shape2.svg';
import shape3 from 'assets/images/buy/shapes/findDeals/shape3.svg';
import shape4 from 'assets/images/buy/shapes/findDeals/shape4.svg';
import PhotoTextSection from '../../components/commons/PhotoTextSection';
import ShortSectionContainer from '../../components/containers/ShortSectionContainer';

const FindDealsSection = () => {
  const { t } = useTranslation('buy');

  return (
    <ShortSectionContainer id={'buy-find-deals'}>
      <PhotoTextSection
        sectionClassName={'buy-find-deals'}
        title={t('find-deals.title')}
        description={t('find-deals.description')}
        image={{ source: findDealsImg, dimensions: { height: 550, width: 720 } }}
        margin={'medium'}
        shapes={[
          { source: shape1, dimensions: { left: -131, top: -99, width: 274, height: 265 } },
          { source: shape2, dimensions: { left: 100, top: -115, width: 88, height: 75 } },
          { source: shape3, dimensions: { left: 90, top: 369, width: 415, height: 312 } },
          { source: shape4, dimensions: { left: 612, top: -65, width: 90, height: 58 } },
        ]}
      />
    </ShortSectionContainer>
  );
};

export default FindDealsSection;
