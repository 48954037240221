import QRCode from 'qrcode.react';

import styled from 'styled-components';
import { refWidth, refWidthLandscape } from '../../utils/dimensions';
import { mq } from '../../../assets/styles/mediaQuery';

const DOWNLOAD_SUFFIX = '/download';

const ResponsiveSvgWrapper = styled.div`
  svg {
    display: block;
    height: auto;
    width: ${refWidth(110)}vw;

    ${mq.landscape} {
      width: ${refWidthLandscape(110)}vw;
    }
  }
`;

const QRCodeWrapper = () => {
  return (
    <ResponsiveSvgWrapper>
      <QRCode renderAs={'svg'} value={`${process.env.REACT_APP_WEB_URL || ''}${DOWNLOAD_SUFFIX}`} />
    </ResponsiveSvgWrapper>
  );
};

export default QRCodeWrapper;
