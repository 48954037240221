import image from 'assets/images/device/chat/image.png';
import DeviceImage from './DeviceImage';

const Chat = () => {
  return (
    <div id={'device-chat'}>
      <DeviceImage src={image} left={27} top={24} width={375} height={824} />
    </div>
  );
};

export default Chat;
