import { ComponentType } from 'react';
import AboutDesktop from 'desktop/pages/About';
import AboutMobile from 'mobile/pages/About';
import HomeDesktop from 'desktop/pages/Home';
import HomeMobile from 'mobile/pages/Home';
import TermsDesktop from 'desktop/pages/Terms';
import TermsMobile from 'mobile/pages/Terms';
import PrivacyPolicyDesktop from 'desktop/pages/PrivacyPolicy';
import PrivacyPoliceMobile from 'mobile/pages/PrivacyPolicy';
import FundraiseDesktop from 'desktop/pages/Fundraise';
import FundraiseMobile from 'mobile/pages/Fundraise';
import BuyDesktop from 'desktop/pages/Buy';
import BuyMobile from 'mobile/pages/Buy';
import ContributeDesktop from 'desktop/pages/Contribute';
import ContributeMobile from 'mobile/pages/Contribute';
import FAQDesktop from 'desktop/pages/FAQ';
import FAQMobile from 'mobile/pages/FAQ';
import SaveThePlanetDesktop from 'desktop/pages/SaveThePlanet';
import SaveThePlanetMobile from 'mobile/pages/SaveThePlanet';

interface Route {
  path: string;
  desktop: ComponentType;
  mobile: ComponentType;
}

export const routes: Route[] = [
  { path: '/', desktop: HomeDesktop, mobile: HomeMobile },
  { path: '/fund', desktop: FundraiseDesktop, mobile: FundraiseMobile },
  { path: '/shop', desktop: BuyDesktop, mobile: BuyMobile },
  { path: '/donate', desktop: ContributeDesktop, mobile: ContributeMobile },
  {
    path: '/save-the-planet',
    desktop: SaveThePlanetDesktop,
    mobile: SaveThePlanetMobile,
  },
  { path: '/about', desktop: AboutDesktop, mobile: AboutMobile },
  { path: '/terms-of-service', desktop: TermsDesktop, mobile: TermsMobile },
  { path: '/terms-of-service.htm', desktop: TermsDesktop, mobile: TermsMobile },
  { path: '/privacy-policy', desktop: PrivacyPolicyDesktop, mobile: PrivacyPoliceMobile },
  { path: '/privacy-policy.htm', desktop: PrivacyPolicyDesktop, mobile: PrivacyPoliceMobile },
  { path: '/faq', desktop: FAQDesktop, mobile: FAQMobile },
  { path: '', desktop: HomeDesktop, mobile: HomeMobile },
];
