import shareImg from 'assets/images/fundraise/fundraiseShare.png';
import shape1 from 'assets/images/fundraise/shapes/share/shape1.svg';
import shape2 from 'assets/images/fundraise/shapes/share/shape2.svg';
import shape3 from 'assets/images/fundraise/shapes/share/shape3.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PhotoTextSection from '../../components/commons/PhotoTextSection';
import ShortSectionContainer from '../../components/containers/ShortSectionContainer';

const ShareSection = () => {
  const { t } = useTranslation('fundraise');

  return (
    <ShortSectionContainer id={'fundraise-share'}>
      <PhotoTextSection
        sectionClassName={'fundraise-share'}
        bullet={{ ordinal: 2 }}
        title={t('share.title')}
        description={t('share.description')}
        image={{ source: shareImg, dimensions: { height: 550, width: 720 } }}
        margin={'medium'}
        shapes={[
          { source: shape1, dimensions: { left: -120, top: -106, width: 274, height: 265 } },
          { source: shape2, dimensions: { left: 726, top: -56, width: 88, height: 75 } },
          { source: shape3, dimensions: { left: 302, top: 282, width: 550, height: 399 } },
        ]}
        reversed={true}
      />
    </ShortSectionContainer>
  );
};

export default ShareSection;
