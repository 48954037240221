import styled from 'styled-components';
import colors from 'assets/styles/colors';
import { animations } from 'assets/styles/animations';

const PointerDiv = styled.div`
  cursor: pointer;
  transition-property: color, fill;
  transition-duration: ${animations.standardDuration};

  &:hover {
    color: ${colors.red};
  }
`;

export default PointerDiv;
