import React from 'react';
import styled from 'styled-components';
import { Topic } from '../../../commons/legalDocument/legalDocumentObject';
import TranslationWithLink from '../../../commons/legalDocument/TranslationWithLink';
import StyledList from '../../../commons/legalDocument/StyledList';
import ThreeLevelList from '../../../commons/legalDocument/ThreeLevelList';
import { ContentList, SubPoint, TopicList, TopicPoint } from '../../../commons/legalDocument/LegalDocumentListStyles';
import { Description, Subtitle } from './LegalDocumentTypography';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  topics: Topic[];
}

const Topics = ({ topics }: Props) => {
  return (
    <TopicList>
      {topics.map(topic => {
        const topicId = topics.indexOf(topic);
        return (
          <Container key={topicId}>
            <Subtitle>
              <TopicPoint>{topic.topic}</TopicPoint>
            </Subtitle>
            <ContentList>
              {topic.content.map(cont => {
                const contentId = `${topicId}_${topic.content.indexOf(cont)}`;
                return (
                  <div key={contentId}>
                    {cont.subPoint && (
                      <Subtitle>
                        <SubPoint>{cont.subPoint}</SubPoint>
                      </Subtitle>
                    )}
                    {cont.subtitle && <Subtitle>{cont.subtitle}</Subtitle>}
                    <Description>
                      <TranslationWithLink defaultText={cont.description} />
                    </Description>
                    {cont.list && (
                      <Description>
                        <StyledList list={cont.list} listStyle={cont.listStyle} parentId={contentId} />
                      </Description>
                    )}
                    {cont.nestedList && (
                      <Description>
                        <ThreeLevelList nestedList={cont.nestedList} listStyle={cont.listStyle} parentId={contentId} />
                      </Description>
                    )}
                  </div>
                );
              })}
            </ContentList>
          </Container>
        );
      })}
    </TopicList>
  );
};

export default Topics;
