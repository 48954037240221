import React from 'react';
import { useTranslation } from 'react-i18next';
import valueSupportiveImg from 'assets/images/about/value-supportive.svg';
import valueSustainabilityImg from 'assets/images/about/value-sustainability.svg';
import valueSocialImpactImg from 'assets/images/about/value-social-impact.svg';
import ValuesListSection from '../../components/commons/ValuesListSection';

const OurValuesSection = () => {
  const { t } = useTranslation('about');

  return (
    <ValuesListSection
      header={t('our-values.header')}
      id={'about'}
      values={[
        {
          image: valueSupportiveImg,
          width: 259,
          top: -15,
          title: t('our-values.value-supportive.header'),
          description: t('our-values.value-supportive.description'),
        },
        {
          image: valueSustainabilityImg,
          width: 209,
          top: 0,
          title: t('our-values.value-sustainability.header'),
          description: t('our-values.value-sustainability.description'),
        },
        {
          image: valueSocialImpactImg,
          width: 254,
          top: 0,
          title: t('our-values.value-social-impact.header'),
          description: t('our-values.value-social-impact.description'),
        },
      ]}
    />
  );
};

export default OurValuesSection;
