import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { refWidth } from 'mobile/utils/dimensions';
import ourStoryImg from 'assets/images/about/our-story.png';
import { mq } from '../../../assets/styles/mediaQuery';
import { refWidthLandscape } from '../../utils/dimensions';
import BulletPhotoSection from '../BulletPhotoSection';
import shape1 from '../../../assets/images/about/shapes/ourStory/shape1.svg';
import shape2 from '../../../assets/images/about/shapes/ourStory/shape2.svg';
import shape3 from '../../../assets/images/about/shapes/ourStory/shape3.svg';
import shape4 from '../../../assets/images/about/shapes/ourStory/shape4.svg';
import shape5 from '../../../assets/images/about/shapes/ourStory/shape5.svg';

const Container = styled.div`
  padding-top: ${refWidth(35)}vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.landscape} {
    padding-top: ${refWidthLandscape(35)}vw;
  }
`;

const OurStorySection = () => {
  const { t } = useTranslation('about');

  return (
    <Container>
      <BulletPhotoSection
        title={t('our-story.title')}
        description={t('our-story.description')}
        image={{ source: ourStoryImg, dimensions: { width: 314, height: 284 } }}
        bullet={{ text: 'our story' }}
        shapes={[
          { source: shape1, dimensions: { left: -31, top: -10, width: 108, height: 172 } },
          { source: shape2, dimensions: { left: 232, top: -23, width: 21, height: 13 } },
          { source: shape3, dimensions: { left: 277, top: -19, width: 67, height: 42 } },
          { source: shape4, dimensions: { left: 209, top: 302, width: 35, height: 22 } },
          { source: shape5, dimensions: { left: 21, top: 202, width: 172, height: 108 } },
        ]}
      />
    </Container>
  );
};

export default OurStorySection;
