import { useTranslation } from 'react-i18next';
import contributePickupShipping from 'assets/images/contribute/contributePickupShipping.png';
import BulletDeviceSection from '../BulletDeviceSection';

const DeliveryMethodSection = () => {
  const { t } = useTranslation('contribute');

  return (
    <BulletDeviceSection
      title={t('delivery-method.title')}
      description={t('delivery-method.description')}
      bullet={{ ordinal: 3 }}
      image={contributePickupShipping}
      variant={'contribute'}
    />
  );
};

export default DeliveryMethodSection;
