import React from 'react';
import TranslationWithLink from './TranslationWithLink';
import { StyledOl } from './LegalDocumentListStyles';

interface Props {
  list: string[];
  listStyle?: string;
  parentId: string;
  addPadding?: number;
}

const StyledList = ({ list, listStyle, parentId, addPadding }: Props) => {
  return (
    <StyledOl listStyle={listStyle} addPadding={addPadding}>
      {list.map(elem => {
        const elemId = `${parentId}${list.indexOf(elem)}`;
        return (
          <li key={elemId}>
            <TranslationWithLink defaultText={elem} />
          </li>
        );
      })}
    </StyledOl>
  );
};

export default StyledList;
