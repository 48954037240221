import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { refFontSize, refWidth } from 'desktop/utils/dimensions';
import colors from 'assets/styles/colors';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useScrollbar } from '../../components/containers/ScrollbarProvider';
import ShortSectionContainer from '../../components/containers/ShortSectionContainer';
import { fonts } from '../../../assets/styles/fonts';

const Container = styled(ShortSectionContainer)`
  flex-direction: column;
  height: auto;
  padding: ${refWidth(150)}vw 0;
`;

const HeaderTextWrapper = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(14)}vw;
  line-height: 1.5em;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: ${colors.gullGray};
`;

const StatsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${refWidth(1440)}vw;
  margin-top: ${refWidth(41)}vw;
`;

const StatContainer = styled.div`
  width: ${refWidth(360)}vw;
  height: ${refWidth(240)}vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border: ${refWidth(1)}vw solid ${colors.geyser};
  border-right: none;

  &:first-child {
    border-bottom-left-radius: ${refWidth(10)}vw;
    border-top-left-radius: ${refWidth(10)}vw;
  }

  &:last-child {
    border-right: ${refWidth(1)}vw solid ${colors.geyser};
    border-bottom-right-radius: ${refWidth(10)}vw;
    border-top-right-radius: ${refWidth(10)}vw;
  }
`;

const StatHeader = styled.div`
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(70)}vw;
  line-height: 1em;
  color: ${colors.red};
  margin-top: ${refWidth(51)}vw;
`;

const StatDescription = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(18)}vw;
  line-height: 2em;
  color: ${colors.fadedJade};
  margin-top: ${refWidth(8)}vw;
  margin-right: ${refWidth(40)}vw;
  margin-left: ${refWidth(40)}vw;
  text-align: center;
`;

const StatsSection = () => {
  const { t } = useTranslation('about');
  const { useScrollReady } = useScrollbar();

  const setupAnimations = () => {
    const descIn = gsap
      .timeline({
        scrollTrigger: { trigger: '#about-stats', start: '-10% center', end: '50% center', scrub: true },
      })
      .fromTo('#about-stats-header', { y: 50, opacity: 0 }, { y: 0, opacity: 1, stagger: 0.2 })
      .fromTo('#about-stats-wrapper', { y: 50, opacity: 0 }, { y: 0, opacity: 1, stagger: 0.2 });

    const linesOfCodeAnim = animateStatValue('#about-stats-lines-of-code');
    const devHoursAnim = animateStatValue('#about-stats-dev-hours');
    const femaleLedAnim = animateStatValue('#about-stats-female-led');
    const countriesOfBirthAnim = animateStatValue('#about-stats-countries-of-birth');

    return () => {
      descIn.kill();
      linesOfCodeAnim.kill();
      devHoursAnim.kill();
      femaleLedAnim.kill();
      countriesOfBirthAnim.kill();
    };
  };

  useScrollReady(setupAnimations);

  return (
    <Container id={'about-stats'}>
      <HeaderTextWrapper id={'about-stats-header'}>{t('stats.header')}</HeaderTextWrapper>
      <StatsWrapper id={'about-stats-wrapper'}>
        <StatContainer>
          <StatHeader id={'about-stats-lines-of-code'}>{t('stats.lines-of-code.value')}</StatHeader>
          <StatDescription>{t('stats.lines-of-code.description')}</StatDescription>
        </StatContainer>
        <StatContainer>
          <StatHeader id={'about-stats-dev-hours'}>{t('stats.dev-hours.value')}</StatHeader>
          <StatDescription>{t('stats.dev-hours.description')}</StatDescription>
        </StatContainer>
        <StatContainer>
          <StatHeader id={'about-stats-female-led'}>{t('stats.female-led.value')}</StatHeader>
          <StatDescription>{t('stats.female-led.description')}</StatDescription>
        </StatContainer>
        <StatContainer>
          <StatHeader id={'about-stats-countries-of-birth'}>{t('stats.countries-of-birth.value')}</StatHeader>
          <StatDescription>{t('stats.countries-of-birth.description')}</StatDescription>
        </StatContainer>
      </StatsWrapper>
    </Container>
  );
};

const animateStatValue = (statId: string) => {
  const animIn = gsap.timeline({ paused: true });
  animIn.from(statId, {
    textContent: 0,
    duration: 2,
    ease: 'power1.inOut',
    snap: { textContent: 1 },
  });

  const triggerAnim = ScrollTrigger.create({
    trigger: '#about-stats',
    start: '10% center',
    onEnter: () => animIn.restart(),
  });

  return {
    kill: () => {
      animIn.kill();
      triggerAnim.kill();
    },
  };
};

export default StatsSection;
