import styled from 'styled-components';
import { refWidth, refWidthLandscape } from 'mobile/utils/dimensions';
import { mq } from 'assets/styles/mediaQuery';

const DeviceImage = styled.img`
  width: ${refWidth(242)}vw;
  margin: 0 auto;
  display: block;

  ${mq.landscape} {
    width: ${refWidthLandscape(242)}vw;
  }
`;

export default DeviceImage;
