import styled from 'styled-components';
import logo from 'assets/images/logo-full.svg';
import { refHeight, refHeightLandscape } from '../../utils/dimensions';
import { mq } from '../../../assets/styles/mediaQuery';

const Logo = styled.img`
  height: ${refHeight(35)}vh;

  ${mq.landscape} {
    height: ${refHeightLandscape(35)}vh;
  }
`;

interface Props {
  className?: string;
  onClick?: () => void;
}

const LogoButton = ({ className, onClick }: Props) => {
  return <Logo onClick={onClick} src={logo} className={className} />;
};

export default LogoButton;
