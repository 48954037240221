import axios, { AxiosPromise } from 'axios';

axios.defaults.headers.common['Content-Type'] = 'application/json';

class ApiService {
  get(url: string, params: any = {}, headers: any = {}) {
    return axios({
      method: 'get',
      url,
      headers,
      params,
    });
  }

  post(url: string, data: any = {}, params: any = {}, headers: any = {}): AxiosPromise {
    return axios({
      method: 'post',
      url,
      headers,
      params,
      data,
    });
  }
}

const apiService = new ApiService();

const apiUrl = process.env.REACT_APP_API_URL;

export { apiService, ApiService, apiUrl };
