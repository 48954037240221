import React from 'react';
import styled from 'styled-components';
import colors from 'assets/styles/colors';
import { refFontSize, refHeight, refWidth } from 'desktop/utils/dimensions';
import { useTranslation } from 'react-i18next';
import gsap from 'gsap';
import savePlanetImg from 'assets/images/home/savePlanet/sectionImg.png';
import shape1 from 'assets/images/home/shapes/savePlanet/shape1.svg';
import shape2 from 'assets/images/home/shapes/savePlanet/shape2.svg';
import shape3 from 'assets/images/home/shapes/savePlanet/shape3.svg';
import shape4 from 'assets/images/home/shapes/savePlanet/shape4.svg';
import { mq } from 'assets/styles/mediaQuery';
import { fonts } from 'assets/styles/fonts';
import ShortSectionContainer from '../../components/containers/ShortSectionContainer';
import LearnMoreButton from '../../components/buttons/LearnMoreButton';
import ImageWithShapes from '../../components/commons/ImageWithShapes';
import SectionContainer from '../../components/containers/SectionContainer';
import { useScrollbar } from '../../components/containers/ScrollbarProvider';
import { useNavigation } from '../../utils/useNavigation';

const Container = styled(SectionContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SavePlanetTextWrapper = styled.div`
  width: ${refWidth(480)}vw;
  margin: 0 ${refWidth(120)}vw;
`;

const SavePlanetTitle = styled.div`
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(70)}vw;
  line-height: 1em;
  color: ${colors.cello};

  ${mq.tablet} {
    font-size: ${refFontSize(80)}vw;
  }

  ${mq.phone} {
    font-size: ${refFontSize(90)}vw;
  }
`;

const SavePlanetDescription = styled.div`
  height: ${refHeight(96)}vh;
  margin-top: ${refHeight(55)}vh;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(18)}vw;
  line-height: 1.78em;
  color: ${colors.fadedJade};

  ${mq.desktopSmall} {
    font-size: ${refFontSize(20)}vw;
  }

  ${mq.tablet} {
    font-size: ${refFontSize(24)}vw;
  }

  ${mq.phone} {
    font-size: ${refFontSize(28)}vw;
  }
`;

const StyledLearnMoreButton = styled(LearnMoreButton)`
  position: relative;
  margin-top: ${refWidth(25)}vw;

  ${mq.tablet} {
    margin-top: ${refWidth(32)}vw;
  }
`;

const SaveSection = () => {
  const { t } = useTranslation('home');
  const { useScrollReady } = useScrollbar();
  const { navigate } = useNavigation();

  const setupAnimations = () => {
    const descIn = gsap
      .timeline({ scrollTrigger: { trigger: '#home-save', start: '20% center', end: '50% center', scrub: true } })
      .fromTo('#save-title', { y: -10, opacity: 0 }, { y: 0, opacity: 1 })
      .fromTo('.save-text-elem', { y: 50, opacity: 0 }, { y: 0, opacity: 1, stagger: 0.2 }, '-=0.3');

    return () => {
      descIn.kill();
    };
  };

  useScrollReady(setupAnimations);

  return (
    <ShortSectionContainer id={'home-save'}>
      <Container id={'save-container'}>
        <SavePlanetTextWrapper>
          <SavePlanetTitle id={'save-title'}>{t('save-planet.title')}</SavePlanetTitle>
          <SavePlanetDescription className={'save-text-elem'}>
            <div>{t('save-planet.description')}</div>
          </SavePlanetDescription>
          <StyledLearnMoreButton
            color={colors.red}
            onClick={() => navigate('/save-the-planet')}
            className={'save-text-elem'}
            text={t('learn-more')}
          />
        </SavePlanetTextWrapper>
        <ImageWithShapes
          sectionClassName={'home-save'}
          image={{ source: savePlanetImg, dimensions: { height: 629, width: 960 } }}
          shapes={[
            { source: shape1, dimensions: { left: 785, top: -132, width: 274, height: 265 } },
            { source: shape2, dimensions: { left: -83, top: 151, width: 177, height: 178 } },
            { source: shape3, dimensions: { left: 682, top: -110, width: 88, height: 75 } },
            { source: shape4, dimensions: { left: -180, top: 449, width: 415, height: 302 } },
          ]}
        />
      </Container>
    </ShortSectionContainer>
  );
};

export default SaveSection;
