// http://veli.ee/colorpedia

const colors = {
  red: '#fc686e',
  blue: '#6674b6',
  fadedJade: '#40667d',
  cello: '#1e2a5e',
  pancho: '#f0cdaf',
  towerGray: '#afbec7',
  white: '#fff',
  aliceBlue: '#edf7fc',
  gullGray: '#98aab5',
  brightGray: '#aaa',
  geyser: '#d4dee4',
  transparent: 'transparent',
  shadedBlack: 'rgba(0,0,0,0.33)',
  shadedWhite: 'rgba(255,255,255,0.8)',
  porcelain: '#f5f6f7',
};

export default colors;
