import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import colors from 'assets/styles/colors';
import { refWidth } from 'desktop/utils/dimensions';

const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled(CenterWrapper)`
  position: absolute;
  width: ${refWidth(20)}vw;
  height: ${refWidth(20)}vw;
`;

const MarkerWrapper = styled(CenterWrapper)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const PopupWrapper = styled(CenterWrapper)`
  position: absolute;
  width: 600%;
  height: 600%;
`;

const Circle = styled.div<{ color: string }>`
  background-color: ${props => props.color};
  border-radius: 50%;
  position: absolute;
`;

const Inner = styled(Circle)`
  width: 50%;
  height: 50%;
`;

const Outer = styled(Circle)`
  width: 100%;
  height: 100%;
  opacity: 0.2;
`;

const Popup = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

interface Props {
  color: string;
  popupConfig?: {
    user: string;
    item: string;
    delay: number;
    repeatDelay: number;
  };
  id?: string;
  className?: string;
}

const Marker = (props: Props) => {
  const color = props.color ?? colors.red;

  const markerRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLImageElement>(null);
  const userRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const timelines: any[] = [];

    const mainTimeline = gsap.timeline({ repeat: -1 });
    timelines.push(mainTimeline);

    mainTimeline.to(markerRef.current, { scale: 0.7, duration: 0.75 });
    mainTimeline.to(markerRef.current, { scale: 1, duration: 0.75 });

    // make markers blink at random timestamps
    mainTimeline.progress(Math.random());

    if (props.popupConfig) {
      const { delay, repeatDelay } = props.popupConfig;

      const popupTimeline = gsap.timeline({ repeat: -1, delay, repeatDelay });
      timelines.push(popupTimeline);

      // show popup
      popupTimeline.set(userRef.current, { opacity: 1 });
      popupTimeline.from(userRef.current, { scale: 0, duration: 0.5, ease: 'back.out' });

      // switch user with item
      popupTimeline.to(userRef.current, { opacity: 0, duration: 1, delay: 1 });
      popupTimeline.to(itemRef.current, { opacity: 1, duration: 1 }, '<');

      // hide popup
      popupTimeline.to(itemRef.current, { delay: 1, scale: 0, duration: 0.5, ease: 'back.in' });
    }

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
      timelines.forEach(timeline => timeline.kill());
    };
  }, []);

  return (
    <Container id={props.id} className={props.className}>
      <MarkerWrapper ref={markerRef}>
        <Outer color={color} />
        <Inner color={color} />
      </MarkerWrapper>
      {props.popupConfig && (
        <PopupWrapper>
          <Popup ref={userRef} src={props.popupConfig.user} />
          <Popup ref={itemRef} src={props.popupConfig.item} />
        </PopupWrapper>
      )}
    </Container>
  );
};

export default Marker;
