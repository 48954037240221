import styled from 'styled-components';
import gsap from 'gsap';
import colors from 'assets/styles/colors';
import { refFontSize, refWidth } from 'desktop/utils/dimensions';
import WalkthroughSlider from 'desktop/components/download/WalkthroughSlider';
import { AppStoreButton, GooglePlayButton } from 'desktop/components/buttons/StoreButtons';
import QRCodeWrapper from 'desktop/components/commons/QRCodeWrapper';
import PhoneInputSection from 'desktop/components/inputs/PhoneInputSection';
import SectionContainer from 'desktop/components/containers/SectionContainer';
import { useScrollbar } from 'desktop/components/containers/ScrollbarProvider';
import ShortSectionContainer from 'desktop/components/containers/ShortSectionContainer';
import { mq } from 'assets/styles/mediaQuery';
import { fonts } from 'assets/styles/fonts';

const Container = styled(SectionContainer)`
  background-color: ${colors.red};
  overflow: hidden;
`;

const RightContainer = styled.div`
  position: absolute;
  top: ${refWidth(78)}vw;
  left: ${refWidth(960)}vw;
  width: ${refWidth(480)}vw;

  ${mq.tablet} {
    width: ${refWidth(560)}vw;
  }

  ${mq.phone} {
    width: ${refWidth(600)}vw;
  }
`;

const SubTitle = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(18)}vw;
  line-height: 1.78em;
  color: ${colors.white};

  ${mq.tablet} {
    font-size: ${refFontSize(22)}vw;
  }

  ${mq.phone} {
    font-size: ${refFontSize(24)}vw;
  }
`;

const Title = styled.div`
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(60)}vw;
  line-height: 1.17em;
  color: ${colors.white};
  margin-top: ${refWidth(30)}vw;

  ${mq.tablet} {
    font-size: ${refFontSize(68)}vw;
  }

  ${mq.phone} {
    font-size: ${refFontSize(72)}vw;
  }
`;

const DownloadButtons = styled.div`
  width: 100%;
  height: ${refWidth(110)}vw;
  position: relative;
`;

const DownloadTitle = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  width: ${refWidth(348)}vw;
  font-size: ${refFontSize(16)}vw;
  line-height: 2em;
  color: ${colors.white};
  position: absolute;
  left: 0;
  top: 0;

  ${mq.tablet} {
    font-size: ${refFontSize(20)}vw;
    font-weight: ${fonts.weight.medium};
    width: ${refWidth(428)}vw;
  }

  ${mq.phone} {
    font-size: ${refFontSize(24)}vw;
  }
`;

const StyledAppStoreButton = styled(AppStoreButton)`
  position: absolute;
  left: 0;
  bottom: 0;
`;

const StyledGooglePlayButton = styled(GooglePlayButton)`
  position: absolute;
  left: ${refWidth(178)}vw;
  bottom: 0;
`;

const ButtonsWithQRWrapper = styled.div`
  margin-top: ${refWidth(30)}vw;
  display: flex;
  flex-direction: row;
  align-items: end;
`;

const StyledPhoneInputSection = styled(PhoneInputSection)`
  margin-top: ${refWidth(30)}vw;
`;

interface Props {
  subtitle: string;
  title: string;
  downloadTitle: string;
}

const DownloadSection = (props: Props) => {
  const { useScrollReady } = useScrollbar();
  useScrollReady(() => setupAnimations());

  return (
    <ShortSectionContainer variant={'bottom'} id={'download-section'}>
      <Container id={'download-section-container'}>
        <WalkthroughSlider />
        <RightContainer>
          <SubTitle id={'download-section-subtitle'}>{props.subtitle}</SubTitle>
          <Title id={'download-section-title'}>{props.title}</Title>
          <ButtonsWithQRWrapper id={'download-section-buttons-with-qr'}>
            <DownloadButtons>
              <DownloadTitle>{props.downloadTitle}</DownloadTitle>
              <StyledAppStoreButton />
              <StyledGooglePlayButton />
            </DownloadButtons>
            <QRCodeWrapper />
          </ButtonsWithQRWrapper>
          <StyledPhoneInputSection id={'download-section-phone-input'} buttonColor={colors.cello} />
        </RightContainer>
      </Container>
    </ShortSectionContainer>
  );
};

const setupAnimations = () => {
  const timeline = gsap
    .timeline({
      scrollTrigger: { trigger: '#download-section', start: '20% center', end: '50% center', scrub: true },
    })
    .from(['#download-section-subtitle', '#download-section-title'], { y: -10, opacity: 0 })
    .from('#download-section-buttons-with-qr', { y: 10, opacity: 0 })
    .from('#download-section-phone-input', { y: 40, opacity: 0 });

  return () => {
    timeline.kill();
  };
};

export default DownloadSection;
