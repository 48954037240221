import { useTranslation } from 'react-i18next';
import BulletDeviceSection from '../BulletDeviceSection';
import buyContactViaChat from '../../../assets/images/buy/buyContactViaChat.png';

const PickupGrabSection = () => {
  const { t } = useTranslation('buy');

  return (
    <BulletDeviceSection
      title={t('pickup-grab.title')}
      description={t('pickup-grab.description')}
      bullet={{ ordinal: 3 }}
      image={buyContactViaChat}
      variant={'buy'}
    />
  );
};

export default PickupGrabSection;
