import { useTranslation } from 'react-i18next';
import colors from 'assets/styles/colors';
import AnimatedDeviceSection from '../AnimatedDeviceSection';
import { useNavigation } from '../../utils/useNavigation';

const ShopSection = () => {
  const { t } = useTranslation('home');
  const { navigate } = useNavigation();

  const onMainButtonClick = () => navigate('/buy');

  return (
    <AnimatedDeviceSection
      title={t('shop.title')}
      description={t('shop.description')}
      backgroundColor={colors.red}
      frames={{ directory: 'buy', firstFrame: 200, lastFrame: 291 }}
      onMainButtonClick={onMainButtonClick}
      translation={t('spend-to-help')}
    />
  );
};

export default ShopSection;
