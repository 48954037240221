import React from 'react';
import { ScrollbarProvider } from 'desktop/components/containers/ScrollbarProvider';
import ExpandableHeader from 'desktop/components/commons/ExpandableHeader';
import useOrphanPrevention from '../../commons/utils/useOrphanPrevention';
import FAQSection from '../components/faq/FAQSection';

const FAQ = () => {
  useOrphanPrevention();

  return (
    <ScrollbarProvider>
      <ExpandableHeader />
      <FAQSection />
    </ScrollbarProvider>
  );
};

export default FAQ;
