import { Question, Section } from './faqObjects';

const toSearchWords = (text: string) =>
  text
    .split(/[ ,]/)
    .filter(w => w !== '')
    .filter(w => w.length > 3 || w.includes('buy'))
    .map(word => word.toLowerCase());

export const matchesWords = (text: string, object: Section | Question) => {
  const keysRegex = /\"header\":|\"questions\":|\"subtitle\":|\"answers\":/gi;
  const content = JSON.stringify(object, ['header', 'questions', 'subtitle', 'answers'])
    .toLowerCase()
    .replaceAll(keysRegex, '');
  return toSearchWords(text).some(word => content.includes(word));
};
