import { useState } from 'react';
import { isTablet } from 'react-device-detect';
import Navbar from '../../../desktop/components/commons/Navbar';
import DownloadMenu from './DownloadMenu';
import Header from './Header';

const ExpandableHeader = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(isTablet);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const closeNavbar = () => {
    setNavbarOpen(false);
  };

  return (
    <>
      {isTablet && <Navbar visible={!menuVisible && navbarOpen} onClose={closeNavbar} />}
      <Header isPadded={navbarOpen} onToggleMenu={toggleMenu} isOpenedMenuVariant={menuVisible} />
      <DownloadMenu visible={menuVisible} onSectionClick={toggleMenu} />
    </>
  );
};

export default ExpandableHeader;
