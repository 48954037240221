import styled from 'styled-components';
import { fonts } from 'assets/styles/fonts';
import colors from 'assets/styles/colors';
import { mq } from 'assets/styles/mediaQuery';
import { refFontSize, refFontSizeLandscape, refHeight } from '../../utils/dimensions';

export const Subtitle = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.bold};
  font-size: ${refFontSize(16)}vw;
  line-height: 1.77em;
  margin-top: ${refHeight(40)}vh;
  color: ${colors.cello};

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(16)}vw;
    line-height: 1.4em;
  }
`;

export const Description = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(12)}vw;
  line-height: 1.77em;
  color: ${colors.fadedJade};
  margin-top: ${refHeight(10)}vh;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(12)}vw;
    line-height: 1.4em;
  }
`;
