import ScreenSection from 'desktop/components/containers/ScreenSection';
import { ContributeDeviceContainer } from 'desktop/components/contribute/ContributeDeviceContainer';
import ContributeDeviceFirst from 'desktop/components/contribute/ContributeDeviceFirst';
import { useScrollbar } from 'desktop/components/containers/ScrollbarProvider';
import { animateDeviceOut } from 'desktop/animations/animateDeviceOut';
import { animateCampaign } from 'desktop/animations/animateCampaign';
import { useTranslation } from 'react-i18next';
import DetailsTextSection from '../../components/commons/DetailsTextSection';
import { animateMainTextSection } from '../../animations/animateMainTextSection';
import { animatePageIn } from '../../animations/animatePageIn';

const YourDonationsSection = () => {
  const { t } = useTranslation('contribute');
  const { useScrollReady } = useScrollbar();

  useScrollReady(setupAnimations);

  return (
    <ScreenSection id={'contribute-your-donations'}>
      <ContributeDeviceContainer id={'contribute-pin-first'}>
        <ContributeDeviceFirst id={'contribute-device-first'} />
        <DetailsTextSection
          id={'contribute-details'}
          header={t('your-donations.header')}
          title={t('your-donations.title')}
          subtitle={t('your-donations.subtitle')}
          description={t('your-donations.description')}
        />
      </ContributeDeviceContainer>
    </ScreenSection>
  );
};

const setupAnimations = () => {
  const page = animatePageIn('#contribute-your-donations');
  const campaign = animateCampaign('#contribute-your-donations');
  const deviceOut = animateDeviceOut('#contribute-your-donations', '#contribute-device-first');
  const details = animateMainTextSection('#contribute-details');

  return () => {
    page.kill();
    campaign.kill();
    deviceOut.kill();
    details.kill();
  };
};

export default YourDonationsSection;
