import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Loupe } from 'assets/images/loupe.svg';
import { refFontSize, refFontSizeCalc, refWidth, refWidthCalc } from 'desktop/utils/dimensions';
import colors from 'assets/styles/colors';
import { animations } from 'assets/styles/animations';
import { fonts } from 'assets/styles/fonts';
import { mq } from 'assets/styles/mediaQuery';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Loupe)`
  position: relative;
  right: ${refWidth(35)}vw;
  width: ${refWidth(25)}vw;
  fill: ${colors.gullGray};
  transition-duration: ${animations.standardDuration};
  cursor: pointer;

  ${mq.tablet} {
    font-size: ${refFontSizeCalc(28, 1.15)}vw;
  }
  ${mq.phone} {
    font-size: ${refFontSizeCalc(28, 1.25)}vw;
  }

  &:hover {
    fill: ${colors.red};
  }
`;

const StyledInput = styled.input`
  width: ${refWidth(420)}vw;
  height: ${refWidth(40)}vw;
  border: 1px solid transparent;
  border-radius: ${refWidth(30)}vw;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(18)}vw;
  line-height: 1.5em;
  color: ${colors.cello};
  padding-left: ${refWidth(10)}vw;
  padding-right: ${refWidth(40)}vw;
  outline: none;
  background-color: ${colors.porcelain};
  transition-duration: ${animations.standardDuration};

  ${mq.tablet} {
    font-size: ${refFontSizeCalc(18, 1.15)}vw;
    height: ${refWidthCalc(40, 1.15)}vw;
    width: ${refWidthCalc(420, 1.15)}vw;
  }
  ${mq.phone} {
    font-size: ${refFontSizeCalc(18, 1.25)}vw;
    height: ${refWidthCalc(40, 1.25)}vw;
    width: ${refWidthCalc(420, 1.25)}vw;
  }

  &:hover {
    border-color: ${colors.red};
  }
`;

interface Props {
  value: string;
  onChange: (value: string) => void;
  onIconClick: () => void;
}

const SearchInput = (props: Props) => {
  return (
    <Container>
      <StyledInput value={props.value} onChange={e => props.onChange(e.target.value)} />
      <StyledIcon onClick={props.onIconClick} />
    </Container>
  );
};

export default SearchInput;
