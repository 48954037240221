import React from 'react';
import styled from 'styled-components';
import { refFontSize, refFontSizeLandscape, refWidth } from 'mobile/utils/dimensions';
import colors from 'assets/styles/colors';
import { mq } from '../../../assets/styles/mediaQuery';
import { fonts } from '../../../assets/styles/fonts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderTextWrapper = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(12)}vw;
  line-height: 1.5em;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: ${colors.red};
  margin-bottom: ${refWidth(20)}vw;
`;

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: ${refWidth(315)}vw;

  ${mq.landscape} {
    width: ${refFontSizeLandscape(315)}vw;
  }
`;

const ValueImg = styled.img<{ width: number }>`
  width: ${props => refWidth(props.width)}vw;
`;

const ValueHeader = styled.div`
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.regular};
  text-align: center;
  font-size: ${refFontSize(28)}vw;
  line-height: 1.5em;
  color: ${colors.cello};
  margin-top: ${refWidth(20)}vw;
`;

const ValueDescription = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  text-align: center;
  font-size: ${refFontSize(14)}vw;
  line-height: 1.6em;
  color: ${colors.fadedJade};
  margin-top: ${refWidth(19)}vw;
  margin-bottom: ${refWidth(70)}vw;
`;

interface ValueType {
  image: string;
  width: number;
  title: string;
  description: string;
}

interface Props {
  header: string;
  values: ValueType[];
}

const ValuesListSection = (props: Props) => {
  return (
    <Container>
      <HeaderTextWrapper>{props.header}</HeaderTextWrapper>
      {props.values.map((item, index) => (
        <ValueContainer key={index}>
          <ValueImg src={item.image} width={item.width} />
          <ValueHeader>{item.title}</ValueHeader>
          <ValueDescription>{item.description}</ValueDescription>
        </ValueContainer>
      ))}
    </Container>
  );
};

export default ValuesListSection;
