import styled from 'styled-components';
import colors from 'assets/styles/colors';
import { useTranslation } from 'react-i18next';
import { mq } from 'assets/styles/mediaQuery';
import { fonts } from 'assets/styles/fonts';
import { refFontSize, refFontSizeCalc, refWidth, refWidthCalc } from 'desktop/utils/dimensions';
import HomeDevice from '../home/HomeDevice';
import SectionContainer from '../containers/SectionContainer';
import ScrollDownInfo from '../commons/ScrollDownInfo';

const Container = styled(SectionContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: ${refWidth(360)}vw;
  ${mq.desktopDefault} {
    width: ${refWidth(1380)}vw;
  }
  ${mq.desktopSmall} {
    width: ${refWidth(1580)}vw;
    left: ${refWidth(240)}vw;
  }
  ${mq.phone} {
    left: ${refWidth(180)}vw;
  }
`;

const Title = styled.div`
  position: absolute;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(28)}vw;
  line-height: 1.5em;
  top: ${refWidth(174)}vw;
  color: ${colors.fadedJade};
  ${mq.tablet} {
    font-size: ${refFontSizeCalc(28, 1.15)}vw;
  }
  ${mq.phone} {
    font-size: ${refFontSizeCalc(28, 1.25)}vw;
  }
`;

const Description = styled.div`
  position: absolute;
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(70)}vw;
  line-height: 1em;
  top: ${refWidth(284)}vw;
  width: ${refWidth(650)}vw;
  color: ${colors.cello};
  ${mq.desktopSmall} {
    font-size: ${refFontSizeCalc(70, 1.15)}vw;
    width: ${refWidthCalc(650, 1.2)}vw;
  }
  ${mq.tablet} {
    font-size: ${refFontSizeCalc(70, 1.25)}vw;
  }
`;
const Subtitle = styled.div`
  position: absolute;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(28)}vw;
  line-height: 1em;
  top: ${refWidth(400)}vw;
  width: ${refWidth(650)}vw;
  color: ${colors.cello};
  ${mq.desktopSmall} {
    font-size: ${refFontSizeCalc(28, 1.15)}vw;
    width: ${refWidthCalc(650, 1.2)}vw;
  }
  ${mq.tablet} {
    font-size: ${refFontSizeCalc(28, 1.25)}vw;
  }
`;

const StyledScrollDownInfo = styled(ScrollDownInfo)`
  position: absolute;
  bottom: ${refWidth(50)}vw;
  ${mq.tablet} {
    bottom: 0;
  }
`;

const MainDescription = () => {
  const { t } = useTranslation('home');

  return (
    <Container>
      <Title id={'home-main-title'}>{t('main.title')}</Title>
      <Description id={'home-main-description'}>{t('main.description')}</Description>
      <Subtitle id={'home-main-subtitle'}>{t('main.subtitle')}</Subtitle>
      <StyledScrollDownInfo />
      <HomeDevice />
    </Container>
  );
};

export default MainDescription;
