import styled from 'styled-components';
import { refHeight, refWidth } from 'desktop/utils/dimensions';
import { zIndex } from 'assets/styles/zIndex';
import { REFERENCE_DEVICE_HEIGHT, REFERENCE_DEVICE_WIDTH } from './dimensions';

export const DeviceContainer = styled.div`
  width: ${refWidth(REFERENCE_DEVICE_WIDTH)}vw;
  height: ${refWidth(REFERENCE_DEVICE_HEIGHT)}vw;
  top: ${refHeight(-91)}%;
  right: ${refWidth(241)}%;
  position: absolute;
  z-index: ${zIndex.input};
`;

export const DeviceHomeContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
