import React from 'react';
import styled from 'styled-components';
import { refWidth } from 'desktop/utils/dimensions';
import { Question as QuestionObject } from '../../../commons/components/faq/faqObjects';
import { useFAQContext } from '../../../commons/components/faq/FAQProvider';
import { RollableWrapper } from '../../../commons/components/faq/RollableWrapper';
import { StyledHr } from '../../../commons/components/faq/StyledHr';
import Answer from './Answer';
import HeaderWrapper from './HeaderWrapper';

const Container = styled.div`
  margin-left: ${refWidth(30)}vw;
  overflow: hidden;
`;

interface Props {
  question: QuestionObject;
}

const Question = (props: Props) => {
  const { id, header, expanded, answers } = props.question;
  const { toggleQuestion } = useFAQContext();

  return (
    <Container id={id}>
      <StyledHr />
      <HeaderWrapper id={id} header={header} expanded={expanded} toggleAction={toggleQuestion} isQuestion={true} />
      <RollableWrapper expanded={expanded}>
        {answers.map((answer, index) => (
          <Answer key={index} answer={answer} />
        ))}
      </RollableWrapper>
    </Container>
  );
};

export default Question;
