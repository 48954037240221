import gsap from 'gsap';
import colors from 'assets/styles/colors';
import { animateMarketplace } from 'desktop/animations/animateMarketplace';
import { useScrollbar } from '../../components/containers/ScrollbarProvider';
import ScreenSection from '../../components/containers/ScreenSection';

const ShopSection = () => {
  const { useScrollReady } = useScrollbar();

  useScrollReady(setupAnimations);

  return <ScreenSection id={'home-shop'} />;
};

const setupAnimations = () => {
  const animBackground = gsap
    .timeline({ scrollTrigger: { trigger: '#home-shop', start: 'top center', end: 'center center', scrub: true } })
    .to('#home-app-description', { backgroundColor: colors.red });

  const descIn = gsap
    .timeline({ scrollTrigger: { trigger: '#home-shop', start: '20% center', end: '50% center', scrub: true } })
    .from('#home-shop-title', { display: 'none' })
    .fromTo('#home-shop-title', { display: 'none', opacity: 0, yPercent: 30 }, { opacity: 1, yPercent: 0 }, '<')
    .from('#home-shop-description', { display: 'none' })
    .fromTo('#home-shop-description', { display: 'none', opacity: 0, yPercent: 30 }, { opacity: 1, yPercent: 0 }, '<')
    .from('#home-spend-to-help', { display: 'none' })
    .fromTo('#home-spend-to-help', { opacity: 0, yPercent: 30 }, { opacity: 1, yPercent: 0 }, '<');

  const descOut = gsap
    .timeline({ scrollTrigger: { trigger: '#home-shop', start: '70% center', end: '100% center', scrub: true } })
    .to('#home-shop-title', { opacity: 0, display: 'none' })
    .to('#home-shop-description', { opacity: 0, display: 'none' }, '<')
    .to('#home-spend-to-help', { opacity: 0, display: 'none' }, '<');

  const marketplace = animateMarketplace('#home-shop');

  return () => {
    animBackground.kill();
    descIn.kill();
    descOut.kill();
    marketplace.kill();
  };
};

export default ShopSection;
