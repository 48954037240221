import colors from 'assets/styles/colors';
import styled from 'styled-components';
import { refFontSize, refFontSizeCalc, refHeight, refWidth } from 'desktop/utils/dimensions';
import { WithId } from 'commons/types/commonProps';
import { mq } from 'assets/styles/mediaQuery';
import { fonts } from 'assets/styles/fonts';
import ScrollDownInfo from '../commons/ScrollDownInfo';

const Container = styled.div`
  white-space: pre-line;
  width: ${refWidth(700)}vw;
  margin-left: ${refWidth(240)}vw;
  ${mq.desktopSmall} {
    margin-left: ${refWidth(120)}vw;
    width: ${refWidth(800)}vw;
  }
  ${mq.tablet} {
    margin-left: ${refWidth(120)}vw;
    width: ${refWidth(850)}vw;
  }
`;

const HeaderText = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(14)}vw;
  line-height: 1.5em;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: ${colors.shadedBlack};
  margin-bottom: ${refHeight(34)}vh;
  ${mq.desktopSmall} {
    font-size: ${refFontSizeCalc(14, 1.1)}vw;
    margin-bottom: ${refHeight(14)}vh;
  }
  ${mq.tablet} {
    font-size: ${refFontSizeCalc(14, 1.2)}vw;
  }
`;

const TitleText = styled.div`
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(70)}vw;
  line-height: 1em;
  color: ${colors.white};
  margin-bottom: ${refHeight(46)}vh;
  ${mq.desktopSmall} {
    font-size: ${refFontSizeCalc(70, 1.1)}vw;
  }
  ${mq.tablet} {
    margin-bottom: ${refHeight(30)}vh;
    font-size: ${refFontSizeCalc(70, 1.15)}vw;
  }
  ${mq.phone} {
    font-size: ${refFontSizeCalc(70, 1.2)}vw;
  }
`;

const SubTitleText = styled.div`
  width: 70%;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(28)}vw;
  line-height: 1.5em;
  color: ${colors.white};
  opacity: 0.8;
  margin-bottom: ${refHeight(18)}vh;
  ${mq.desktopSmall} {
    font-size: ${refFontSizeCalc(28, 1.1)}vw;
  }
  ${mq.tablet} {
    font-size: ${refFontSizeCalc(28, 1.2)}vw;
  }
`;

const DescriptionText = styled.div`
  width: 70%;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.medium};
  font-size: ${refFontSize(18)}vw;
  line-height: 1.78em;
  color: ${colors.white};
  opacity: 0.8;
  margin-bottom: ${refHeight(54)}vh;
  ${mq.desktopSmall} {
    font-size: ${refFontSizeCalc(18, 1.15)}vw;
    margin-bottom: ${refHeight(28)}vh;
  }
`;

interface Props extends WithId {
  header: string;
  title: string;
  subtitle: string;
  description: string;
}

const DetailsTextSection = ({ id, header, title, subtitle, description }: Props) => {
  return (
    <Container id={id}>
      <HeaderText>{header}</HeaderText>
      <TitleText>{title}</TitleText>
      <SubTitleText>{subtitle}</SubTitleText>
      <DescriptionText>{description}</DescriptionText>
      <ScrollDownInfo variant={'transparent'} />
    </Container>
  );
};

export default DetailsTextSection;
