import goalImg from 'assets/images/fundraise/fundraiseStartCampaign.png';
import shape1 from 'assets/images/fundraise/shapes/startCampaign/shape1.svg';
import shape2 from 'assets/images/fundraise/shapes/startCampaign/shape2.svg';
import shape3 from 'assets/images/fundraise/shapes/startCampaign/shape3.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PhotoTextSection from '../../components/commons/PhotoTextSection';
import ShortSectionContainer from '../../components/containers/ShortSectionContainer';

const StartCampaignSection = () => {
  const { t } = useTranslation('fundraise');

  return (
    <ShortSectionContainer id={'fundraise-start-campaign'}>
      <PhotoTextSection
        sectionClassName={'fundraise-start-campaign'}
        bullet={{ ordinal: 1 }}
        title={t('start-campaign.title')}
        description={t('start-campaign.description')}
        image={{ source: goalImg, dimensions: { height: 550, width: 720 } }}
        margin={'medium'}
        shapes={[
          { source: shape1, dimensions: { left: 568, top: -89, width: 71, height: 57 } },
          { source: shape2, dimensions: { left: 625, top: -65, width: 170, height: 169 } },
          { source: shape3, dimensions: { left: -169, top: 251, width: 539, height: 430 } },
        ]}
      />
    </ShortSectionContainer>
  );
};

export default StartCampaignSection;
