import { useTranslation } from 'react-i18next';
import contributeVideo from 'assets/videos/mobile_contribute.mp4';
import colors from 'assets/styles/colors';
import VideoMainSection from '../VideoMainSection';

const YourDonationsSection = () => {
  const { t } = useTranslation('contribute');

  return (
    <VideoMainSection
      header={t('your-donations.header')}
      title={t('your-donations.title')}
      subtitle={t('your-donations.subtitle')}
      description={t('your-donations.description')}
      video={contributeVideo}
      backgroundColor={colors.pancho}
    />
  );
};

export default YourDonationsSection;
