import styled from 'styled-components';
import { refHeight, refWidth } from 'desktop/utils/dimensions';
import { mq } from 'assets/styles/mediaQuery';

export const MIN_SECTION_CONTAINER_HEIGHT = `${refWidth(680)}vw`;

const SectionContainer = styled.div`
  width: ${refWidth(1680)}vw;
  height: ${refWidth(750)}vw;
  max-height: ${refHeight(750)}vh;
  min-height: ${MIN_SECTION_CONTAINER_HEIGHT};
  max-width: 300vh;
  position: absolute;

  ${mq.tablet} {
    width: ${refWidth(1760)}vw;
  }

  ${mq.phone} {
    width: ${refWidth(1820)}vw;
  }
`;

export default SectionContainer;
