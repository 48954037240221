import { useTranslation } from 'react-i18next';
import colors from 'assets/styles/colors';
import styled from 'styled-components';
import React from 'react';
import {
  refFontSize,
  refFontSizeLandscape,
  refHeightLandscape,
  refWidth,
  refWidthLandscape,
  refHeight,
} from '../../utils/dimensions';
import { mq } from '../../../assets/styles/mediaQuery';
import { fonts } from '../../../assets/styles/fonts';
import ExplainerPlayer from '../../components/home/ExplainerPlayer';
import playElipse from '../../../assets/images/home/seeApp/playElipse.svg';
import { openInNewTab } from '../../../commons/utils/links';

const DEFAULT_VIDEO_URL = 'https://www.youtube.com/watch?v=4HXic24dkf0';

const Container = styled.div`
  padding-top: ${refWidth(50)}vw;
  padding-bottom: ${refWidth(10)}vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.landscape} {
    padding-top: ${refWidthLandscape(50)}vw;
    padding-bottom: ${refWidthLandscape(10)}vw;
  }
`;

const TextWrapper = styled.div`
  padding-top: ${refWidth(245)}vw;
  width: ${refWidth(315)}vw;

  ${mq.landscape} {
    width: ${refFontSizeLandscape(315)}vw;
    padding-top: ${refWidthLandscape(245)}vw;
  }
`;

const Title = styled.div`
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(40)}vw;
  line-height: 1em;
  color: ${colors.cello};
  text-align: center;
  margin-bottom: ${refWidth(33)}vw;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(40)}vw;
    margin-bottom: ${refWidthLandscape(33)}vw;
  }
`;

const Description = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(14)}vw;
  line-height: 1.72em;
  color: ${colors.fadedJade};
  text-align: center;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(14)}vw;
  }
`;

const PlayButtonWrapper = styled.div`
  margin-top: ${refWidth(33)}vw;
`;

const PlayButton = styled.div`
  position: relative;
  justify-content: flex-start;
  border-radius: ${refWidth(30)}vw;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  background-color: ${colors.red};
  width: ${refWidth(159)}vw;
  height: ${refWidth(49)}vw;
  ${mq.landscape} {
    width: ${refWidthLandscape(159)}vw;
    height: ${refWidthLandscape(49)}vw;
  }
`;

const PlayImg = styled.img`
  width: ${refWidth(32)}vw;
  height: auto;
  padding: ${refHeight(14)}vh ${refWidth(15)}vw ${refHeight(14)}vh ${refWidth(18)}vw;
  ${mq.landscape} {
    width: ${refWidthLandscape(32)}vw;
    padding: ${refHeightLandscape(14)}vh ${refWidthLandscape(15)}vw ${refHeightLandscape(14)}vh
      ${refWidthLandscape(18)}vw;
  }
`;

const PlayTextWrapper = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.bold};
  color: ${colors.white};
  text-transform: uppercase;
  font-size: ${refFontSize(13)}vw;
  line-height: 1.46em;
  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(13)}vw;
  }
`;

const SeeSection = () => {
  const { t } = useTranslation('home');
  return (
    <Container>
      <ExplainerPlayer />

      <TextWrapper>
        <Title>
          {t('see-app.title-line-1')} <br /> {t('see-app.title-line-2')}
        </Title>
        <Description>
          <div>{t('see-app.post')}</div>
          <div>{t('see-app.buy')}</div>
          <div>{t('see-app.start-campaign')}</div>
        </Description>
      </TextWrapper>
      <PlayButtonWrapper>
        <PlayButton onClick={() => openInNewTab(process.env.REACT_APP_PROMO_VIDEO_URL || DEFAULT_VIDEO_URL)}>
          <PlayImg src={playElipse} />
          <PlayTextWrapper>{t('see-app.play')}</PlayTextWrapper>
        </PlayButton>
      </PlayButtonWrapper>
    </Container>
  );
};

export default SeeSection;
