import React from 'react';
import styled from 'styled-components';
import { refFontSize, refWidth } from 'desktop/utils/dimensions';
import colors from 'assets/styles/colors';
import { animations } from 'assets/styles/animations';
import { fonts } from 'assets/styles/fonts';

const StyledButton = styled.div`
  display: flex;
  width: ${refWidth(80)}vw;
  height: ${refWidth(50)}vw;
  border-radius: ${refWidth(30)}vw;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.bold};
  color: ${colors.white};
  background-color: ${colors.red};
  font-size: ${refFontSize(14)}vw;
  line-height: 1em;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: ${animations.standardDuration};
  margin-left: ${refWidth(10)}vw;

  &:hover {
    opacity: 0.7;
  }
`;

interface Props {
  value: string;
  onClick?: () => void;
}

const ShortButton = (props: Props) => {
  return (
    <>
      <StyledButton onClick={props.onClick}>{props.value}</StyledButton>
    </>
  );
};

export default ShortButton;
