import { isIOS, isIOS13, isChrome } from 'react-device-detect';

export const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

export const useStoreLink = () => {
  const openMobileStoreLink = () => {
    if (isIOS || isIOS13) {
      openInNewTab(appStoreLink());
    } else {
      openInNewTab(googlePlayLink());
    }
  };

  const appStoreLink = () => {
    const appId = process.env.REACT_APP_APP_STORE_ID || '';
    return isChrome && (isIOS || isIOS13) // fix for AppStore redirect issue on Chrome iOS
      ? 'itms-apps://apps.apple.com/us/app/giveo/id' + appId
      : 'https://apps.apple.com/us/app/giveo/id' + appId;
  };

  const googlePlayLink = () => {
    return process.env.REACT_APP_GOOGLE_PLAY_URL || '';
  };

  return {
    openMobileStoreLink,
    appStoreLink,
    googlePlayLink,
  };
};
