import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { zIndex } from '../../../assets/styles/zIndex';
import ShortButtonDesktop from '../../../desktop/components/buttons/ShortButton';
import ShortButtonMobile from '../../../mobile/components/buttons/ShortButton';
import colors from '../../../assets/styles/colors';
import { fonts } from '../../../assets/styles/fonts';
import * as desktopDimensions from '../../../desktop/utils/dimensions';
import * as mobileDimensions from '../../../mobile/utils/dimensions';
import { mq } from '../../../assets/styles/mediaQuery';

const Modal = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  align-items: center;
  justify-content: center;
`;

const ErrorContainer = styled.div<{ isMobile?: boolean }>`
  position: relative;
  padding: ${desktopDimensions.refWidth(60)}vw;
  display: flex;
  flex-direction: column;
  width: ${props => (props.isMobile ? mobileDimensions.refWidth(250) : desktopDimensions.refWidth(500))}vw;
  height: ${props => (props.isMobile ? mobileDimensions.refWidth(300) : desktopDimensions.refWidth(200))}vw;
  background-color: ${colors.white};
  z-index: ${zIndex.popup};
  align-items: center;
  justify-content: center;

  ${mq.landscape} {
    width: ${props => (props.isMobile ? mobileDimensions.refWidth(250) : desktopDimensions.refWidth(500))}vw;
    height: ${props => (props.isMobile ? mobileDimensions.refWidth(100) : desktopDimensions.refWidth(200))}vw;
  }
`;

const Message = styled.div<{ isMobile?: boolean }>`
  text-align: center;
  font-family: ${fonts.family.description};
  color: ${colors.fadedJade};
  font-weight: ${fonts.weight.light};
  font-size: ${props => (props.isMobile ? mobileDimensions.refFontSize(18) : desktopDimensions.refFontSize(18))}vw;
  line-height: 1.5em;
  margin-bottom: ${props => (props.isMobile ? mobileDimensions.refWidth(20) : desktopDimensions.refWidth(50))}vw;

  ${mq.landscape} {
    font-size: ${props => (props.isMobile ? mobileDimensions.refFontSize(10) : desktopDimensions.refFontSize(18))}vw;
  }
`;

interface Props {
  closeModal: () => void;
  isMobile?: boolean;
}

const IssueCollector = (props: Props) => {
  const [loadError, setLoadError] = useState(false);
  const { t } = useTranslation('faq');

  useEffect(() => {
    const jquery = document.createElement('script');
    const issueCollector = document.createElement('script');
    const openDialog = document.createElement('script');

    jquery.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
    jquery.async = true;

    issueCollector.src =
      'https://source-corp.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/sb53l8/b/2/bc54840da492f9ca037209037ef0522a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=2c9ad5b4';
    issueCollector.async = true;

    openDialog.text =
      'window.ATL_JQ_PAGE_PROPS = {' +
      '"triggerFunction": function (showCollectorDialog) {' +
      'jQuery("#modal").ready(function () {' +
      'setTimeout(function () {' +
      'showCollectorDialog();' +
      '}, 50);' +
      '});' +
      '}' +
      '};';

    const observer = new MutationObserver(() => {
      const elem = document.getElementById('atlwdg-container');
      if (elem) {
        observer.observe(elem, { subtree: false, childList: true });
        if (elem.style.display === 'none') props.closeModal();
      }
    });

    issueCollector.onload = onCollectorLoadSuccess(observer);
    issueCollector.onerror = onCollectorLoadError();

    document.body.appendChild(jquery);
    document.body.appendChild(issueCollector);
    document.body.appendChild(openDialog);

    return () => {
      document.body.removeChild(jquery);
      document.body.removeChild(issueCollector);
      document.body.removeChild(openDialog);
      observer.disconnect();
    };
  }, []);

  const onCollectorLoadSuccess = (observer: MutationObserver) => {
    return () => observer.observe(document, { subtree: true, childList: true });
  };

  const onCollectorLoadError = () => {
    return () => {
      setLoadError(true);
    };
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Modal>
      {loadError && (
        <ErrorContainer isMobile={props.isMobile}>
          {scrollToTop()}
          <Message isMobile={props.isMobile}>{t('issue-collector-error')}</Message>
          {props.isMobile ? (
            <ShortButtonMobile value={t('common:close-button')} onClick={props.closeModal} />
          ) : (
            <ShortButtonDesktop value={t('common:close-button')} onClick={props.closeModal} />
          )}
        </ErrorContainer>
      )}
    </Modal>
  );
};

export default IssueCollector;
