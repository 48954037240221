import { useTranslation } from 'react-i18next';
import fundraiseCollect from 'assets/images/fundraise/fundraiseCollect.png';
import BulletPhotoSection from '../BulletPhotoSection';

const CollectSection = () => {
  const { t } = useTranslation('fundraise');

  return (
    <BulletPhotoSection
      title={t('collect.title')}
      description={t('collect.description')}
      image={{ source: fundraiseCollect, dimensions: { width: 260, height: 240 } }}
      hasTopPadding={true}
    />
  );
};
export default CollectSection;
