import arrow from 'assets/images/device/campaign/arrow.svg';
import header from 'assets/images/device/campaign/header.svg';
import search from 'assets/images/device/campaign/search.svg';
import photo1 from 'assets/images/device/campaign/1/photo.png';
import photo2 from 'assets/images/device/campaign/2/photo.png';
import photo3 from 'assets/images/device/campaign/3/photo.png';
import photo4 from 'assets/images/device/campaign/4/photo.png';
import title1 from 'assets/images/device/campaign/1/title.svg';
import title2 from 'assets/images/device/campaign/2/title.svg';
import title3 from 'assets/images/device/campaign/3/title.svg';
import title4 from 'assets/images/device/campaign/4/title.svg';
import progress from 'assets/images/device/campaign/progress.svg';
import details from 'assets/images/device/campaign/details.svg';
import DeviceImage from './DeviceImage';

const Campaign = () => {
  return (
    <div id={'device-campaign'}>
      <div id={'device-campaign1'}>
        <DeviceImage src={arrow} left={42} top={79} width={21} height={20} />
        <DeviceImage src={header} left={42} top={115} width={329} height={40} />
        <DeviceImage src={search} left={42} top={165} width={345} height={43} />
        <DeviceImage src={photo1} left={27} top={223} width={374} height={428} />
        <DeviceImage src={title1} left={42} top={674} width={345} height={63} />
        <DeviceImage src={progress} left={42} top={753} width={347} height={5} />
        <DeviceImage src={details} left={42} top={776} width={345} height={45} />
      </div>
      <div id={'device-campaign2'}>
        <DeviceImage src={arrow} left={42} top={79} width={21} height={20} />
        <DeviceImage src={header} left={42} top={115} width={329} height={40} />
        <DeviceImage src={search} left={42} top={165} width={345} height={43} />
        <DeviceImage src={photo2} left={27} top={223} width={374} height={428} />
        <DeviceImage src={title2} left={42} top={674} width={345} height={63} />
        <DeviceImage src={progress} left={42} top={753} width={347} height={5} />
        <DeviceImage src={details} left={42} top={776} width={345} height={45} />
      </div>
      <div id={'device-campaign3'}>
        <DeviceImage src={arrow} left={42} top={79} width={21} height={20} />
        <DeviceImage src={header} left={42} top={115} width={329} height={40} />
        <DeviceImage src={search} left={42} top={165} width={345} height={43} />
        <DeviceImage src={photo3} left={27} top={223} width={374} height={428} />
        <DeviceImage src={title3} left={42} top={674} width={345} height={63} />
        <DeviceImage src={progress} left={42} top={753} width={347} height={5} />
        <DeviceImage src={details} left={42} top={776} width={345} height={45} />
      </div>
      <div id={'device-campaign4'}>
        <DeviceImage src={arrow} left={42} top={79} width={21} height={20} />
        <DeviceImage src={header} left={42} top={115} width={329} height={40} />
        <DeviceImage src={search} left={42} top={165} width={345} height={43} />
        <DeviceImage src={photo4} left={27} top={223} width={374} height={428} />
        <DeviceImage src={title4} left={42} top={674} width={345} height={63} />
        <DeviceImage src={progress} left={42} top={753} width={347} height={5} />
        <DeviceImage src={details} left={42} top={776} width={345} height={45} />
      </div>
    </div>
  );
};

export default Campaign;
