import styled from 'styled-components';
import { refWidth, refWidthLandscape } from '../../utils/dimensions';
import { mq } from '../../../assets/styles/mediaQuery';

const pt = (width: number, multiplier: number) => refWidth(width * multiplier);
const ls = (width: number, multiplier: number) => refWidthLandscape(width * multiplier);

const Shadow = styled.div<{ color: string; multiplier: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: ${({ color, multiplier: m }) =>
    `inset ${pt(-20, m)}vw ${pt(-30, m)}vw ${pt(30, m)}vw ${pt(10, m)}vw ${color}, 
     inset ${pt(20, m)}vw ${pt(30, m)}vw ${pt(30, m)}vw ${pt(10, m)}vw ${color}`};

  ${mq.landscape} {
    box-shadow: ${({ color, multiplier: m }) =>
      `inset ${ls(-20, m)}vw ${ls(-30, m)}vw ${ls(30, m)}vw ${ls(10, m)}vw ${color}, 
       inset ${ls(20, m)}vw ${ls(30, m)}vw ${ls(30, m)}vw ${ls(10, m)}vw ${color}`};
  }
`;

interface Props {
  color: string;
  multiplier?: number;
}

const AnimationShadow = ({ color, multiplier = 1 }: Props) => {
  return <Shadow color={color} multiplier={multiplier} />;
};

export default AnimationShadow;
