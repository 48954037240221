import styled from 'styled-components';
import { fonts } from 'assets/styles/fonts';
import colors from 'assets/styles/colors';
import { LegalDocument } from '../../../commons/legalDocument/legalDocumentObject';
import { refFontSize, refFontSizeLandscape, refHeight, refWidth } from '../../utils/dimensions';
import { mq } from '../../../assets/styles/mediaQuery';
import { Description, Subtitle } from './LegalDocumentTypography';
import Topics from './Topics';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${refHeight(60)}vh ${refWidth(40)}vw;
`;

const Title = styled.div`
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(40)}vw;
  line-height: 1em;
  margin-bottom: ${refHeight(50)}vh;
  color: ${colors.cello};

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(40)}vw;
  }
`;

const TopicsWrapper = styled.div`
  padding-left: ${refWidth(5)}vw;
`;

interface Props {
  inputText: LegalDocument;
}

const LegalDocumentSection = ({ inputText }: Props) => {
  return (
    <Container>
      <Title>{inputText.title}</Title>
      <Subtitle>{inputText.update}</Subtitle>
      <Description>{inputText.description}</Description>
      <TopicsWrapper>
        <Topics topics={inputText.topics} />
      </TopicsWrapper>
    </Container>
  );
};

export default LegalDocumentSection;
