import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import colors from '../../../assets/styles/colors';
import {
  refWidth,
  refFontSize,
  refWidthLandscape,
  refFontSizeLandscape,
  refHeightLandscape,
  NAVBAR_HEIGHT,
} from '../../utils/dimensions';
import logo from '../../../assets/images/logo-symbol.svg';
import xIcon from '../../../assets/images/menuXicon.svg';
import { fonts } from '../../../assets/styles/fonts';
import { mq } from '../../../assets/styles/mediaQuery';
import { useStoreLink } from '../../../commons/utils/links';
import { useSingleElementOrphanPrevention } from '../../../commons/utils/useOrphanPrevention';

const NavbarContainer = styled.div<{ visible: boolean; height: number; shouldAnimateOnHide: boolean }>`
  width: 100%;
  height: ${({ visible, height }) => (visible ? height : 0)}vw;
  top: 0;
  background-color: ${colors.aliceBlue};
  transition: ${({ visible, shouldAnimateOnHide }) =>
    visible ? '0.3s ease-out' : shouldAnimateOnHide ? '0.3s ease-in' : '0'};
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mq.landscape} {
    height: ${({ visible, height }) => (visible ? 0.52 * height : 0)}vw;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${refWidth(19)}vw;
`;

const Logo = styled.img`
  width: ${refWidth(40)}vw;
  height: auto;

  ${mq.landscape} {
    width: ${refWidthLandscape(40)}vw;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${refWidth(15)}vw;
`;

const Header = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(12)}vw;
  line-height: 1.5em;
  color: ${colors.fadedJade};

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(12)}vw;
  }
`;

const Description = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(12)}vw;
  line-height: 1.5em;
  color: ${colors.fadedJade};

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(12)}vw;
  }
`;

const StoreMobileButton = styled.div`
  cursor: pointer;
  transition: transform 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${refWidth(32)}vw;
  color: ${colors.white};
  text-transform: uppercase;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.bold};
  font-size: ${refFontSize(11)}vw;
  line-height: 1.5em;
  background-color: ${colors.red};
  border-radius: ${refWidth(30)}vw;
  width: ${refWidth(53)}vw;
  height: ${refWidth(31)}vw;

  ${mq.landscape} {
    width: ${refWidthLandscape(53)}vw;
    font-size: ${refFontSizeLandscape(11)}vw;
    height: ${refHeightLandscape(31)}vw;
  }

  &:hover {
    transform: scale(1.05);
  }
`;

const CloseIcon = styled.img`
  width: ${refWidth(16)}vw;
  height: auto;
  margin-right: ${refWidth(20)}vw;
  padding-left: ${refWidth(22)}vw;

  ${mq.landscape} {
    width: ${refWidthLandscape(16)}vw;
  }
`;

interface Props {
  visible: boolean;
}

const Navbar = ({ visible }: Props) => {
  const [onTop, setOnTop] = useState(true);
  const [manuallyClosed, setManuallyClosed] = useState(false);
  useSingleElementOrphanPrevention('navbar-header');
  const { openMobileStoreLink } = useStoreLink();

  const { t } = useTranslation('common');

  const handleScroll = () => {
    setOnTop(window.pageYOffset === 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const close = () => {
    setManuallyClosed(true);
  };

  return (
    <>
      <NavbarContainer visible={visible && !manuallyClosed} height={NAVBAR_HEIGHT} shouldAnimateOnHide={onTop}>
        <ContentContainer>
          <Logo src={logo} />
          <TextContainer>
            <Header id={'navbar-header'}>{t('banner.header')}</Header>
            <Description>{t('banner.description')}</Description>
          </TextContainer>
          <StoreMobileButton onClick={openMobileStoreLink}>{t('banner.button')}</StoreMobileButton>
        </ContentContainer>
        <CloseIcon src={xIcon} onClick={close} />
      </NavbarContainer>
    </>
  );
};

export default Navbar;
