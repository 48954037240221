import ExpandableHeader from 'desktop/components/commons/ExpandableHeader';
import MainSection from 'desktop/sections/home/MainSection';
import FundraiseSection from 'desktop/sections/home/FundraiseSection';
import SeeSection from 'desktop/sections/home/SeeSection';
import SmoothScroll from 'desktop/components/containers/SmoothScroll';
import { useTranslation } from 'react-i18next';
import { ScrollbarProvider } from 'desktop/components/containers/ScrollbarProvider';
import LoadingOverlay from 'desktop/components/containers/LoadingOverlay';
import useOrphanPrevention from 'commons/utils/useOrphanPrevention';
import DonateSection from 'desktop/sections/home/DonateSection';
import ShopSection from 'desktop/sections/home/ShopSection';
import Footer from '../sections/Footer';
import SaveSection from '../sections/home/SaveSection';
import DownloadSection from '../sections/DownloadSection';

const SNAP_POINTS = [
  '#home-main',
  '#home-donate',
  '#home-shop',
  '#home-fundraise',
  '#home-see',
  '#home-save',
  '#download-section',
];

const Home = () => {
  const { t } = useTranslation('home');
  useOrphanPrevention();

  return (
    <ScrollbarProvider>
      <ExpandableHeader />
      <LoadingOverlay position={'aboveHeader'} />
      <SmoothScroll snapPointsConfig={{ elementIds: SNAP_POINTS, snapToEnd: true }}>
        <MainSection />
        <DonateSection />
        <ShopSection />
        <FundraiseSection />
        <SeeSection />
        <SaveSection />
        <DownloadSection
          subtitle={t('download.subtitle')}
          title={t('download.title')}
          downloadTitle={t('download.download-title')}
        />
        <Footer />
      </SmoothScroll>
    </ScrollbarProvider>
  );
};

export default Home;
