import styled from 'styled-components';
import { refFontSize, refFontSizeCalc, refHeight, refWidth } from 'desktop/utils/dimensions';
import colors from 'assets/styles/colors';
import { useEffect } from 'react';
import gsap from 'gsap';
import { zIndex } from 'assets/styles/zIndex';
import { useTranslation } from 'react-i18next';
import { mq } from 'assets/styles/mediaQuery';
import { fonts } from 'assets/styles/fonts';
import { TABLET_MULTIPLIER } from '../../../commons/utils/dimensions';
import PhoneInputSection from '../inputs/PhoneInputSection';
import { AppStoreButton, GooglePlayButton } from '../buttons/StoreButtons';
import SocialMediaIcons from '../../../commons/components/commons/SocialMediaIcons';
import { useNavigation } from '../../utils/useNavigation';
import PointerDiv from './PointerDiv';
import QRCodeWrapper from './QRCodeWrapper';

const SECTION_FONT_SIZE = 16 * TABLET_MULTIPLIER;

const Section = styled.div<{ visible: boolean }>`
  width: 100%;
  height: 100vh;
  background-color: ${colors.aliceBlue};
  z-index: ${zIndex.input};
  position: absolute;
  display: flex;
  flex-direction: row;
  transition: ${({ visible }) => (visible ? '1s' : '0.3s ease-in')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

const LeftSection = styled.div`
  margin-left: ${refWidth(240)}vw;
  margin-top: ${refHeight(237)}vh;
  width: ${refWidth(329)}vw;

  ${mq.tablet} {
    margin-left: ${refWidth(180)}vw;
  }
`;

const MainTextSection = styled.div`
  margin-bottom: ${refHeight(60)}vh;
`;

const MainTextWrapper = styled.div`
  margin-bottom: ${refHeight(30)}vh;
`;

const MainText = styled(PointerDiv)`
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(70)}vw;
  line-height: 1em;
  color: ${colors.cello};

  ${mq.tablet} {
    font-size: ${refFontSize(85)}vw;
  }
`;

const TextSubSection = styled.div`
  height: ${refHeight(156)}vh;
`;

const SubSectionText = styled(PointerDiv)`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(28)}vw;
  line-height: 1.5em;
  color: ${colors.cello};
  margin-bottom: ${refHeight(15)}vh;

  ${mq.tablet} {
    font-size: ${refFontSize(36)}vw;
  }
`;

const RightSection = styled.div`
  width: ${refWidth(380)}vw;
  margin-left: ${refWidth(271)}vw;
  margin-top: ${refHeight(237)}vh;
  padding-right: ${refWidth(60)}vw;
`;

const SectionHeader = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.medium};
  color: ${colors.fadedJade};
  font-size: ${refFontSize(SECTION_FONT_SIZE)}vw;
  line-height: 1.625em;

  ${mq.desktopSmall} {
    font-size: ${refFontSizeCalc(SECTION_FONT_SIZE, 1.4)}vw;
  }

  ${mq.tablet} {
    font-size: ${refFontSize(21)}vw;
  }
`;

const SocialMediaSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${refHeight(9)}vh;
  margin-bottom: ${refHeight(46)}vh;
`;

const RedirectSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${refHeight(12)}vh;
`;

const StyledAppStoreButton = styled(AppStoreButton)`
  margin-bottom: ${refHeight(10)}vh;

  ${mq.tablet} {
    margin-bottom: ${refHeight(2)}vh;
  }
`;

const StyledGooglePlayButton = styled(GooglePlayButton)`
  margin-bottom: ${refHeight(10)}vh;
`;

const QRSectionWrapper = styled.div`
  padding-right: ${refWidth(20)}vw;
`;

const StyledPhoneInputSection = styled(PhoneInputSection)`
  padding-right: ${refWidth(210)}vw;
  margin-top: ${refHeight(30)}vh;
  margin-bottom: ${refHeight(30)}vh;
`;

interface Props {
  onSectionClick: () => void;
  visible: boolean;
}

const DownloadMenu = ({ onSectionClick, visible }: Props) => {
  const { t } = useTranslation('home');
  const { navigate } = useNavigation();

  const enterMenuAnimations = () => {
    const fromVars = { opacity: 0, y: -30 };
    const toVars = { opacity: 1, y: 0, duration: 0.5 };
    const tl = gsap.timeline();
    tl.fromTo('#contribute-text', { ...fromVars }, { ...toVars }, 0.1)
      .fromTo('#right-section', { ...fromVars, y: 0 }, { ...toVars }, 0.1)
      .fromTo('#buy-text', { ...fromVars }, { ...toVars }, 0.2)
      .fromTo('#fundraise-text', { ...fromVars }, { ...toVars }, 0.3)
      .fromTo('.subsection-text', { ...fromVars }, { ...toVars, stagger: 0.1 }, 0.4);
    return () => {
      tl.kill();
    };
  };

  useEffect(() => {
    if (visible) {
      return enterMenuAnimations();
    }
  }, [visible]);

  return (
    <>
      <Section id={'main-section'} visible={visible}>
        <LeftSection>
          <MainTextSection>
            <MainTextWrapper id={'contribute-text'}>
              <MainText
                onClick={() => {
                  navigate('/donate');
                  onSectionClick();
                }}>
                {t('burger-menu.donate')}
              </MainText>
            </MainTextWrapper>
            <MainTextWrapper id={'buy-text'}>
              <MainText
                onClick={() => {
                  navigate('/shop');
                  onSectionClick();
                }}>
                {t('burger-menu.shop')}
              </MainText>
            </MainTextWrapper>
            <MainTextWrapper id={'fundraise-text'}>
              <MainText
                onClick={() => {
                  navigate('/fund');
                  onSectionClick();
                }}>
                {t('burger-menu.fundraise')}
              </MainText>
            </MainTextWrapper>
          </MainTextSection>
          <TextSubSection>
            <SubSectionText
              onClick={() => {
                navigate('/save-the-planet');
                onSectionClick();
              }}
              className={'subsection-text'}>
              {t('burger-menu.save-planet')}
            </SubSectionText>
            <SubSectionText
              onClick={() => {
                navigate('/faq');
                onSectionClick();
              }}
              className={'subsection-text'}>
              {t('burger-menu.faq')}
            </SubSectionText>
            <SubSectionText
              onClick={() => {
                navigate('/about');
                onSectionClick();
              }}
              className={'subsection-text'}>
              {t('burger-menu.about-us')}
            </SubSectionText>
          </TextSubSection>
        </LeftSection>
        <RightSection id={'right-section'}>
          <>
            <SectionHeader>{t('burger-menu.follow-us')}</SectionHeader>
            <SocialMediaSection>
              <SocialMediaIcons iconWidth={refWidth(32)} iconMarginRight={refWidth(5)} />
            </SocialMediaSection>
          </>
          <>
            <SectionHeader>{t('burger-menu.download')}</SectionHeader>
            <RedirectSection>
              <div>
                <StyledAppStoreButton />
                <StyledGooglePlayButton />
              </div>
              <QRSectionWrapper>
                <QRCodeWrapper />
              </QRSectionWrapper>
            </RedirectSection>
            <StyledPhoneInputSection messageColor={colors.red} buttonColor={colors.red} />
          </>
        </RightSection>
      </Section>
    </>
  );
};

export default DownloadMenu;
