import AppDescription from 'desktop/components/home/AppDescription';
import gsap from 'gsap';
import { useScrollbar } from 'desktop/components/containers/ScrollbarProvider';
import { animateCampaign } from 'desktop/animations/animateCampaign';
import PinnedScreenSection from 'desktop/components/containers/PinnedScreenSection';

const DonateSection = () => {
  const { useScrollReady } = useScrollbar();

  useScrollReady(setupAnimations);

  return (
    <PinnedScreenSection id={'home-donate'}>
      <AppDescription />
    </PinnedScreenSection>
  );
};

const setupAnimations = () => {
  const pinAppDescription = gsap.timeline({
    scrollTrigger: { trigger: '#home-donate', endTrigger: '#home-shop', pin: '#home-app-description' },
  });

  const descIn = gsap
    .timeline({ scrollTrigger: { trigger: '#home-donate', start: '20% center', end: '50% center', scrub: true } })
    .from('#home-donate-title', { display: 'none' })
    .fromTo('#home-donate-title', { opacity: 0, yPercent: 30 }, { opacity: 1, yPercent: 0 }, '<')
    .from('#home-donate-description', { display: 'none' })
    .fromTo('#home-donate-description', { opacity: 0, yPercent: 30 }, { opacity: 1, yPercent: 0 }, '<')
    .from('#home-give-to-earn', { display: 'none' })
    .fromTo('#home-give-to-earn', { opacity: 0, yPercent: 30 }, { opacity: 1, yPercent: 0 }, '<');

  const descOut = gsap
    .timeline({ scrollTrigger: { trigger: '#home-donate', start: '70% center', end: '100% center', scrub: true } })
    .to('#home-donate-title', { opacity: 0, display: 'none' })
    .to('#home-donate-description', { opacity: 0, display: 'none' }, '<')
    .to('#home-give-to-earn', { opacity: 0, display: 'none' }, '<');

  const campaign = animateCampaign('#home-donate');

  return () => {
    pinAppDescription.kill();
    descIn.kill();
    descOut.kill();
    campaign.kill();
  };
};

export default DonateSection;
