import styled from 'styled-components';
import { refWidth, refWidthLandscape } from 'mobile/utils/dimensions';
import { mq } from 'assets/styles/mediaQuery';
import { ImageAdjacentShape, ImageDimensions, ShapeDimensions } from './ImageAdjacentShape';

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const Image = styled.img<{ width: number }>`
  width: ${props => refWidth(props.width)}vw;

  ${mq.landscape} {
    width: ${props => refWidthLandscape(props.width)}vw;
  }
`;

interface Props {
  image: { dimensions: ImageDimensions; source: string };
  shapes?: { dimensions: ShapeDimensions; source: string }[];
}

const ImageWithShapes = ({ image, shapes = [] }: Props) => {
  return (
    <ImageContainer>
      <Image src={image.source} width={image.dimensions.width} />
      {shapes.map((shape, index) => (
        <ImageAdjacentShape
          key={index}
          shapeDimensions={shape.dimensions}
          imageDimensions={image.dimensions}
          src={shape.source}
        />
      ))}
    </ImageContainer>
  );
};

export default ImageWithShapes;
