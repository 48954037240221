import { dropShadow } from 'desktop/utils/dropShadow';
import gsap from 'gsap';
import { createShowHideTrigger } from './createShowHideTrigger';

export const animateCampaign = (sectionId: string) => {
  const imageShowFrom = { opacity: 0, scale: 1.5, stagger: 0.1, filter: dropShadow(10, 30, 5, 0.5) };
  const imageHideTo = { opacity: 0, delay: 2 };

  const campaignMain = gsap.timeline({ repeat: -1, paused: true, repeatDelay: 2, defaults: { duration: 1 } });

  // campaign 1
  campaignMain.set('#loading-overlay', { display: 'none' });
  campaignMain.from('#device-campaign1 img', imageShowFrom);

  // campaign 2
  campaignMain.set('#device-campaign1 img', imageHideTo);
  campaignMain.from('#device-campaign2 img', imageShowFrom, '<');

  // campaign 3
  campaignMain.set('#device-campaign2 img', imageHideTo);
  campaignMain.from('#device-campaign3 img', imageShowFrom, '<');

  // campaign 4
  campaignMain.set('#device-campaign3 img', imageHideTo);
  campaignMain.from('#device-campaign4 img', imageShowFrom, '<');

  const campaignOut = gsap.timeline({ paused: true }).fromTo('#device-campaign', { opacity: 1 }, { opacity: 0 });

  const triggerCampaign = createShowHideTrigger(sectionId, campaignMain, campaignOut);

  return {
    kill: () => {
      triggerCampaign.kill();
      campaignMain.kill();
      campaignOut.kill();
    },
  };
};
