import ScrollTrigger from 'gsap/ScrollTrigger';

export const createShowHideTrigger = (sectionId: string, main: GSAPTimeline, out: GSAPTimeline) => {
  const show = () => {
    out.pause(0);
    main.restart();
  };

  const hide = () => {
    main.pause();
    out.restart();
  };

  return ScrollTrigger.create({
    trigger: sectionId,
    start: '30% center',
    end: '70% center',
    onEnter: show,
    onLeave: hide,
    onEnterBack: show,
    onLeaveBack: hide,
  });
};
