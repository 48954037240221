import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useMobileVersion } from 'commons/utils/useMobileVersion';
import { routes } from './routes';
import { NavigationProvider } from './utils/NavigationProvider';

const Navigation = () => {
  const useMobile = useMobileVersion();

  const routeComponents = routes.map(({ path, desktop, mobile }, key) => {
    return <Route exact={true} path={path} component={useMobile ? mobile : desktop} key={key} />;
  });

  return (
    <BrowserRouter>
      <NavigationProvider>
        <Switch>{routeComponents}</Switch>
      </NavigationProvider>
    </BrowserRouter>
  );
};

export default Navigation;
