import { useMediaQuery } from 'react-responsive';
import { isTablet, isAndroid, isIOS13, isIOS, isWinPhone } from 'react-device-detect';
import { TABLET_MIN_WIDTH } from '../../assets/styles/mediaQuery';

export const useMobileVersion = () => {
  const portrait = '(max-aspect-ratio: 1/1)';
  const landscape = `(max-device-width: ${TABLET_MIN_WIDTH - 0.0001}px)`;

  return (
    (useMediaQuery({ query: `${portrait},${landscape}` }) || // size condition
      isAndroid ||
      isIOS ||
      isIOS13 ||
      isWinPhone) && // device type condition fallback
    !(isTablet && !useMediaQuery({ query: `${portrait}` })) // prevent tablets in landscape to be seen as mobiles
  );
};
