import PageWrapper from 'mobile/components/containers/PageWrapper';
import SetGoalSection from 'mobile/sections/fundraise/SetGoalSection';
import StartCampaignSection from 'mobile/sections/fundraise/StartCampaignSection';
import ShareSection from 'mobile/sections/fundraise/ShareSection';
import SupportSection from 'mobile/sections/fundraise/SupportSection';
import WithdrawSection from 'mobile/sections/fundraise/WithdrawSection';
import CollectSection from 'mobile/sections/fundraise/CollectSection';
import { useTranslation } from 'react-i18next';
import ExpandableHeader from '../components/commons/ExpandableHeader';
import Footer from '../sections/Footer';
import DownloadSection from '../sections/DownloadSection';

const Fundraise = () => {
  const { t } = useTranslation('fundraise');
  return (
    <ExpandableHeader>
      <PageWrapper>
        <SetGoalSection />
        <StartCampaignSection />
        <ShareSection />
        <SupportSection />
        <WithdrawSection />
        <CollectSection />
        <DownloadSection
          subtitle={t('download.subtitle')}
          title={t('download.title')}
          downloadTitle={t('download.download-title')}
        />
        <Footer />
      </PageWrapper>
    </ExpandableHeader>
  );
};

export default Fundraise;
