import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { refFontSize, refFontSizeLandscape, refWidth, refWidthLandscape } from 'mobile/utils/dimensions';
import colors from 'assets/styles/colors';
import { mq } from '../../../assets/styles/mediaQuery';
import { fonts } from '../../../assets/styles/fonts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderTextWrapper = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(12)}vw;
  line-height: 1.5em;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: ${colors.gullGray};

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(12)}vw;
  }
`;

const StatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${refWidth(315)}vw;
  margin-top: ${refWidth(20)}vw;
  border: ${refWidth(1)}vw solid ${colors.geyser};
  border-radius: ${refWidth(10)}vw;

  ${mq.landscape} {
    width: ${refWidthLandscape(315)}vw;
    margin-top: ${refWidthLandscape(20)}vw;
    border-radius: ${refWidthLandscape(10)}vw;
  }
`;

const StatsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  &:first-child {
    border-bottom: ${refWidth(1)}vw solid ${colors.geyser};
  }
`;

const StatContainer = styled.div`
  width: ${refWidth(158)}vw;
  height: ${refWidth(160)}vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:first-child {
    border-right: ${refWidth(1)}vw solid ${colors.geyser};
  }

  ${mq.landscape} {
    width: ${refWidthLandscape(158)}vw;
    height: ${refWidthLandscape(160)}vw;
  }
`;

const StatHeader = styled.div`
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(40)}vw;
  line-height: 1em;
  color: ${colors.red};
  margin-top: ${refWidth(30)}vw;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(40)}vw;
    margin-top: ${refWidthLandscape(30)}vw;
  }
`;

const StatDescription = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(14)}vw;
  line-height: 1.6em;
  color: ${colors.fadedJade};
  margin-top: ${refWidth(16)}vw;
  margin-right: ${refWidth(20)}vw;
  margin-left: ${refWidth(20)}vw;
  text-align: center;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(14)}vw;
    margin-top: ${refWidthLandscape(16)}vw;
    margin-right: ${refWidthLandscape(20)}vw;
    margin-left: ${refWidthLandscape(20)}vw;
  }
`;

const StatsSection = () => {
  const { t } = useTranslation('about');

  return (
    <Container>
      <HeaderTextWrapper>{t('stats.header')}</HeaderTextWrapper>
      <StatsWrapper>
        <StatsRow>
          <StatContainer>
            <StatHeader>{t('stats.lines-of-code.value')}</StatHeader>
            <StatDescription>{t('stats.lines-of-code.description')}</StatDescription>
          </StatContainer>
          <StatContainer>
            <StatHeader>{t('stats.dev-hours.value')}</StatHeader>
            <StatDescription>{t('stats.dev-hours.description')}</StatDescription>
          </StatContainer>
        </StatsRow>
        <StatsRow>
          <StatContainer>
            <StatHeader>{t('stats.female-led.value')}</StatHeader>
            <StatDescription>{t('stats.female-led.description')}</StatDescription>
          </StatContainer>
          <StatContainer>
            <StatHeader>{t('stats.countries-of-birth.value')}</StatHeader>
            <StatDescription>{t('stats.countries-of-birth.description')}</StatDescription>
          </StatContainer>
        </StatsRow>
      </StatsWrapper>
    </Container>
  );
};

export default StatsSection;
