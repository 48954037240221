import { FundraiseDeviceContainer } from 'desktop/components/fundraise/FundraiseDeviceContainer';
import { useScrollbar } from 'desktop/components/containers/ScrollbarProvider';
import FundraiseDeviceSecond from 'desktop/components/fundraise/FundraiseDeviceSecond';
import { animatePinDevice } from 'desktop/animations/animatePinDevice';
import { animateSimpleDevice } from 'desktop/animations/animateSimpleDevice';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import BulletTextSection from '../../components/commons/BulletTextSection';
import { animateDeviceTextSection } from '../../animations/animateDeviceTextSection';
import PinnedScreenSection from '../../components/containers/PinnedScreenSection';

const TextSectionWrapper = styled.div`
  position: absolute;
`;

const SupportSection = () => {
  const { t } = useTranslation('fundraise');
  const { useScrollReady } = useScrollbar();

  useScrollReady(setupAnimations);

  return (
    <PinnedScreenSection id={'fundraise-support'}>
      <FundraiseDeviceContainer id={'fundraise-pin-second'}>
        <FundraiseDeviceSecond id={'fundraise-device-second'} />
        <TextSectionWrapper>
          <BulletTextSection
            title={t('support.title')}
            description={t('support.description')}
            sectionClassName={'fundraise-support'}
            bullet={{ ordinal: 3 }}
            reversed={true}
            variant={'fundraise'}
          />
        </TextSectionWrapper>
        <TextSectionWrapper>
          <BulletTextSection
            title={t('withdraw.title')}
            description={t('withdraw.description')}
            sectionClassName={'fundraise-withdraw'}
            bullet={{ ordinal: 4 }}
            reversed={true}
            variant={'fundraise'}
          />
        </TextSectionWrapper>
      </FundraiseDeviceContainer>
    </PinnedScreenSection>
  );
};

const setupAnimations = () => {
  const pinDevice = animatePinDevice('#fundraise-support', '#fundraise-support', '#fundraise-pin-second');
  const simpleDevice = animateSimpleDevice('#fundraise-support', '#device-search');
  const support = animateDeviceTextSection('#fundraise-support', '.fundraise-support-text');
  const withdraw = animateDeviceTextSection('#fundraise-withdraw', '.fundraise-withdraw-text');

  return () => {
    pinDevice.kill();
    simpleDevice.kill();
    support.kill();
    withdraw.kill();
  };
};

export default SupportSection;
