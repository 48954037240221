import Shapes from 'desktop/components/device/Shapes';
import DeviceLogo from 'desktop/components/device/DeviceLogo';
import Eclipses from 'desktop/components/device/Eclipses';
import Markers from 'desktop/components/device/Markers';
import DeviceMockup from 'desktop/components/device/DeviceMockup';
import Campaign from 'desktop/components/device/Campaign';
import Marketplace from 'desktop/components/device/Marketplace';
import Goal from 'desktop/components/device/Goal';
import { WithClassName } from 'commons/types/commonProps';
import { DeviceContainer, DeviceHomeContainer } from '../device/DeviceContainer';

const HomeDevice = ({ className }: WithClassName) => {
  return (
    <DeviceContainer id={'home-device-pin-wrapper'}>
      <DeviceHomeContainer id={'home-device'} className={className}>
        <Shapes />
        <DeviceMockup />
        <Eclipses />
        <DeviceLogo />
        <Markers />
        <Campaign />
        <Marketplace />
        <Goal />
      </DeviceHomeContainer>
    </DeviceContainer>
  );
};

export default HomeDevice;
