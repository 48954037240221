import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isTablet } from 'react-device-detect';
import SocialMediaIcons from '../../../commons/components/commons/SocialMediaIcons';
import { FooterText } from '../footer/FooterTypography';
import { refWidth } from '../../utils/dimensions';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const TextWrapper = styled(FooterText)`
  margin-right: ${refWidth(18)}vw;
`;

const FollowUs = () => {
  const { t } = useTranslation('common');
  return (
    <Container>
      <TextWrapper>{t('follow-us')}</TextWrapper>
      <SocialMediaIcons iconWidth={refWidth(isTablet ? 30 : 28)} iconMarginRight={refWidth(isTablet ? 12 : 8)} />
    </Container>
  );
};

export default FollowUs;
