import styled from 'styled-components';
import { fonts } from 'assets/styles/fonts';
import { refFontSize, refFontSizeCalc, refHeight, refWidth } from 'desktop/utils/dimensions';
import colors from 'assets/styles/colors';
import { mq } from 'assets/styles/mediaQuery';
import { LegalDocument } from '../../../commons/legalDocument/legalDocumentObject';
import Topics from './Topics';
import { Description, Subtitle } from './LegalDocumentTypography';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${refWidth(360)}vw;

  ${mq.desktopDefault} {
    width: ${refWidth(1150)}vw;
  }

  ${mq.desktopSmall} {
    width: ${refWidth(1550)}vw;
    margin-left: ${refWidth(200)}vw;
  }

  ${mq.phone} {
    margin-left: ${refWidth(180)}vw;
  }
`;

const Title = styled.div`
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(70)}vw;
  line-height: 1em;
  margin-top: ${refHeight(350)}vh;
  margin-bottom: ${refHeight(50)}vh;
  color: ${colors.cello};

  ${mq.desktopSmall} {
    font-size: ${refFontSizeCalc(70, 1.15)}vw;
  }

  ${mq.tablet} {
    font-size: ${refFontSizeCalc(70, 1.25)}vw;
  }
`;

const TopicsWrapper = styled.div`
  padding-left: ${refWidth(20)}vw;
`;

interface Props {
  inputText: LegalDocument;
}

const LegalDocumentSection = ({ inputText }: Props) => {
  return (
    <Container id={'LegalDocument'}>
      <Title>{inputText.title}</Title>
      <Subtitle>{inputText.update}</Subtitle>
      <Description>{inputText.description}</Description>
      <TopicsWrapper>
        <Topics topics={inputText.topics} />
      </TopicsWrapper>
    </Container>
  );
};

export default LegalDocumentSection;
