import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Section } from './faqObjects';
import { loadFAQContent } from './faqProviderUtil';
import { matchesWords } from './faqUtils';

interface FAQContextType {
  sections: Section[];
  toggleSection: (sectionId: string) => void;
  toggleQuestion: (questionId: string) => void;
  search: (text: string) => boolean;
}

const FAQContext = createContext<FAQContextType>(null!);

interface Props {
  children: ReactNode;
  onScrollRequest: (elementId: string, isExpanded: boolean) => void;
}

const FAQProvider = ({ children, onScrollRequest }: Props) => {
  const [sections, setSections] = useState<Section[]>([]);

  useEffect(() => setSections(loadFAQContent()), []);

  const toggleSection = (sectionId: string) => {
    const nextExpanded = !sections.find(s => s.id === sectionId)?.expanded;

    setSections(prev =>
      prev.map(section => ({
        ...section,
        expanded: section.id === sectionId ? nextExpanded : nextExpanded ? false : section.expanded,
        questions: section.questions.map(question => ({
          ...question,
          expanded: section.id === sectionId ? false : question.expanded,
        })),
      }))
    );

    onScrollRequest(sectionId, nextExpanded);
  };

  const toggleQuestion = (questionId: string) => {
    const sec = sections.find(section => section.questions.find(question => question.id === questionId))!;
    const nextExpanded = !sec.questions.find(question => question.id === questionId)?.expanded;

    setSections(prev =>
      prev.map(section => ({
        ...section,
        expanded: section.id === sec.id ? true : nextExpanded ? false : section.expanded,
        questions: section.questions.map(question => ({
          ...question,
          expanded: question.id === questionId ? nextExpanded : nextExpanded ? false : question.expanded,
        })),
      }))
    );

    onScrollRequest(questionId, nextExpanded);
  };

  const search = (text: string) => {
    const filteredState = sections.map(section => ({
      ...section,
      expanded: matchesWords(text, section),
      questions: section.questions.map(question => ({
        ...question,
        expanded: matchesWords(text, question),
      })),
    }));

    setSections(filteredState);

    const firstFoundQuestionId = filteredState
      .find(section => section.questions.find(question => question.expanded))
      ?.questions.find(question => question.expanded)?.id;

    const firstFoundId = firstFoundQuestionId
      ? firstFoundQuestionId
      : filteredState.find(section => section.expanded)?.id;

    firstFoundId && onScrollRequest(firstFoundId, true);

    return !!firstFoundId;
  };

  return (
    <FAQContext.Provider value={{ sections, toggleSection, toggleQuestion, search }}>{children}</FAQContext.Provider>
  );
};

const useFAQContext = () => useContext(FAQContext);
export { FAQProvider, useFAQContext };
