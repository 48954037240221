import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export const animateCarouselIn = (sectionId: string, elementsIds: string) => {
  const animation = gsap.timeline().fromTo(elementsIds, { opacity: 0.2, scale: 0.8 }, { opacity: 1, scale: 1 });

  const scrollTrigger = ScrollTrigger.create({
    animation,
    trigger: sectionId,
    end: 'bottom bottom',
    scrub: true,
  });

  return {
    kill: () => {
      scrollTrigger.kill();
      animation.kill();
    },
  };
};
