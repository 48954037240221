import ReactPlayer from 'react-player';
import React from 'react';

interface Props {
  url: string;
  width: string;
  height: string;
  controls: boolean;
  playing: boolean;
  muted: boolean;
  loop?: boolean;
}

const InlineReactPlayer = (props: Props) => {
  return <ReactPlayer playsinline={true} {...props} />;
};

export default InlineReactPlayer;
