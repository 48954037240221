import { useTranslation } from 'react-i18next';
import beEco from 'assets/images/beEco.png';
import shape1 from 'assets/images/buy/shapes/wasteLess/shape1.svg';
import shape2 from 'assets/images/buy/shapes/wasteLess/shape2.svg';
import BulletPhotoSection from '../BulletPhotoSection';

const GiveLittleSection = () => {
  const { t } = useTranslation('contribute');

  return (
    <BulletPhotoSection
      title={t('give-little.title')}
      description={t('give-little.description')}
      image={{ source: beEco, dimensions: { width: 285, height: 262 } }}
      shapes={[
        { source: shape1, dimensions: { left: 232, top: 56, width: 43, height: 68 } },
        { source: shape2, dimensions: { left: 105, top: 209, width: 33, height: 19 } },
      ]}
    />
  );
};

export default GiveLittleSection;
