import React from 'react';
import { useTranslation } from 'react-i18next';
import ourStoryImg from 'assets/images/about/our-story.png';
import shape1 from 'assets/images/about/shapes/ourStory/shape1.svg';
import shape2 from 'assets/images/about/shapes/ourStory/shape2.svg';
import shape3 from 'assets/images/about/shapes/ourStory/shape3.svg';
import shape4 from 'assets/images/about/shapes/ourStory/shape4.svg';
import shape5 from 'assets/images/about/shapes/ourStory/shape5.svg';
import PhotoTextSection from '../../components/commons/PhotoTextSection';
import ShortSectionContainer from '../../components/containers/ShortSectionContainer';

const OurStorySection = () => {
  const { t } = useTranslation('about');

  return (
    <ShortSectionContainer id={'about-our-story'}>
      <PhotoTextSection
        sectionClassName={'about-our-story'}
        bullet={{ text: 'our story' }}
        title={t('our-story.title')}
        description={t('our-story.description')}
        image={{ source: ourStoryImg, dimensions: { height: 760, width: 840 } }}
        reversed={true}
        margin={'medium'}
        width={'big'}
        shapes={[
          { source: shape1, dimensions: { left: -83, top: -26, width: 288, height: 460 } },
          { source: shape2, dimensions: { left: 620, top: -63, width: 57, height: 35 } },
          { source: shape3, dimensions: { left: 695, top: -78, width: 279, height: 212 } },
          { source: shape4, dimensions: { left: 559, top: 789, width: 96, height: 60 } },
          { source: shape5, dimensions: { left: 57, top: 563, width: 460, height: 288 } },
        ]}
      />
    </ShortSectionContainer>
  );
};

export default OurStorySection;
