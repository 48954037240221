import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

const LinkDescription = styled.div`
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
`;

interface Props {
  defaultText: string;
}

const TranslationWithLink = ({ defaultText }: Props) => {
  return (
    <Trans
      defaults={defaultText}
      components={[
        <LinkDescription onClick={() => window.open('https://policies.google.com/technologies/partner-sites')} />,
        <LinkDescription onClick={() => window.open('https://tools.google.com/dlpage/gaoptout')} />,
        <LinkDescription onClick={() => window.open('mailto:office@giveo.app')} />,
        <LinkDescription onClick={() => window.open('https://www.google.com/intl/en_us/help/terms_maps/')} />,
        <LinkDescription onClick={() => window.open('https://www.adr.org/')} />,
      ]}
    />
  );
};

export default TranslationWithLink;
