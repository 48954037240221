import image from 'assets/images/device/search/image.png';
import DeviceImage from './DeviceImage';

const Search = () => {
  return (
    <div id={'device-search'}>
      <DeviceImage src={image} left={27} top={24} width={375} height={824} />
    </div>
  );
};

export default Search;
