import PageWrapper from 'mobile/components/containers/PageWrapper';
import { useTranslation } from 'react-i18next';
import ExpandableHeader from '../components/commons/ExpandableHeader';
import Footer from '../sections/Footer';
import DownloadSection from '../sections/DownloadSection';
import JoinTeamSection from '../sections/about/JoinTeamSection';
import OurValuesSection from '../sections/about/OurValuesSection';
import StatsSection from '../sections/about/StatsSection';
import OurStorySection from '../sections/about/OurStorySection';
import TechnologiesSection from '../sections/about/TechnologiesSection';

const About = () => {
  const { t } = useTranslation('about');

  return (
    <ExpandableHeader>
      <PageWrapper>
        <JoinTeamSection />
        <OurValuesSection />
        <StatsSection />
        <OurStorySection />
        <TechnologiesSection />
        <DownloadSection
          subtitle={t('download.subtitle')}
          title={t('download.title')}
          downloadTitle={t('download.download-title')}
        />
        <Footer />
      </PageWrapper>
    </ExpandableHeader>
  );
};

export default About;
