import styled from 'styled-components';
import colors from 'assets/styles/colors';
import { zIndex } from 'assets/styles/zIndex';

type Position = 'belowHeader' | 'aboveHeader';

const Layout = styled.div<{ position: Position }>`
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: ${colors.white};
  z-index: ${props => (props.position === 'aboveHeader' ? zIndex.aboveHeader : zIndex.belowHeader)};
`;

interface Props {
  position?: Position;
}

const LoadingOverlay = ({ position = 'belowHeader' }: Props) => {
  return <Layout id='loading-overlay' position={position} />;
};

export default LoadingOverlay;
