import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import DeviceImage from './DeviceImage';

interface Props {
  id?: string;
  src: string;
  width: number;
  height: number;
  top: number;
  left: number;
}

const Shape = (props: Props) => {
  const shapeRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const timeline = gsap.timeline({ repeat: -1, yoyo: true });
    timeline.fromTo(shapeRef.current, { rotate: 10 }, { rotate: -10, ease: 'power1.inOut', duration: 4 });
    timeline.progress(Math.random());

    return () => {
      timeline.kill();
    };
  }, []);

  return <DeviceImage ref={shapeRef} {...props} />;
};

export default Shape;
