import React from 'react';
import LegalDocumentSection from 'desktop/components/legalDocument/LegalDocumentSection';
import SmoothScroll from 'desktop/components/containers/SmoothScroll';
import { ScrollbarProvider } from 'desktop/components/containers/ScrollbarProvider';
import { useTranslation } from 'react-i18next';
import { LegalDocument } from 'commons/legalDocument/legalDocumentObject';
import ExpandableHeader from 'desktop/components/commons/ExpandableHeader';
import DownloadSection from '../sections/DownloadSection';
import Footer from '../sections/Footer';

const Terms = () => {
  const { t } = useTranslation('terms');
  const termsOfService: LegalDocument = t('legalDocument', { returnObjects: true });

  return (
    <ScrollbarProvider>
      <ExpandableHeader />
      <SmoothScroll childrenFadeIn={true}>
        <LegalDocumentSection inputText={termsOfService} />
        <DownloadSection
          subtitle={t('home:download.subtitle')}
          title={t('home:download.title')}
          downloadTitle={t('home:download.download-title')}
        />
        <Footer />
      </SmoothScroll>
    </ScrollbarProvider>
  );
};

export default Terms;
