import { useTranslation } from 'react-i18next';
import ExpandableHeader from 'desktop/components/commons/ExpandableHeader';
import SmoothScroll from 'desktop/components/containers/SmoothScroll';
import { ScrollbarProvider } from 'desktop/components/containers/ScrollbarProvider';
import Footer from '../sections/Footer';
import DownloadSection from '../sections/DownloadSection';
import HelpEnvironmentSection from '../sections/saveThePlanet/HelpEnvironmentSection';
import GivingSecondLifeSection from '../sections/saveThePlanet/GivingSecondLifeSection';

const SaveThePlanet = () => {
  const { t } = useTranslation('saveThePlanet');

  return (
    <ScrollbarProvider>
      <ExpandableHeader />
      <SmoothScroll childrenFadeIn={true}>
        <HelpEnvironmentSection />
        <GivingSecondLifeSection />
        <DownloadSection
          subtitle={t('download.subtitle')}
          title={t('download.title')}
          downloadTitle={t('download.download-title')}
        />
        <Footer />
      </SmoothScroll>
    </ScrollbarProvider>
  );
};

export default SaveThePlanet;
