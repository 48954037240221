import { useScrollbar } from '../components/containers/ScrollbarProvider';
import { useGlobalNavigation } from '../../navigation/utils/NavigationProvider';

export const useNavigation = () => {
  const { navigateOrScrollToTop } = useGlobalNavigation();
  const { scrollToTop } = useScrollbar();

  return {
    navigate: (path: string) => navigateOrScrollToTop(path, scrollToTop),
  };
};
