import styled from 'styled-components';
import { refWidth } from 'desktop/utils/dimensions';
import colors from 'assets/styles/colors';
import { WithClassName, WithId } from 'commons/types/commonProps';
import { forwardRef, Ref } from 'react';
import { mq } from 'assets/styles/mediaQuery';
import HomeButton from './HomeButton';

const ButtonContainer = styled(HomeButton)`
  width: ${refWidth(144)}vw;
  height: ${refWidth(60)}vw;
  ${mq.desktopSmall} {
    width: ${refWidth(174)}vw;
    height: ${refWidth(70)}vw;
  }
  ${mq.phone} {
    width: ${refWidth(194)}vw;
    height: ${refWidth(80)}vw;
  }
`;

interface Props extends WithId, WithClassName {
  onClick: () => void;
  color?: string;
  text: string;
}

const LearnMoreButton = forwardRef(({ id, className, onClick, color, text }: Props, ref: Ref<HTMLDivElement>) => {
  return (
    <ButtonContainer
      ref={ref}
      id={id}
      className={className}
      onClick={onClick}
      color={color || colors.cello}
      text={text}
    />
  );
});

export default LearnMoreButton;
