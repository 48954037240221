import React from 'react';
import { useTranslation } from 'react-i18next';
import ValuesListSection from '../../components/commons/ValuesListSection';
import valueSupportiveImg from '../../../assets/images/about/value-supportive.svg';
import valueSustainabilityImg from '../../../assets/images/about/value-sustainability.svg';
import valueSocialImpactImg from '../../../assets/images/about/value-social-impact.svg';

const OurValuesSection = () => {
  const { t } = useTranslation('about');

  return (
    <ValuesListSection
      header={t('our-values.header')}
      values={[
        {
          image: valueSupportiveImg,
          width: 178,
          title: t('our-values.value-supportive.header'),
          description: t('our-values.value-supportive.description'),
        },
        {
          image: valueSustainabilityImg,
          width: 144,
          title: t('our-values.value-sustainability.header'),
          description: t('our-values.value-sustainability.description'),
        },
        {
          image: valueSocialImpactImg,
          width: 174,
          title: t('our-values.value-social-impact.header'),
          description: t('our-values.value-social-impact.description'),
        },
      ]}
    />
  );
};

export default OurValuesSection;
