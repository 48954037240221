import styled from 'styled-components';
import { refFontSize, refFontSizeLandscape } from 'mobile/utils/dimensions';
import { mq } from '../../../assets/styles/mediaQuery';
import colors from '../../../assets/styles/colors';
import { fonts } from '../../../assets/styles/fonts';
import WalkthroughSlider from './WalkthroughSlider';
import BulletTextSection from './BulletTextSection';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
`;

const Description = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(14)}vw;
  line-height: 2em;
  text-align: center;
  color: ${colors.white};

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(14)}vw;
  }
`;

interface Props {
  title: string;
  description: string;
  downloadTitle: string;
}

const BulletDeviceDownload = ({ title, description, downloadTitle }: Props) => {
  return (
    <Container>
      <BulletTextSection title={title} description={description} />
      <WalkthroughSlider />
      <Description>{downloadTitle}</Description>
    </Container>
  );
};

export default BulletDeviceDownload;
