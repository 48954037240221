import React from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { refHeight, refWidth } from 'desktop/utils/dimensions';
import { useScrollbar } from 'desktop/components/containers/ScrollbarProvider';
import { isTablet } from 'react-device-detect';
import { mq } from '../../assets/styles/mediaQuery';

const Container = styled.div`
  align-items: center;
  position: relative;
  margin-left: ${refWidth(isTablet ? 100 : 220)}vw;
  margin-right: ${refWidth(isTablet ? 100 : 200)}vw;
  padding-bottom: ${refWidth(60)}vw;
`;

const FooterWrapper = styled.div`
  margin-top: ${refHeight(100)}vh;
  display: flex;
  justify-content: space-around;
  width: 100%;

  ${mq.desktopSmall} {
    margin-top: ${refHeight(80)}vh;
  }
`;

const Footer = () => {
  const { useScrollReady } = useScrollbar();
  useScrollReady(setupAnimation);

  return (
    <Container>
      <FooterWrapper />
    </Container>
  );
};

const setupAnimation = () => {
  const animation = gsap.from('.footer', {
    y: 20,
    opacity: 0,
    stagger: 0.1,
    ease: 'easeInOut',
    scrollTrigger: {
      trigger: '.footer',
      start: 'center bottom',
      end: 'bottom bottom',
      scrub: 1,
    },
  });

  return () => {
    animation.kill();
  };
};

export default Footer;
