import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import UserIcon from '../../../assets/images/faq/user-icon.svg';
import MessageIcon from '../../../assets/images/faq/message-icon.svg';
import FavoriteIcon from '../../../assets/images/faq/favorite-icon.svg';
import { refWidth, refWidthLandscape } from '../../utils/dimensions';
import { mq } from '../../../assets/styles/mediaQuery';

const IconContainer = styled.img`
  margin-bottom: -${refWidth(4)}vw;
  width: ${refWidth(16)}vw;

  ${mq.landscape} {
    margin-bottom: -${refWidthLandscape(3)}vw;
    width: ${refWidthLandscape(14)}vw;
  }
`;

interface Props {
  text: string;
}

const TranslationWithIcon = ({ text }: Props) => {
  return (
    <Trans
      defaults={text}
      components={[
        <IconContainer src={UserIcon} alt={'user'} />,
        <IconContainer src={MessageIcon} alt={'message'} />,
        <IconContainer src={FavoriteIcon} alt={'favorite'} />,
      ]}
    />
  );
};

export default TranslationWithIcon;
