import { useTranslation } from 'react-i18next';
import fundOthersImg from 'assets/images/contribute/contributeFundOthers.png';
import shape1 from 'assets/images/contribute/shapes/fundOthers/shape1.svg';
import shape2 from 'assets/images/contribute/shapes/fundOthers/shape2.svg';
import shape3 from 'assets/images/contribute/shapes/fundOthers/shape3.svg';
import React from 'react';
import PhotoTextSection from '../../components/commons/PhotoTextSection';
import ShortSectionContainer from '../../components/containers/ShortSectionContainer';

const TaxDeductibleSection = () => {
  const { t } = useTranslation('contribute');

  return (
    <ShortSectionContainer id={'contribute-tax-deductible'}>
      <PhotoTextSection
        sectionClassName={'contribute-tax-deductible'}
        title={t('tax-deductible.title')}
        description={t('tax-deductible.description')}
        image={{ source: fundOthersImg, dimensions: { height: 556, width: 642 } }}
        reversed={true}
        shapes={[
          { source: shape1, dimensions: { left: 333, top: 12, width: 99, height: 97 } },
          { source: shape2, dimensions: { left: -100, top: 228, width: 164, height: 164 } },
          { source: shape3, dimensions: { left: -127, top: -6, width: 274, height: 265 } },
        ]}
      />
    </ShortSectionContainer>
  );
};

export default TaxDeductibleSection;
