import progress from 'assets/images/device/goal/progress.svg';
import close from 'assets/images/device/goal/close.svg';
import header from 'assets/images/device/goal/header.svg';
import description from 'assets/images/device/goal/description.svg';
import inputPlaceholder from 'assets/images/device/goal/input-placeholder.svg';
import inputLine from 'assets/images/device/goal/input-line.svg';
import back from 'assets/images/device/goal/back.svg';
import next from 'assets/images/device/goal/next.svg';
import keypad from 'assets/images/device/goal/keypad.svg';
import keyPressed from 'assets/images/device/goal/key-pressed.svg';
import charDollar from 'assets/images/device/goal/charDollar.svg';
import char4 from 'assets/images/device/goal/char4.svg';
import char5 from 'assets/images/device/goal/char5.svg';
import char0 from 'assets/images/device/goal/char0.svg';

import DeviceImage from './DeviceImage';

const Goal = () => {
  return (
    <div id={'device-goal'}>
      <div id={'device-goal-init'}>
        <DeviceImage src={progress} left={57} top={87} width={85} height={10} />
        <DeviceImage src={close} left={361} top={83} width={20} height={20} />
        <DeviceImage src={header} left={57} top={111} width={260} height={40} />
        <DeviceImage src={description} left={57} top={165} width={314} height={54} />
        <DeviceImage id={'goal-placeholder'} src={inputPlaceholder} left={57} top={244} width={182} height={49} />
        <DeviceImage src={inputLine} left={57} top={305} width={306} height={2} />
        <DeviceImage src={back} left={57} top={489} width={20} height={20} />
        <DeviceImage src={next} left={323} top={468} width={64} height={64} />
        <DeviceImage src={keypad} left={27} top={556} width={375} height={295} />
      </div>
      <div id={'device-goal-set'}>
        <DeviceImage id={'goal-key0'} src={keyPressed} left={156} top={726} width={117} height={47} />
        <DeviceImage id={'goal-key4'} src={keyPressed} left={33} top={616} width={117} height={47} />
        <DeviceImage id={'goal-key5'} src={keyPressed} left={156} top={616} width={117} height={47} />

        <DeviceImage id={'goal-currency'} src={charDollar} left={57} top={244} width={35} height={47} />
        <DeviceImage id={'goal-digit1'} src={char4} left={92} top={244} width={35} height={47} />
        <DeviceImage id={'goal-digit2'} src={char0} left={127} top={244} width={35} height={47} />
        <DeviceImage id={'goal-digit3'} src={char5} left={162} top={244} width={35} height={47} />
        <DeviceImage id={'goal-digit4'} src={char0} left={197} top={244} width={35} height={47} />
        <DeviceImage id={'goal-digit5'} src={char0} left={232} top={244} width={35} height={47} />
      </div>
    </div>
  );
};

export default Goal;
