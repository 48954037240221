export const fonts = {
  family: {
    main: 'Neue Haas Grotesk Display Pro, sans-serif',
    description: 'Poppins, sans-serif',
  },
  weight: {
    superLight: '300',
    light: '400',
    medium: '500',
    regular: '600',
    bold: '700',
  },
};
