import DeviceMockup from 'desktop/components/device/DeviceMockup';
import { WithId } from 'commons/types/commonProps';
import { DeviceContainer } from '../device/DeviceContainer';
import Search from '../device/Search';
import Withdraw from '../device/Withdraw';

const FundraiseDeviceSecond = ({ id }: WithId) => {
  return (
    <DeviceContainer id={id}>
      <DeviceMockup />
      <Search />
      <Withdraw />
    </DeviceContainer>
  );
};

export default FundraiseDeviceSecond;
