import * as unorphan from 'unorphan';
import { useEffect } from 'react';

const useOrphanPrevention = (selector?: string) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    unorphan(document.querySelectorAll(selector || 'h1, p, div'), { br: true });
  }, []);
};

export const useSingleElementOrphanPrevention = (elementId: string) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    unorphan(document.querySelector('#' + elementId), { br: true });
  }, []);
};

export default useOrphanPrevention;
