import collectImg from 'assets/images/fundraise/fundraiseCollect.png';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PhotoTextSection from '../../components/commons/PhotoTextSection';
import ShortSectionContainer from '../../components/containers/ShortSectionContainer';

const CollectSection = () => {
  const { t } = useTranslation('fundraise');

  return (
    <ShortSectionContainer id={'fundraise-collect'}>
      <PhotoTextSection
        sectionClassName={'fundraise-collect'}
        title={t('collect.title')}
        description={t('collect.description')}
        image={{ source: collectImg, dimensions: { height: 545, width: 590 } }}
        reversed={true}
      />
    </ShortSectionContainer>
  );
};

export default CollectSection;
