import { ScrollbarPlugin } from 'smooth-scrollbar';
import { Data2d } from 'smooth-scrollbar/interfaces';

class ScaleSpeedPlugin extends ScrollbarPlugin {
  static pluginName = 'scaleSpeed';

  static defaultOptions = {
    speed: 1,
  };

  transformDelta(delta: Data2d) {
    const { speed } = this.options;

    return {
      x: delta.x * speed,
      y: delta.y * speed,
    };
  }
}

export default ScaleSpeedPlugin;
