import colors from 'assets/styles/colors';
import styled from 'styled-components';
import { refFontSize, refFontSizeCalc, refWidth, refWidthCalc } from 'desktop/utils/dimensions';
import { useTranslation } from 'react-i18next';
import { mq } from 'assets/styles/mediaQuery';
import { fonts } from 'assets/styles/fonts';
import SectionContainer from '../containers/SectionContainer';
import LearnMoreButton from '../buttons/LearnMoreButton';
import { useNavigation } from '../../utils/useNavigation';

const Container = styled(SectionContainer)`
  background-color: ${colors.pancho};
`;

const TextWrapper = styled.div`
  margin-left: ${refWidth(240)}vw;

  ${mq.desktopDefault} {
    width: ${refWidth(1440)}vw;
  }

  ${mq.tablet} {
    left: ${refWidthCalc(240, 0.5)}vw;
  }

  ${mq.phone} {
    left: ${refWidthCalc(240, 0.4)}vw;
  }
`;

const Title = styled.div`
  position: absolute;
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.bold};
  font-size: ${refFontSize(120)}vw;
  line-height: 1em;
  color: ${colors.white};
  top: ${refWidth(174)}vw;
  opacity: 0;

  ${mq.tablet} {
    font-size: ${refFontSizeCalc(120, 1.15)}vw;
  }

  ${mq.phone} {
    font-size: ${refFontSizeCalc(120, 1.25)}vw;
  }
`;

const Description = styled.div`
  position: absolute;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.superLight};
  font-size: ${refFontSize(22)}vw;
  line-height: 1.5em;
  color: ${colors.white};
  top: ${refWidth(334)}vw;
  width: ${refWidth(480)}vw;
  opacity: 0;

  ${mq.tablet} {
    font-size: ${refFontSizeCalc(22, 1.15)}vw;
    top: ${refWidth(349)}vw;
  }

  ${mq.phone} {
    font-size: ${refFontSizeCalc(22, 1.25)}vw;
    line-height: normal;
  }
`;

const StyledLearnMoreButton = styled(LearnMoreButton)`
  width: ${refWidth(144)}vw;
  height: ${refWidth(60)}vw;
  top: ${refWidth(494)}vw;
  opacity: 0;

  ${mq.tablet} {
    top: ${refWidth(524)}vw;
  }

  ${mq.desktopSmall} {
    width: ${refWidth(174)}vw;
    height: ${refWidth(70)}vw;
  }

  ${mq.phone} {
    width: ${refWidth(194)}vw;
    height: ${refWidth(80)}vw;
  }
`;

const AppDescription = () => {
  const { t } = useTranslation('home');

  const { navigate } = useNavigation();

  const onClickGiveToEarn = () => navigate('/donate');
  const onClickSpendToHelp = () => navigate('/shop');
  const onClickShareToSave = () => navigate('/fund');

  return (
    <Container id={'home-app-description'}>
      <TextWrapper>
        <Title id={'home-donate-title'}>{t('donate.title')}</Title>
        <Title id={'home-shop-title'}>{t('shop.title')}</Title>
        <Title id={'home-fundraise-title'}>{t('fundraise.title')}</Title>

        <Description id={'home-donate-description'}>{t('donate.description')}</Description>
        <Description id={'home-shop-description'}>{t('shop.description')}</Description>
        <Description id={'home-fundraise-description'}>{t('fundraise.description')}</Description>

        <StyledLearnMoreButton onClick={onClickGiveToEarn} id={'home-give-to-earn'} text={t('give-to-earn')} />
        <StyledLearnMoreButton onClick={onClickSpendToHelp} id={'home-spend-to-help'} text={t('spend-to-help')} />
        <StyledLearnMoreButton onClick={onClickShareToSave} id={'home-share-to-save'} text={t('share-to-save')} />
      </TextWrapper>
    </Container>
  );
};

export default AppDescription;
