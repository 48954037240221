import { useTranslation } from 'react-i18next';
import buyFindDeals from 'assets/images/buy/buyFindDeals.png';
import shape1 from 'assets/images/buy/shapes/findDeals/shape1.svg';
import shape2 from 'assets/images/buy/shapes/findDeals/shape2.svg';
import shape3 from 'assets/images/buy/shapes/findDeals/shape3.svg';
import shape4 from 'assets/images/buy/shapes/findDeals/shape4.svg';
import BulletPhotoSection from '../BulletPhotoSection';

const FindDealsSection = () => {
  const { t } = useTranslation('buy');

  return (
    <BulletPhotoSection
      title={t('find-deals.title')}
      description={t('find-deals.description')}
      image={{ source: buyFindDeals, dimensions: { width: 315, height: 207 } }}
      shapes={[
        { source: shape1, dimensions: { left: -37, top: -49, width: 103, height: 100 } },
        { source: shape2, dimensions: { left: 69, top: -44, width: 34, height: 29 } },
        { source: shape3, dimensions: { left: 33, top: 128, width: 156, height: 119 } },
        { source: shape4, dimensions: { left: 267, top: -28, width: 34, height: 23 } },
      ]}
      hasTopPadding={true}
    />
  );
};

export default FindDealsSection;
