import styled from 'styled-components';
import { refFontSize, refWidth } from 'mobile/utils/dimensions';
import colors from 'assets/styles/colors';
import { fonts } from 'assets/styles/fonts';

const Container = styled.div<{ color: string }>`
  width: ${refWidth(130)}vw;
  height: ${refWidth(55)}vw;
  border-radius: ${refWidth(30)}vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.color};
  cursor: pointer;

  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.bold};
  color: ${colors.white};
  text-transform: uppercase;
  font-size: ${refFontSize(13)}vw;
  line-height: 1.5em;
  letter-spacing: 0.05em;
`;

interface Props {
  color: string;
  onClick: () => void;
  translation: string;
}

const MainPageButton = ({ color, onClick, translation }: Props) => {
  return (
    <Container onClick={onClick} color={color}>
      {translation}
    </Container>
  );
};

export default MainPageButton;
