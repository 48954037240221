import { dropShadow } from 'desktop/utils/dropShadow';
import gsap from 'gsap';
import { createShowHideTrigger } from './createShowHideTrigger';

export const animateGoal = (sectionId: string) => {
  const imagesShowFrom = { opacity: 0, scale: 1.5, duration: 1, stagger: 0.1, filter: dropShadow(10, 30, 5, 0.5) };

  const keyPressFrom = { opacity: 0 };
  const keyPressTo = { opacity: 1, yoyo: true, repeat: 1, duration: 0.1 };

  const digitShowFrom = { opacity: 0 };
  const digitShowTo = { opacity: 1, duration: 0.1 };

  // show goal screen
  const goalMain = gsap.timeline({ paused: true });
  goalMain.set('#loading-overlay', { display: 'none' });
  goalMain.from('#device-goal-init img', imagesShowFrom);

  // press 4
  goalMain.fromTo('#goal-key4', keyPressFrom, keyPressTo);
  goalMain.set('#goal-placeholder', { opacity: 0 }, '<');
  goalMain.fromTo('#goal-currency', digitShowFrom, digitShowTo, '<');
  goalMain.fromTo('#goal-digit1', digitShowFrom, digitShowTo, '<');

  // press 0
  goalMain.fromTo('#goal-key0', keyPressFrom, keyPressTo);
  goalMain.fromTo('#goal-digit2', digitShowFrom, digitShowTo, '<');

  // press 5
  goalMain.fromTo('#goal-key5', keyPressFrom, keyPressTo);
  goalMain.fromTo('#goal-digit3', digitShowFrom, digitShowTo, '<');

  // press 0
  goalMain.fromTo('#goal-key0', keyPressFrom, keyPressTo);
  goalMain.fromTo('#goal-digit4', digitShowFrom, digitShowTo, '<');

  // press 0
  goalMain.fromTo('#goal-key0', keyPressFrom, keyPressTo);
  goalMain.fromTo('#goal-digit5', digitShowFrom, digitShowTo, '<');

  const goalOut = gsap.timeline({ paused: true }).fromTo('#device-goal', { opacity: 1 }, { opacity: 0 });

  const triggerGoal = createShowHideTrigger(sectionId, goalMain, goalOut);

  return {
    kill: () => {
      triggerGoal.kill();
      goalMain.kill();
      goalOut.kill();
    },
  };
};
