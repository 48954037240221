import React from 'react';
import styled from 'styled-components';
import { isTablet } from 'react-device-detect';
import { refHeight, refHeightLandscape, refWidth } from '../utils/dimensions';
import { mq } from '../../assets/styles/mediaQuery';

const Container = styled.div`
  margin: auto;
  width: ${isTablet ? '75%' : 'auto'};
  display: grid;
  grid-row-gap: ${refHeight(28)}vh;
  padding: ${refHeight(0)}vh ${refWidth(30)}vw ${refHeight(0)}vh ${refWidth(30)}vw;

  ${mq.landscape} {
    grid-row-gap: ${refHeightLandscape(28)}vh;
    padding: ${refHeight(0)}vh ${refWidth(50)}vw;
  }
`;

const Footer = () => {
  return <Container />;
};

export default Footer;
