import ScreenSection from 'desktop/components/containers/ScreenSection';
import MainDescription from 'desktop/components/home/MainDescription';
import styled from 'styled-components';
import { zIndex } from 'assets/styles/zIndex';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useScrollbar } from '../../components/containers/ScrollbarProvider';

const StyledScreenSection = styled(ScreenSection)`
  z-index: ${zIndex.aboveInput};
`;

const MainSection = () => {
  const { useScrollReady } = useScrollbar();

  useScrollReady(setupAnimations);

  return (
    <StyledScreenSection id={'home-main'}>
      <MainDescription />
    </StyledScreenSection>
  );
};

const setupAnimations = () => {
  // pin device from main section to buy
  const pinDevice = gsap.timeline({
    scrollTrigger: { trigger: '#home-main', endTrigger: '#home-shop', pin: '#home-device-pin-wrapper' },
  });

  const deviceYStart = 30;
  const deviceYMiddle = 15;
  const deviceYTarget = 0;

  //show title, description and device after 0.5s since page loading
  const animMain = gsap.timeline();
  animMain.set('#loading-overlay', { display: 'none' });
  animMain.from('#home-main-title', { delay: 0.5, opacity: 0, yPercent: 10 }, 'start');
  animMain.from('#home-main-description', { opacity: 0, yPercent: 10 });
  animMain.from('#home-main-subtitle', { opacity: 0, yPercent: 10 });
  animMain.fromTo('#home-device', { yPercent: deviceYStart, opacity: 0 }, { yPercent: deviceYMiddle, opacity: 1 });

  // show logo after 1.25s, and keep swinging for 4s
  animMain.from('#home-main-logo img', { delay: 1.25, opacity: 0, yPercent: 10, ease: 'power1' }, 'start');
  animMain.from('#home-main-logo img', { rotate: -30, duration: 4, ease: 'elastic', transformOrigin: '50% 36%' }, '<');

  //show scroll info after 2s, and icon after 2.5s
  animMain.from('#home-main-scroll-description', { delay: 2, opacity: 0, yPercent: 30 }, 'start');
  animMain.from('#home-main-scroll-icon', { delay: 0.5, opacity: 0 }, '<');

  //show shapes after 1.5s with 0.25 delay each
  animMain.from('#home-main-shapes img', { delay: 1.5, opacity: 0, stagger: 0.25 }, 'start');

  //show eclipses after 2.5s with 0.1 delay each
  animMain.from('#home-main-eclipses img', { delay: 2.5, opacity: 0, stagger: 0.1 }, 'start');

  //show markers after 3s with 0.1 delay each
  animMain.from('#home-main-markers > div', { delay: 3, opacity: 0, stagger: 0.1 }, 'start');

  //show header after 3s
  //do not animate header directly as there is conflict with transition attribute
  animMain.from('#header > *', { delay: 3, opacity: 0, duration: 1 }, 'start');

  const animOut = gsap
    .timeline()
    .fromTo('#home-device', { yPercent: deviceYMiddle }, { yPercent: deviceYTarget })
    .fromTo('#home-main-eclipses', { opacity: 1 }, { opacity: 0 }, '<')
    .fromTo('#home-main-markers', { opacity: 1 }, { opacity: 0 }, '<')
    .fromTo('#home-main-logo', { opacity: 1 }, { opacity: 0 }, '<')
    .fromTo('#home-main-shapes', { opacity: 1 }, { opacity: 0 }, '<');

  const triggerMain = ScrollTrigger.create({
    trigger: '#home-main',
    start: '30% center',
    end: '70% center',
    animation: animMain,
    toggleActions: 'play complete none none',
  });

  const triggerOut = ScrollTrigger.create({
    trigger: '#home-main',
    start: '70% center',
    end: '100% center',
    animation: animOut,
    scrub: true,
  });

  return () => {
    triggerMain.kill();
    triggerOut.kill();

    pinDevice.kill();
    animMain.kill();
    animOut.kill();
  };
};

export default MainSection;
