import { animateDeviceOut } from 'desktop/animations/animateDeviceOut';
import { animateSimpleDevice } from 'desktop/animations/animateSimpleDevice';
import ScreenSection from '../../components/containers/ScreenSection';
import { useScrollbar } from '../../components/containers/ScrollbarProvider';

const WithdrawSection = () => {
  const { useScrollReady } = useScrollbar();

  useScrollReady(setupAnimations);

  return <ScreenSection id={'fundraise-withdraw'} />;
};

const setupAnimations = () => {
  const deviceOut = animateDeviceOut('#fundraise-withdraw', '#fundraise-device-second');
  const simpleDevice = animateSimpleDevice('#fundraise-withdraw', '#device-withdraw');

  return () => {
    deviceOut.kill();
    simpleDevice.kill();
  };
};

export default WithdrawSection;
