import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { refFontSize, refWidth } from 'desktop/utils/dimensions';
import colors from 'assets/styles/colors';
import gsap from 'gsap';
import { useScrollbar } from '../../components/containers/ScrollbarProvider';
import ShortSectionContainer from '../../components/containers/ShortSectionContainer';
import { TECHNOLOGIES } from '../../../commons/utils/technologiesConst';
import { fonts } from '../../../assets/styles/fonts';

const Container = styled(ShortSectionContainer)`
  flex-direction: column;
  height: auto;
  padding: ${refWidth(20)}vw 0;
`;

const HeaderTextWrapper = styled.div`
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(40)}vw;
  line-height: 1em;
  color: ${colors.cello};
`;

const TechnologiesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: ${refWidth(1440)}vw;
  margin-top: ${refWidth(40)}vw;
`;

const Technology = styled.div`
  background-color: ${colors.porcelain};
  color: ${colors.fadedJade};
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.medium};
  font-size: ${refFontSize(14)}vw;
  line-height: 2em;
  margin: ${refWidth(7)}vw ${refWidth(5)}vw;
  padding: ${refWidth(10)}vw ${refWidth(20)}vw;
  border-radius: ${refWidth(25)}vw;
`;

const TechnologiesSection = () => {
  const { t } = useTranslation('about');
  const { useScrollReady } = useScrollbar();

  const setupAnimations = () => {
    const descIn = gsap
      .timeline({
        scrollTrigger: { trigger: '#about-technologies', start: 'top 80%', end: 'bottom 80%', scrub: true },
      })
      .fromTo('#about-technologies-header', { y: 50, opacity: 0 }, { y: 0, opacity: 1, stagger: 0.2 })
      .fromTo('#about-technologies-wrapper', { y: 50, opacity: 0 }, { y: 0, opacity: 1, stagger: 0.2 });

    return () => {
      descIn.kill();
    };
  };

  useScrollReady(setupAnimations);

  return (
    <Container id={'about-technologies'}>
      <HeaderTextWrapper id={'about-technologies-header'}>{t('technologies.header')}</HeaderTextWrapper>
      <TechnologiesWrapper id={'about-technologies-wrapper'}>
        {TECHNOLOGIES.map(value => (
          <Technology key={value}>{value}</Technology>
        ))}
      </TechnologiesWrapper>
    </Container>
  );
};

export default TechnologiesSection;
