import ScreenSection from 'desktop/components/containers/ScreenSection';
import { FundraiseDeviceContainer } from 'desktop/components/fundraise/FundraiseDeviceContainer';
import { useScrollbar } from 'desktop/components/containers/ScrollbarProvider';
import FundraiseDeviceFirst from 'desktop/components/fundraise/FundraiseDeviceFirst';
import { animateDeviceOut } from 'desktop/animations/animateDeviceOut';
import { animateGoal } from 'desktop/animations/animateGoal';
import DetailsTextSection from 'desktop/components/commons/DetailsTextSection';
import { useTranslation } from 'react-i18next';
import { animateMainTextSection } from 'desktop/animations/animateMainTextSection';
import { animatePageIn } from '../../animations/animatePageIn';

const SetGoalSection = () => {
  const { t } = useTranslation('fundraise');
  const { useScrollReady } = useScrollbar();

  useScrollReady(setupAnimations);

  return (
    <ScreenSection id={'fundraise-set-goal'}>
      <FundraiseDeviceContainer id={'fundraise-pin-first'}>
        <FundraiseDeviceFirst id={'fundraise-device-first'} />
        <DetailsTextSection
          id={'fundraise-details'}
          header={t('set-goal.header')}
          title={t('set-goal.title')}
          subtitle={t('set-goal.subtitle')}
          description={t('set-goal.description')}
        />
      </FundraiseDeviceContainer>
    </ScreenSection>
  );
};

const setupAnimations = () => {
  const page = animatePageIn('#fundraise-set-goal');
  const goal = animateGoal('#fundraise-set-goal');
  const deviceOut = animateDeviceOut('#fundraise-set-goal', '#fundraise-device-first');
  const details = animateMainTextSection('#fundraise-details');

  return () => {
    page.kill();
    goal.kill();
    deviceOut.kill();
    details.kill();
  };
};

export default SetGoalSection;
