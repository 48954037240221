import ExpandableHeader from 'desktop/components/commons/ExpandableHeader';
import SmoothScroll from 'desktop/components/containers/SmoothScroll';
import { useTranslation } from 'react-i18next';
import { ScrollbarProvider } from 'desktop/components/containers/ScrollbarProvider';
import LoadingOverlay from 'desktop/components/containers/LoadingOverlay';
import useOrphanPrevention from 'commons/utils/useOrphanPrevention';
import DownloadSection from '../sections/DownloadSection';
import Footer from '../sections/Footer';
import StartCampaignSection from '../sections/fundraise/StartCampaignSection';
import SetGoalSection from '../sections/fundraise/SetGoalSection';
import ShareSection from '../sections/fundraise/ShareSection';
import SupportSection from '../sections/fundraise/SupportSection';
import WithdrawSection from '../sections/fundraise/WithdrawSection';
import CollectSection from '../sections/fundraise/CollectSection';

const SNAP_POINTS = [
  '#fundraise-set-goal',
  '#fundraise-start-campaign',
  '#fundraise-share',
  '#fundraise-support',
  '#fundraise-withdraw',
  '#fundraise-collect',
  '#download-section',
];

const Fundraise = () => {
  const { t } = useTranslation('fundraise');
  useOrphanPrevention();

  return (
    <ScrollbarProvider>
      <ExpandableHeader />
      <LoadingOverlay />
      <SmoothScroll snapPointsConfig={{ elementIds: SNAP_POINTS, snapToEnd: true }}>
        <SetGoalSection />
        <StartCampaignSection />
        <ShareSection />
        <SupportSection />
        <WithdrawSection />
        <CollectSection />
        <DownloadSection
          subtitle={t('download.subtitle')}
          title={t('download.title')}
          downloadTitle={t('download.download-title')}
        />
        <Footer />
      </SmoothScroll>
    </ScrollbarProvider>
  );
};

export default Fundraise;
