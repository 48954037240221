import { useTranslation } from 'react-i18next';
import wasteLessImg from 'assets/images/beEco.png';
import React from 'react';
import shape1 from 'assets/images/buy/shapes/wasteLess/shape1.svg';
import shape2 from 'assets/images/buy/shapes/wasteLess/shape2.svg';
import PhotoTextSection from '../../components/commons/PhotoTextSection';
import ShortSectionContainer from '../../components/containers/ShortSectionContainer';

const WasteLessSection = () => {
  const { t } = useTranslation('buy');

  return (
    <ShortSectionContainer id={'buy-waste-less'}>
      <PhotoTextSection
        sectionClassName={'buy-waste-less'}
        title={t('waste-less.title')}
        description={t('waste-less.description')}
        image={{ source: wasteLessImg, dimensions: { height: 596, width: 650 } }}
        reversed={true}
        shapes={[
          { source: shape1, dimensions: { left: 531, top: 100, width: 97, height: 122 } },
          { source: shape2, dimensions: { left: 234, top: 484, width: 74, height: 41 } },
        ]}
      />
    </ShortSectionContainer>
  );
};

export default WasteLessSection;
