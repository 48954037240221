import { useTranslation } from 'react-i18next';
import BulletDeviceSection from '../BulletDeviceSection';
import buySearchItems from '../../../assets/images/buy/buySearchItems.png';

const SearchItemsSection = () => {
  const { t } = useTranslation('buy');

  return (
    <BulletDeviceSection
      title={t('search-items.title')}
      description={t('search-items.description')}
      bullet={{ ordinal: 1 }}
      image={buySearchItems}
      variant={'buy'}
      hasTopPadding={true}
    />
  );
};

export default SearchItemsSection;
