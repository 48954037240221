import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { zIndex } from 'assets/styles/zIndex';
import { refHeight, refHeightLandscape, refWidth } from '../../utils/dimensions';
import LogoButton from '../buttons/LogoButton';
import MenuButton from '../buttons/MenuButton';
import colors from '../../../assets/styles/colors';
import { mq } from '../../../assets/styles/mediaQuery';

const Container = styled.div<{ isOpen: boolean }>`
  z-index: ${zIndex.header};
  position: sticky;
  top: 0;
  height: ${refHeight(100)}vh;
  display: flex;
  width: 100%;
  background-color: ${({ isOpen }) => (isOpen ? colors.aliceBlue : colors.white)};
  transition: all 0.3s linear;
  align-items: center;

  ${mq.landscape} {
    height: ${refHeightLandscape(80)}vh;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledLogoButton = styled(LogoButton)`
  margin-left: ${refWidth(20)}vw;
`;

const StyledMenuButton = styled(MenuButton)`
  position: relative;
  margin-right: ${refWidth(25)}vw;
`;

interface Props {
  isOpenedMenuVariant: boolean;
  onToggleMenu: () => void;
}

const Header = ({ isOpenedMenuVariant, onToggleMenu }: Props) => {
  const history = useHistory();

  return (
    <Container isOpen={isOpenedMenuVariant}>
      <Wrapper>
        <StyledLogoButton
          onClick={() => {
            history.replace('/');
            isOpenedMenuVariant && onToggleMenu();
          }}
        />
        <StyledMenuButton onClick={onToggleMenu} open={isOpenedMenuVariant} />
      </Wrapper>
    </Container>
  );
};

export default Header;
