import React from 'react';
import Navigation from './navigation/Navigation';
import GlobalStyle from './assets/styles/GlobalStyles';
import './translations/config';
import './assets/styles/fonts.css';

const App = () => (
  <>
    <GlobalStyle />
    <Navigation />
  </>
);

export default App;
