import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import colors from '../../../assets/styles/colors';
import { refWidth, refFontSize } from '../../utils/dimensions';
import logo from '../../../assets/images/logo-symbol.svg';
import xIcon from '../../../assets/images/menuXicon.svg';
import { fonts } from '../../../assets/styles/fonts';
import { mq } from '../../../assets/styles/mediaQuery';
import { useStoreLink } from '../../../commons/utils/links';
import { useSingleElementOrphanPrevention } from '../../../commons/utils/useOrphanPrevention';
import { zIndex } from '../../../assets/styles/zIndex';

const NavbarContainer = styled.div<{ visible: boolean; height: number }>`
  width: 100%;
  position: absolute;
  z-index: ${zIndex.aboveInput};
  height: ${({ visible, height }) => (visible ? height : 0)}vw;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  top: 0;
  background-color: ${colors.aliceBlue};
  transition: ${({ visible }) => (visible ? '0.3s ease-out' : '0.3s ease-in')};
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mq.landscape} {
    height: ${({ visible, height }) => (visible ? 0.72 * height : 0)}vw;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${refWidth(42)}vw;
`;

const Logo = styled.img`
  width: ${refWidth(60)}vw;
  height: auto;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${refWidth(25)}vw;
`;

const Header = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(20)}vw;
  line-height: 1.5em;
  color: ${colors.fadedJade};
`;

const Description = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(20)}vw;
  line-height: 1.5em;
  color: ${colors.fadedJade};
`;

const StoreMobileButton = styled.div`
  cursor: pointer;
  transition: transform 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${refWidth(36)}vw;
  color: ${colors.white};
  text-transform: uppercase;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.bold};
  font-size: ${refFontSize(17)}vw;
  line-height: 1.5em;
  background-color: ${colors.red};
  border-radius: ${refWidth(35)}vw;
  width: ${refWidth(85)}vw;
  height: ${refWidth(45)}vw;

  &:hover {
    transform: scale(1.05);
  }
`;

const CloseIcon = styled.img`
  width: ${refWidth(24)}vw;
  height: auto;
  margin-right: ${refWidth(40)}vw;
  padding-left: ${refWidth(22)}vw;
`;

interface Props {
  visible: boolean;
  onClose: () => void;
}

const Navbar = ({ visible, onClose }: Props) => {
  useSingleElementOrphanPrevention('navbar-header');
  const { openMobileStoreLink } = useStoreLink();
  const { t } = useTranslation('common');

  return (
    <>
      <NavbarContainer visible={visible} height={refWidth(100)}>
        <ContentContainer>
          <Logo src={logo} />
          <TextContainer>
            <Header id={'navbar-header'}>{t('banner.header')}</Header>
            <Description>{t('banner.description')}</Description>
          </TextContainer>
          <StoreMobileButton onClick={openMobileStoreLink}>{t('banner.button')}</StoreMobileButton>
        </ContentContainer>
        <CloseIcon src={xIcon} onClick={onClose} />
      </NavbarContainer>
    </>
  );
};

export default Navbar;
