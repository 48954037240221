import React from 'react';
import { Section as SectionObject } from '../../../commons/components/faq/faqObjects';
import { RollableWrapper } from '../../../commons/components/faq/RollableWrapper';
import { useFAQContext } from '../../../commons/components/faq/FAQProvider';
import HeaderWrapper from './HeaderWrapper';
import Question from './Question';

interface Props {
  section: SectionObject;
}

const Section = ({ section }: Props) => {
  const { id, expanded, header, questions } = section;
  const { toggleSection } = useFAQContext();

  return (
    <>
      <HeaderWrapper id={id} header={header} expanded={expanded} toggleAction={toggleSection} />
      <RollableWrapper expanded={expanded}>
        {questions.map((question, index) => {
          return <Question key={index} question={question} />;
        })}
      </RollableWrapper>
    </>
  );
};

export default Section;
