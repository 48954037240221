import DeviceMockup from 'desktop/components/device/DeviceMockup';
import { WithId } from 'commons/types/commonProps';
import { DeviceContainer } from '../device/DeviceContainer';
import Goal from '../device/Goal';

const FundraiseDeviceFirst = ({ id }: WithId) => {
  return (
    <DeviceContainer id={id}>
      <DeviceMockup />
      <Goal />
    </DeviceContainer>
  );
};

export default FundraiseDeviceFirst;
