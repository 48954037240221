import styled from 'styled-components';
import { refHeight, refWidth } from 'desktop/utils/dimensions';
import { zIndex } from 'assets/styles/zIndex';
import DownloadButton from '../buttons/DownloadButton';
import MenuButton, { DESKTOP_MENU_BETWEEN_DISTANCE } from '../buttons/MenuButton';
import LogoButton from '../buttons/LogoButton';
import { useScrollbar } from '../containers/ScrollbarProvider';
import { animations } from '../../../assets/styles/animations';
import { mq } from '../../../assets/styles/mediaQuery';
import { useNavigation } from '../../utils/useNavigation';

const Container = styled.div<{ padded: boolean }>`
  z-index: ${zIndex.header};
  position: absolute;
  height: ${refHeight(60)}vh;
  transition: ${animations.standardDuration} ease-in;
  top: ${({ padded }) => (padded ? refWidth(80) : refWidth(40))}vw;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledLogoButton = styled(LogoButton)`
  left: ${refWidth(50)}%;
  position: fixed;
`;

const StyledMenuButton = styled(MenuButton)`
  right: ${refWidth(40)}%;
  position: fixed;

  ${mq.desktopSmall} {
    &:before {
      content: '';
      position: relative;
      display: flex;
      width: ${8 * DESKTOP_MENU_BETWEEN_DISTANCE}vw;
      height: ${6 * DESKTOP_MENU_BETWEEN_DISTANCE}vw;
      margin: -${2 * DESKTOP_MENU_BETWEEN_DISTANCE}vw;
    }
  }
`;

const StyledDownloadButton = styled(DownloadButton)`
  right: ${refWidth(180)}%;
  position: fixed;
`;

interface Props {
  isOpenedMenuVariant: boolean;
  onToggleMenu: () => void;
  isPadded: boolean;
}

const Header = ({ isOpenedMenuVariant, onToggleMenu, isPadded }: Props) => {
  const { scrollIntoView } = useScrollbar();
  const { navigate } = useNavigation();

  const onDownloadClick = () => {
    isOpenedMenuVariant && onToggleMenu();
    scrollIntoView('#download-section');
  };

  return (
    <Container id={'header'} padded={isPadded}>
      <StyledLogoButton
        onClick={() => {
          navigate('/');
          isOpenedMenuVariant && onToggleMenu();
        }}
      />
      <StyledDownloadButton onClick={onDownloadClick} />
      <StyledMenuButton onClick={onToggleMenu} open={isOpenedMenuVariant} />
    </Container>
  );
};

export default Header;
