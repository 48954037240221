import styled from 'styled-components';
import { refFontSize, refFontSizeLandscape, refWidth, refWidthLandscape } from 'mobile/utils/dimensions';
import { fonts } from 'assets/styles/fonts';
import { variantConfig, VariantType } from 'commons/types/bulletTextVariant';
import { useTranslation } from 'react-i18next';
import { mq } from 'assets/styles/mediaQuery';
import { FontSizeType } from './types';

const Container = styled.div`
  width: ${refWidth(315)}vw;

  ${mq.landscape} {
    width: ${refFontSizeLandscape(315)}vw;
  }
`;

const BulletWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const OrdinalWrapper = styled.div<{ color: string }>`
  width: ${refWidth(32)}vw;
  height: ${refWidth(32)}vw;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${refWidth(10)}vw;

  ${mq.landscape} {
    width: ${refWidthLandscape(32)}vw;
    height: ${refWidthLandscape(32)}vw;
    margin-right: ${refWidthLandscape(10)}vw;
  }
`;

const OrdinalTextWrapper = styled.div<{ color: string }>`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(12)}vw;
  line-height: 1.75em;
  color: ${({ color }) => color};

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(12)}vw;
  }
`;

const TextWrapper = styled.div<{ color: string }>`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(12)}vw;
  line-height: 1.5em;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: ${({ color }) => color};

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(12)}vw;
  }
`;

const Title = styled.div<{ color: string; fontType: FontSizeType }>`
  margin-top: ${refWidth(20)}vw;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${({ fontType }) => refFontSize(fontType === 'big' ? 40 : 24)}vw;
  line-height: 1.25em;
  text-align: center;
  color: ${({ color }) => color};

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(24)}vw;
    margin-top: ${refWidthLandscape(20)}vw;
  }
`;

const Description = styled.div<{ color: string; fontType: FontSizeType }>`
  margin-top: ${refWidth(25)}vw;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${({ fontType }) => refFontSize(fontType === 'big' ? 18 : 14)}vw;
  line-height: 2em;
  text-align: center;
  color: ${({ color }) => color};
  white-space: pre-line;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(14)}vw;
    margin-top: ${refWidthLandscape(25)}vw;
  }
`;

interface Props {
  title: string;
  description: string;
  bullet?: { text: string; ordinal?: number } | { text?: string; ordinal: number };
  variant?: VariantType;
  fontSizeType?: FontSizeType;
}

const BulletTextSection = ({ title, description, bullet, variant, fontSizeType }: Props) => {
  const variantColors = variantConfig.get(variant || 'mixed')!;
  const { t } = useTranslation();

  return (
    <Container>
      {bullet && (
        <BulletWrapper>
          {bullet.ordinal && (
            <OrdinalWrapper color={variantColors.bullet}>
              <OrdinalTextWrapper color={variantColors.ordinal}>{bullet.ordinal}</OrdinalTextWrapper>
            </OrdinalWrapper>
          )}
          <TextWrapper color={variantColors.bullet}>{bullet.text || t('how-it-works')}</TextWrapper>
        </BulletWrapper>
      )}
      <Title fontType={fontSizeType || 'medium'} color={variantColors.title}>
        {title}
      </Title>
      <Description fontType={fontSizeType || 'medium'} color={variantColors.description}>
        {description}
      </Description>
    </Container>
  );
};

export default BulletTextSection;
