import DeviceMockup from 'desktop/components/device/DeviceMockup';
import { WithId } from 'commons/types/commonProps';
import { DeviceContainer } from '../device/DeviceContainer';
import Search from '../device/Search';
import Item from '../device/Item';
import Chat from '../device/Chat';

const BuyDeviceSecond = ({ id }: WithId) => {
  return (
    <DeviceContainer id={id}>
      <DeviceMockup />
      <Search />
      <Item />
      <Chat />
    </DeviceContainer>
  );
};

export default BuyDeviceSecond;
