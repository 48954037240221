import React from 'react';
import styled from 'styled-components';
import { refWidth } from '../../utils/dimensions';
import ScrollDownInfo from './ScrollDownInfo';

const StyledScrollDown = styled(ScrollDownInfo)`
  position: absolute;
  left: ${refWidth(260)}vw;
  bottom: ${refWidth(120)}vw;
`;

const StyledScrollDownInfo = () => {
  return <StyledScrollDown />;
};

export default StyledScrollDownInfo;
