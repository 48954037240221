import { createGlobalStyle } from 'styled-components';
import { useMobileVersion } from '../../commons/utils/useMobileVersion';

const GlobalStyleDesktop = createGlobalStyle`
  body {
    margin: 0;
    overflow: hidden;
  }
`;

const GlobalStyleMobile = createGlobalStyle`
  body {
    margin: 0;
  }
`;

const GlobalStyle = () => {
  const isMobile = useMobileVersion();

  return isMobile ? <GlobalStyleMobile /> : <GlobalStyleDesktop />;
};

export default GlobalStyle;
