import styled from 'styled-components';
import { refFontSize, refFontSizeLandscape, refWidth, refWidthLandscape } from '../utils/dimensions';
import { mq } from '../../assets/styles/mediaQuery';
import { fonts } from '../../assets/styles/fonts';
import colors from '../../assets/styles/colors';
import MainPageButton from '../components/buttons/MainPageButton';
import FrameAnimation from '../components/commons/FrameAnimation';
import { WithId } from '../../commons/types/commonProps';

const Container = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  padding-top: ${refWidth(50)}vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.landscape} {
    padding-top: ${refWidthLandscape(50)}vw;
  }
`;

const TextWrapper = styled.div`
  width: ${refWidth(315)}vw;

  ${mq.landscape} {
    width: ${refFontSizeLandscape(315)}vw;
  }
`;

const Title = styled.div`
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(50)}vw;
  line-height: 1em;
  color: ${colors.white};
  text-align: center;
  padding-bottom: 0.2em;
  margin-bottom: ${refWidth(25)}vw;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(50)}vw;
    margin-bottom: ${refWidthLandscape(25)}vw;
  }
`;

const Description = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(16)}vw;
  line-height: 1.75em;
  color: ${colors.white};
  text-align: center;
  margin-bottom: ${refWidth(50)}vw;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(16)}vw;
    margin-bottom: ${refWidthLandscape(50)}vw;
  }
`;

interface Props extends WithId {
  title: string;
  description: string;
  backgroundColor: string;
  frames: { directory: string; firstFrame: number; lastFrame: number };
  onMainButtonClick: () => void;
  translation: string;
}

const AnimatedDeviceSection = ({
  id,
  title,
  description,
  backgroundColor,
  frames,
  onMainButtonClick,
  translation,
}: Props) => {
  return (
    <Container id={id} color={backgroundColor}>
      <TextWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextWrapper>
      <MainPageButton color={colors.cello} onClick={onMainButtonClick} translation={translation} />
      <FrameAnimation {...frames} backgroundColor={backgroundColor} />
    </Container>
  );
};

export default AnimatedDeviceSection;
