import { useTranslation } from 'react-i18next';
import colors from 'assets/styles/colors';
import AnimatedDeviceSection from '../AnimatedDeviceSection';
import { useNavigation } from '../../utils/useNavigation';

const FundraiseSection = () => {
  const { t } = useTranslation('home');
  const { navigate } = useNavigation();

  const onMainButtonClick = () => navigate('/fundraise');

  return (
    <AnimatedDeviceSection
      title={t('fundraise.title')}
      description={t('fundraise.description')}
      backgroundColor={colors.blue}
      frames={{ directory: 'fundraise', firstFrame: 3000, lastFrame: 3152 }}
      onMainButtonClick={onMainButtonClick}
      translation={t('share-to-save')}
    />
  );
};

export default FundraiseSection;
