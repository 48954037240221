import colors from 'assets/styles/colors';
import user1 from 'assets/images/home/users/user1.png';
import item1 from 'assets/images/home/items/item1.png';
import user2 from 'assets/images/home/users/user2.png';
import item2 from 'assets/images/home/items/item2.png';
import user3 from 'assets/images/home/users/user3.png';
import item3 from 'assets/images/home/items/item3.png';
import styled from 'styled-components';
import { refHeight, refWidth } from 'desktop/utils/dimensions';
import Marker from './Marker';
import { REFERENCE_DEVICE_HEIGHT, REFERENCE_DEVICE_WIDTH } from './dimensions';

const StyledMarker = styled(Marker)<{ top: number; left: number }>`
  top: ${props => refHeight(props.top, REFERENCE_DEVICE_HEIGHT)}%;
  left: ${props => refWidth(props.left, REFERENCE_DEVICE_WIDTH)}%;
`;

const Markers = () => {
  const markerPopupConfig1 = { user: user1, item: item1, delay: 4, repeatDelay: 8 };
  const markerPopupConfig2 = { user: user2, item: item2, delay: 8, repeatDelay: 8 };
  const markerPopupConfig3 = { user: user3, item: item3, delay: 12, repeatDelay: 8 };

  return (
    <div id={'home-main-markers'}>
      <StyledMarker color={colors.red} left={251} top={154} />
      <StyledMarker color={colors.red} left={109} top={225} />
      <StyledMarker color={colors.red} left={355} top={298} />
      <StyledMarker color={colors.red} left={84} top={571} />
      <StyledMarker color={colors.red} left={175} top={665} />

      <StyledMarker color={colors.blue} left={326} top={119} />
      <StyledMarker color={colors.blue} left={292} top={530} />
      <StyledMarker color={colors.blue} left={213} top={611} />
      <StyledMarker color={colors.blue} left={251} top={717} />

      <StyledMarker color={colors.blue} left={241} top={263} popupConfig={markerPopupConfig1} />
      <StyledMarker color={colors.blue} left={89} top={409} popupConfig={markerPopupConfig2} />
      <StyledMarker color={colors.red} left={326} top={550} popupConfig={markerPopupConfig3} />
    </div>
  );
};

export default Markers;
