import React from 'react';
import styled from 'styled-components';
import { animations } from 'assets/styles/animations';
import colors from 'assets/styles/colors';

const Container = styled.div<{ answer?: boolean }>`
  width: ${props => (props.answer ? 14 : 18)}px;
  height: ${props => (props.answer ? 14 : 18)}px;
  position: relative;
  overflow: hidden;
  align-self: center;
`;

const StyledSpan = styled.div<{ rolledDown: boolean }>`
  position: absolute;
  width: calc(100% - 2px);
  height: 2px;
  background-color: ${colors.red};
  transition: ${animations.standardDuration} ease-in-out;
  top: 50%;

  &:nth-child(2) {
    opacity: ${({ rolledDown }) => (rolledDown ? 0 : 1)};
    transform: ${({ rolledDown }) => (rolledDown ? 'rotate(0)' : 'rotate(90deg)')};
  }
`;

interface Props {
  isRolledDown: boolean;
  isAnswer?: boolean;
}

const Sign = (props: Props) => {
  return (
    <Container answer={props.isAnswer}>
      <StyledSpan rolledDown={props.isRolledDown} />
      <StyledSpan rolledDown={props.isRolledDown} />
    </Container>
  );
};

export default Sign;
