import React from 'react';
import { useTranslation } from 'react-i18next';
import valueReduceImg from 'assets/images/saveThePlanet/value-reduce.svg';
import valueReuseImg from 'assets/images/saveThePlanet/value-reuse.svg';
import valueRepurposeImg from 'assets/images/saveThePlanet/value-repurpose.svg';
import ValuesListSection from '../../components/commons/ValuesListSection';

const GivingSecondLifeSection = () => {
  const { t } = useTranslation('saveThePlanet');

  return (
    <ValuesListSection
      header={t('giving-second-life.header')}
      id={'giving-second-life'}
      values={[
        {
          image: valueReduceImg,
          width: 208,
          top: -15,
          title: t('giving-second-life.value-reduce.header'),
          description: t('giving-second-life.value-reduce.description'),
        },
        {
          image: valueReuseImg,
          width: 240,
          top: 0,
          title: t('giving-second-life.value-reuse.header'),
          description: t('giving-second-life.value-reuse.description'),
        },
        {
          image: valueRepurposeImg,
          width: 213,
          top: 0,
          title: t('giving-second-life.value-repurpose.header'),
          description: t('giving-second-life.value-repurpose.description'),
        },
      ]}
    />
  );
};

export default GivingSecondLifeSection;
