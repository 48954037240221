import React from 'react';
import gsap from 'gsap';
import styled from 'styled-components';
import { refWidth } from 'desktop/utils/dimensions';
import { useScrollbar } from '../containers/ScrollbarProvider';
import { ImageAdjacentShape, ImageDimensions, ShapeDimensions } from './ImageAdjacentShape';

const ImageContainer = styled.div`
  position: relative;
  bottom: 0;
  right: 0;
`;

const Image = styled.img<{ width: number }>`
  width: ${props => refWidth(props.width)}vw;
`;

interface Props {
  sectionClassName: string;
  image: {
    dimensions: ImageDimensions;
    source: string;
  };
  shapes?: {
    dimensions: ShapeDimensions;
    source: string;
  }[];
}

const ImageWithShapes = ({ image, shapes, sectionClassName }: Props) => {
  const { useScrollReady } = useScrollbar();

  const setupAnimations = () => {
    if (shapes) {
      const timeline = gsap.timeline({ repeat: -1, yoyo: true });
      timeline.fromTo(
        '.' + sectionClassName + '-shape',
        { rotate: 10 },
        { rotate: -10, ease: 'power1.inOut', duration: 4 }
      );
      timeline.progress(Math.random());

      const imgIn = gsap.timeline({
        scrollTrigger: {
          trigger: '#' + sectionClassName,
          start: '30% center',
          end: '50% center',
          scrub: true,
          onLeaveBack: () => timeline.pause(),
          onEnter: () => timeline.resume(),
        },
      });

      const imgOut = gsap.timeline({
        scrollTrigger: {
          trigger: '#' + sectionClassName,
          start: '70% center',
          end: 'end center',
          scrub: true,
          onLeave: () => timeline.pause(),
          onEnterBack: () => timeline.resume(),
        },
      });

      return () => {
        timeline.kill();
        imgIn.kill();
        imgOut.kill();
      };
    }
  };

  useScrollReady(setupAnimations);

  return (
    <ImageContainer>
      <Image src={image.source} width={image.dimensions.width} />
      {shapes &&
        shapes.map((shape, index) => (
          <ImageAdjacentShape
            key={index}
            className={sectionClassName + '-shape'}
            shapeDimensions={shape.dimensions}
            imageDimensions={image.dimensions}
            src={shape.source}
          />
        ))}
    </ImageContainer>
  );
};

export default ImageWithShapes;
