import ScreenSection from 'desktop/components/containers/ScreenSection';
import { useScrollbar } from 'desktop/components/containers/ScrollbarProvider';
import { animateDeviceOut } from 'desktop/animations/animateDeviceOut';
import { animateSimpleDevice } from 'desktop/animations/animateSimpleDevice';
import { animateCarouselIn } from '../../animations/animateCarouselIn';
import { animateCarouselOut } from '../../animations/animateCarouselOut';

const ChooseCampaignSection = () => {
  const { useScrollReady } = useScrollbar();

  useScrollReady(setupAnimations);

  return <ScreenSection id={'contribute-choose-campaign'} />;
};

const setupAnimations = () => {
  const trigger = '#contribute-choose-campaign';
  const deviceOut = animateDeviceOut(trigger, '#contribute-device-second');
  const simpleDevice = animateSimpleDevice(trigger, '#device-description');
  const carouselIn = animateCarouselIn(trigger, '.contribute-choose-campaign-text');
  const carouselOut = animateCarouselOut(trigger, '.contribute-delivery-method-text');

  return () => {
    deviceOut.kill();
    simpleDevice.kill();
    carouselIn.kill();
    carouselOut.kill();
  };
};

export default ChooseCampaignSection;
