import ScreenSection from 'desktop/components/containers/ScreenSection';
import { animateSimpleDevice } from 'desktop/animations/animateSimpleDevice';
import { useScrollbar } from 'desktop/components/containers/ScrollbarProvider';
import { animateCarouselIn } from '../../animations/animateCarouselIn';
import { animateCarouselOut } from '../../animations/animateCarouselOut';

const DeliveryMethodSection = () => {
  const { useScrollReady } = useScrollbar();

  useScrollReady(setupAnimations);

  return <ScreenSection id={'contribute-delivery-method'} />;
};

const setupAnimations = () => {
  const trigger = '#contribute-delivery-method';

  const simpleDevice = animateSimpleDevice(trigger, '#device-delivery');
  const carouselIn = animateCarouselIn(trigger, '.contribute-delivery-method-text');
  const carouselOut = animateCarouselOut(trigger, '.contribute-set-price-text');

  return () => {
    simpleDevice.kill();
    carouselIn.kill();
    carouselOut.kill();
  };
};

export default DeliveryMethodSection;
