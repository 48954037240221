import styled from 'styled-components';
import device from 'assets/images/device.svg';

const Device = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
`;

const DeviceMockup = () => {
  return <Device src={device} />;
};

export default DeviceMockup;
