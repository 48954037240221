export const TECHNOLOGIES = [
  'Java',
  'Spring framework',
  'PostgreSQL',
  'Flyway',
  'RabbitMQ',
  'Redis',
  'JPA',
  'Hibernate',
  'Gradle',
  'Git',
  'React Native',
  'Typescript',
  'React.js',
  'Redux',
  'Jest',
  'Axios',
  'Styled Components',
  'Gatling',
  'Scala',
  'Docker',
  'Kubernetes',
  'Amazon Web Services',
  'Cloud AMQP',
  'ELK stack',
  'Kibana',
  'Grafana',
  'Prometheus',
  'Kong',
  'Jenkins',
  'Rundeck',
  'Fastlane',
  'Swagger',
];
