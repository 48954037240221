import styled from 'styled-components';
import { refHeight, refWidth } from 'desktop/utils/dimensions';
import logo from 'assets/images/logo-simple.svg';
import { REFERENCE_DEVICE_HEIGHT, REFERENCE_DEVICE_WIDTH } from './dimensions';

const Logo = styled.img`
  position: absolute;
  width: ${refWidth(123, REFERENCE_DEVICE_WIDTH)}%;
  height: ${refHeight(169, REFERENCE_DEVICE_HEIGHT)}%;
  top: ${refHeight(323, REFERENCE_DEVICE_HEIGHT)}%;
  left: ${refWidth(152, REFERENCE_DEVICE_WIDTH)}%;
`;

const DeviceLogo = () => {
  return (
    <div id={'home-main-logo'}>
      <Logo src={logo} />;
    </div>
  );
};

export default DeviceLogo;
