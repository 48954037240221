import React from 'react';
import { useTranslation } from 'react-i18next';
import ExpandableHeader from '../components/commons/ExpandableHeader';
import Footer from '../sections/Footer';
import PageWrapper from '../components/containers/PageWrapper';
import DownloadSection from '../sections/DownloadSection';
import FAQSection from '../components/faq/FAQSection';

const FAQ = () => {
  const { t } = useTranslation('home');
  return (
    <ExpandableHeader>
      <PageWrapper>
        <FAQSection />
        <DownloadSection
          subtitle={t('download.subtitle')}
          title={t('download.title')}
          downloadTitle={t('download.download-title')}
        />
        <Footer />
      </PageWrapper>
    </ExpandableHeader>
  );
};

export default FAQ;
