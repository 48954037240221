import React from 'react';
import gsap from 'gsap';
import { useScrollbar } from '../containers/ScrollbarProvider';
import ImageWithShapes from './ImageWithShapes';
import { ImageDimensions, ShapeDimensions } from './ImageAdjacentShape';
import BulletTextSection from './BulletTextSection';
import { FontSizeType, MarginType, WidthType } from './types';

interface Props {
  sectionClassName: string;
  bullet?: { text: string; ordinal?: number } | { text?: string; ordinal: number };
  title: string;
  description: string;
  image: {
    source: string;
    dimensions: ImageDimensions;
  };
  shapes?: {
    dimensions: ShapeDimensions;
    source: string;
  }[];
  reversed?: boolean;
  width?: WidthType;
  margin?: MarginType;
  titleFont?: FontSizeType;
}

const PhotoTextSection = ({
  sectionClassName,
  bullet,
  title,
  description,
  image,
  shapes,
  reversed,
  width,
  margin,
  titleFont,
}: Props) => {
  const { useScrollReady } = useScrollbar();

  const setupAnimations = () => {
    const descIn = gsap
      .timeline({
        scrollTrigger: { trigger: '#' + sectionClassName, start: '10% center', end: '50% center', scrub: true },
      })
      .fromTo('.' + sectionClassName + '-text', { y: 50, opacity: 0 }, { y: 0, opacity: 1, stagger: 0.2 });

    return () => {
      descIn.kill();
    };
  };

  useScrollReady(setupAnimations);

  return (
    <>
      {reversed && <ImageWithShapes sectionClassName={sectionClassName} image={image} shapes={shapes} />}
      <BulletTextSection
        title={title}
        description={description}
        sectionClassName={sectionClassName}
        bullet={bullet}
        reversed={reversed}
        width={width}
        margin={margin}
        titleFont={titleFont}
      />
      {!reversed && <ImageWithShapes sectionClassName={sectionClassName} image={image} shapes={shapes} />}
    </>
  );
};

export default PhotoTextSection;
