import React from 'react';
import { ListLevel } from './legalDocumentObject';
import StyledList from './StyledList';
import { StyledOl, LvlTwoLi, LIST_DEFAULT_PADDING } from './LegalDocumentListStyles';

interface Props {
  nestedList: ListLevel[];
  listStyle?: string;
  parentId: string;
}

const ThreeLevelList = ({ nestedList, listStyle, parentId }: Props) => {
  return (
    <StyledOl listStyle={listStyle}>
      {nestedList.map(lvlOne => {
        const lvlOneId = `${parentId}${nestedList.indexOf(lvlOne)}`;
        return (
          <div key={lvlOneId}>
            <li>{lvlOne.point}</li>
            {lvlOne.list && (
              <StyledList
                list={lvlOne.list}
                listStyle={lvlOne.listStyle}
                parentId={lvlOneId}
                addPadding={LIST_DEFAULT_PADDING}
              />
            )}
            {lvlOne.nestedList && (
              <StyledOl listStyle={lvlOne.listStyle}>
                {lvlOne.nestedList.map(lvlTwo => {
                  const lvlTwoId = `${lvlOneId}${lvlOne.nestedList!.indexOf(lvlTwo)}`;
                  return (
                    <div key={lvlTwoId}>
                      <LvlTwoLi>{lvlTwo.point}</LvlTwoLi>
                      {lvlTwo.list && (
                        <StyledList
                          list={lvlTwo.list}
                          listStyle={lvlTwo.listStyle}
                          parentId={lvlTwoId}
                          addPadding={2 * LIST_DEFAULT_PADDING}
                        />
                      )}
                    </div>
                  );
                })}
              </StyledOl>
            )}
          </div>
        );
      })}
    </StyledOl>
  );
};

export default ThreeLevelList;
