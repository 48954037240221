import header from 'assets/images/device/marketplace/header.svg';
import add from 'assets/images/device/marketplace/add.svg';
import search from 'assets/images/device/marketplace/search.svg';
import filters from 'assets/images/device/marketplace/filters.svg';
import browse from 'assets/images/device/marketplace/browse.svg';
import category1 from 'assets/images/device/marketplace/category1.svg';
import category2 from 'assets/images/device/marketplace/category2.svg';
import category3 from 'assets/images/device/marketplace/category3.svg';
import selected from 'assets/images/device/marketplace/selected.svg';
import item1 from 'assets/images/device/marketplace/item1.png';
import item2 from 'assets/images/device/marketplace/item2.png';
import menu from 'assets/images/device/marketplace/menu.svg';
import DeviceImage from './DeviceImage';

const Marketplace = () => {
  return (
    <div id={'device-marketplace'}>
      <DeviceImage src={header} left={42} top={66} width={284} height={40} />
      <DeviceImage src={add} left={302} top={71} width={87} height={35} />
      <DeviceImage src={search} left={42} top={118} width={345} height={43} />
      <DeviceImage src={filters} left={42} top={173} width={359} height={39} />
      <DeviceImage src={browse} left={41} top={243} width={347} height={28} />
      <DeviceImage src={category1} left={41} top={283} width={136} height={195} />
      <DeviceImage src={category2} left={192} top={283} width={136} height={195} />
      <DeviceImage src={category3} left={343} top={283} width={58} height={195} />
      <DeviceImage src={selected} left={40} top={509} width={347} height={28} />
      <DeviceImage src={item1} left={40} top={548} width={167} height={167} />
      <DeviceImage src={item2} left={220} top={548} width={167} height={167} />

      <DeviceImage src={menu} left={66} top={771} width={302} height={31} />
    </div>
  );
};

export default Marketplace;
