import PageWrapper from 'mobile/components/containers/PageWrapper';
import FindDealsSection from 'mobile/sections/buy/FindDealsSection';
import SearchItemsSection from 'mobile/sections/buy/SearchItemsSection';
import BuyWithClickSection from 'mobile/sections/buy/BuyWithClickSection';
import WasteLessSection from 'mobile/sections/buy/WasteLessSection';
import { useTranslation } from 'react-i18next';
import PurchaseSection from 'mobile/sections/buy/PurchaseSection';
import EveryDollarSection from 'mobile/sections/buy/EveryDollarSection';
import PickupGrabSection from 'mobile/sections/buy/PickupGrabSection';
import ExpandableHeader from '../components/commons/ExpandableHeader';
import Footer from '../sections/Footer';
import DownloadSection from '../sections/DownloadSection';

const Buy = () => {
  const { t } = useTranslation('buy');
  return (
    <ExpandableHeader>
      <PageWrapper>
        <PurchaseSection />
        <FindDealsSection />
        <EveryDollarSection />
        <SearchItemsSection />
        <BuyWithClickSection />
        <PickupGrabSection />
        <WasteLessSection />
        <DownloadSection
          subtitle={t('download.subtitle')}
          title={t('download.title')}
          downloadTitle={t('download.download-title')}
        />
        <Footer />
      </PageWrapper>
    </ExpandableHeader>
  );
};

export default Buy;
