import { useTranslation } from 'react-i18next';
import contributeSetPrice from 'assets/images/contribute/contributeSetPrice.png';
import BulletDeviceSection from '../BulletDeviceSection';

const SetPriceSection = () => {
  const { t } = useTranslation('contribute');

  return (
    <BulletDeviceSection
      title={t('set-price.title')}
      description={t('set-price.description')}
      bullet={{ ordinal: 2 }}
      image={contributeSetPrice}
      variant={'contribute'}
    />
  );
};

export default SetPriceSection;
