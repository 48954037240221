import React from 'react';
import styled from 'styled-components';
import { refFontSize, refWidth } from 'desktop/utils/dimensions';
import colors from 'assets/styles/colors';
import gsap from 'gsap';
import { useScrollbar } from '../containers/ScrollbarProvider';
import ShortSectionContainer from '../containers/ShortSectionContainer';
import { fonts } from '../../../assets/styles/fonts';

const Container = styled(ShortSectionContainer)`
  flex-direction: column;
  height: auto;
  padding: ${refWidth(20)}vw 0;
`;

const HeaderTextWrapper = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(14)}vw;
  line-height: 1.5em;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: ${colors.red};
`;

const ValuesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${refWidth(1440)}vw;
  margin-top: ${refWidth(175)}vw;
`;

const ValueContainer = styled.div`
  width: ${refWidth(361)}vw;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ValueImg = styled.img<{ width: number; top: number }>`
  position: absolute;
  width: ${props => refWidth(props.width)}vw;
  top: ${props => refWidth(props.top)}vw;
`;

const ValueHeader = styled.div`
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(40)}vw;
  line-height: 1em;
  color: ${colors.cello};
  margin-top: ${refWidth(230)}vw;
`;

const ValueDescription = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(18)}vw;
  line-height: 2em;
  color: ${colors.fadedJade};
  margin-top: ${refWidth(42)}vw;
`;

interface ValueType {
  image: string;
  width: number;
  top: number;
  title: string;
  description: string;
}

interface Props {
  header: string;
  id: string;
  values: ValueType[];
}

const ValuesListSection = (props: Props) => {
  const { useScrollReady } = useScrollbar();

  const setupAnimations = () => {
    const descIn = gsap
      .timeline({
        scrollTrigger: { trigger: '#values-' + props.id, start: 'top 80%', end: 'bottom 80%', scrub: true },
      })
      .fromTo('#values-header-' + props.id, { y: 50, opacity: 0 }, { y: 0, opacity: 1, stagger: 0.2 })
      .fromTo('#values-wrapper-' + props.id, { y: 50, opacity: 0 }, { y: 0, opacity: 1, stagger: 0.2 });

    return () => {
      descIn.kill();
    };
  };

  useScrollReady(setupAnimations);

  return (
    <Container id={'values-' + props.id}>
      <HeaderTextWrapper id={'values-header-' + props.id}>{props.header}</HeaderTextWrapper>
      <ValuesWrapper id={'values-wrapper-' + props.id}>
        {props.values.map((item, index) => (
          <ValueContainer key={index}>
            <ValueImg src={item.image} width={item.width} top={item.top} />
            <ValueHeader>{item.title}</ValueHeader>
            <ValueDescription>{item.description}</ValueDescription>
          </ValueContainer>
        ))}
      </ValuesWrapper>
    </Container>
  );
};

export default ValuesListSection;
