import React from 'react';
import explainerShort from 'assets/videos/giveo_explainer_short.mp4';
import styled from 'styled-components';
import { zIndex } from 'assets/styles/zIndex';
import { isTablet } from 'react-device-detect';
import InlineReactPlayer from '../commons/InlineReactPlayer';

const ExplainerContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: ${zIndex.under};
  opacity: 0.25;
  overflow: hidden;
`;

const PlayerContainer = styled.div`
  position: relative;
  left: -${isTablet ? 7 : 32}%;
`;

const ExplainerPlayer = () => {
  return (
    <>
      <ExplainerContainer>
        <PlayerContainer>
          <InlineReactPlayer
            url={explainerShort}
            controls={false}
            width={isTablet ? '114%' : '164%'}
            height='auto'
            playing={true}
            loop={true}
            muted={true}
          />
        </PlayerContainer>
      </ExplainerContainer>
    </>
  );
};

export default ExplainerPlayer;
