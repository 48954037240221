import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import colors from 'assets/styles/colors';
import { refFontSize, refWidth } from 'desktop/utils/dimensions';
import { mq } from 'assets/styles/mediaQuery';
import { fonts } from '../../../assets/styles/fonts';

const Wrapper = styled.div<{ backgroundColor: string }>`
  width: ${refWidth(141)}vw;
  height: ${refWidth(60)}vw;
  border-radius: ${refWidth(30)}vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: ${props => props.backgroundColor};
  cursor: pointer;
  position: relative;
  border: 1px solid ${colors.white};
  ${mq.desktopSmall} {
    width: ${refWidth(171)}vw;
    height: ${refWidth(70)}vw;
    border-radius: ${refWidth(35)}vw;
  }
  ${mq.phone} {
    width: ${refWidth(191)}vw;
    height: ${refWidth(80)}vw;
    border-radius: ${refWidth(40)}vw;
  }
`;

const Circle = styled.div`
  border-radius: 50%;
  width: 0;
  height: 0;
  position: absolute;
`;

const Text = styled.div`
  color: ${colors.white};
  position: absolute;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.bold};
  font-size: ${refFontSize(14)}vw;
  text-transform: uppercase;
  ${mq.desktopSmall} {
    font-size: ${refFontSize(18)}vw;
  }
  ${mq.phone} {
    font-size: ${refFontSize(21)}vw;
  }
`;

interface Props {
  className?: string;
  onClick: () => void;
}

const DownloadButton = ({ className, onClick }: Props) => {
  const buttonColors = [colors.blue, colors.red];

  const wrapperRef = useRef<HTMLDivElement>(null);
  const circleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timeline = gsap.timeline({ repeat: -1 });

    const circleSize = `${refWidth(150)}vw`;

    buttonColors.forEach(color => {
      timeline.set(circleRef.current, { background: color, width: 0, height: 0 });
      timeline.to(circleRef.current, { width: circleSize, height: circleSize, duration: 1, delay: 2, ease: 'power0' });
      timeline.set(wrapperRef.current, { backgroundColor: color });
    });

    return () => {
      timeline.kill();
    };
  }, []);

  return (
    <Wrapper
      ref={wrapperRef}
      className={className}
      backgroundColor={buttonColors[buttonColors.length - 1]}
      onClick={onClick}>
      <Circle ref={circleRef} />
      <Text>Download</Text>
    </Wrapper>
  );
};

export default DownloadButton;
