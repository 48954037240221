import { useTranslation } from 'react-i18next';
import fundraiseVideo from 'assets/videos/mobile_fundraise.mp4';
import colors from 'assets/styles/colors';
import VideoMainSection from '../VideoMainSection';

const SetGoalSection = () => {
  const { t } = useTranslation('fundraise');

  return (
    <VideoMainSection
      header={t('set-goal.header')}
      title={t('set-goal.title')}
      subtitle={t('set-goal.subtitle')}
      description={t('set-goal.description')}
      video={fundraiseVideo}
      backgroundColor={colors.blue}
    />
  );
};

export default SetGoalSection;
