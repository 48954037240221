import styled from 'styled-components';
import { mq } from '../../../assets/styles/mediaQuery';
import { refFontSize, refFontSizeLandscape, refWidth, refWidthLandscape } from '../../utils/dimensions';
import { fonts } from '../../../assets/styles/fonts';
import colors from '../../../assets/styles/colors';

const Container = styled.div`
  width: ${refWidth(315)}vw;

  ${mq.landscape} {
    width: ${refFontSizeLandscape(315)}vw;
  }
`;

const Title = styled.div`
  margin-top: ${refWidth(20)}vw;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(36)}vw;
  line-height: 1.25em;
  text-align: center;
  color: ${colors.white};

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(36)}vw;
    margin-top: ${refWidthLandscape(20)}vw;
  }
`;

const Description = styled.div`
  margin-top: ${refWidth(25)}vw;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(14)}vw;
  line-height: 2em;
  text-align: center;
  color: ${colors.white};

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(14)}vw;
    margin-top: ${refWidthLandscape(25)}vw;
  }
`;

interface Props {
  title: string;
  description: string;
}

const BulletTextSection = ({ title, description }: Props) => {
  return (
    <Container>
      <Description>{description}</Description>
      <Title>{title}</Title>
    </Container>
  );
};

export default BulletTextSection;
