import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';
import explainer from 'assets/videos/giveo_explainer.mp4';
import explainerShort from 'assets/videos/giveo_explainer_short.mp4';
import styled from 'styled-components';
import { refHeight, refWidth } from 'desktop/utils/dimensions';
import closeIcon from 'assets/images/home/seeApp/closeVideoButton.svg';
import { zIndex } from 'assets/styles/zIndex';
import { FullScreen, useFullScreenHandle } from 'react-full-screen/dist';
import colors from 'assets/styles/colors';
import InlineReactPlayer from '../../../mobile/components/commons/InlineReactPlayer';
import { mq } from '../../../assets/styles/mediaQuery';

const ExplainerContainer = styled.div`
  position: absolute;
  left: 0;
  width: ${refWidth(1080)}vw;
  box-sizing: border-box;
  border: ${refHeight(2)}vh solid ${colors.white};
`;

const CloseIconWrapper = styled.img<{ visible: boolean }>`
  width: ${refWidth(64)}vw;
  position: absolute;
  cursor: pointer;
  padding: ${refWidth(1)}vw;
  top: ${refHeight(56)}vw;
  right: ${refWidth(100)}vw;
  z-index: ${zIndex.popup};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  ${mq.tablet} {
    width: ${refWidth(92)}vw;
    top: ${refHeight(12)}vw;
  }
`;

interface Props {
  playFullScreen: boolean;
  onFullScreenExit: () => void;
  play: boolean;
}

const ExplainerPlayer = ({ playFullScreen, onFullScreenExit }: Props) => {
  const handle = useFullScreenHandle();

  useEffect(() => {
    if (playFullScreen && document.fullscreenEnabled) {
      handle.enter();
    }
  }, [playFullScreen]);

  return (
    <>
      {document.fullscreenEnabled && (
        <FullScreen handle={handle} onChange={(state: boolean) => !state && onFullScreenExit()}>
          {playFullScreen && (
            <>
              <ReactPlayer
                url={explainer}
                width='100%'
                height='100%'
                controls={false}
                playing={playFullScreen}
                muted={false}
              />
              <CloseIconWrapper visible={handle.active} src={closeIcon} onClick={handle.exit} />
            </>
          )}
        </FullScreen>
      )}
      {playFullScreen && !document.fullscreenEnabled && (
        <>
          <ReactPlayer
            url={explainer}
            height='100%'
            width='100%'
            controls={false}
            playing={true}
            muted={false}
            playsinline={false}
            onPause={onFullScreenExit}
          />
          <CloseIconWrapper visible={true} src={closeIcon} onClick={onFullScreenExit} />
        </>
      )}
      {(!playFullScreen || document.fullscreenEnabled) && (
        <ExplainerContainer>
          <InlineReactPlayer
            url={explainerShort}
            width='100%'
            height='100%'
            controls={false}
            playing={true}
            loop={true}
            muted={true}
          />
        </ExplainerContainer>
      )}
    </>
  );
};

export default ExplainerPlayer;
