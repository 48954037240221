import { useTranslation } from 'react-i18next';
import fundraiseCollect from 'assets/images/fundraise/fundraiseCollect.png';
import BulletPhotoSection from '../BulletPhotoSection';

const TaxDeductibleSection = () => {
  const { t } = useTranslation('contribute');

  return (
    <BulletPhotoSection
      title={t('tax-deductible.title')}
      description={t('tax-deductible.description')}
      image={{ source: fundraiseCollect, dimensions: { width: 260, height: 240 } }}
      hasTopPadding={true}
    />
  );
};

export default TaxDeductibleSection;
