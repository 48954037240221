import { useTranslation } from 'react-i18next';
import contributeUnwantedStuff from 'assets/images/contribute/contributeUnwantedStuff.png';
import shape2 from 'assets/images/contribute/shapes/unwantedStuff/shape2.svg';
import shape3 from 'assets/images/contribute/shapes/unwantedStuff/shape3.svg';
import shape4 from 'assets/images/contribute/shapes/unwantedStuff/shape4.svg';
import shape5 from 'assets/images/contribute/shapes/unwantedStuff/shape5.svg';
import BulletPhotoSection from '../BulletPhotoSection';

const UnwantedStuffSection = () => {
  const { t } = useTranslation('contribute');

  return (
    <BulletPhotoSection
      title={t('unwanted-stuff.title')}
      description={t('unwanted-stuff.description')}
      image={{ source: contributeUnwantedStuff, dimensions: { width: 315, height: 240 } }}
      shapes={[
        { source: shape2, dimensions: { left: -28, top: -24, width: 78, height: 108 } },
        { source: shape3, dimensions: { left: -42, top: 194, width: 32, height: 41 } },
        { source: shape4, dimensions: { left: 170, top: 122, width: 198, height: 172 } },
        { source: shape5, dimensions: { left: 42, top: -38, width: 33, height: 31 } },
      ]}
      hasTopPadding={true}
    />
  );
};

export default UnwantedStuffSection;
