import React from 'react';
import { useTranslation } from 'react-i18next';
import { LegalDocument } from '../../commons/legalDocument/legalDocumentObject';
import ExpandableHeader from '../components/commons/ExpandableHeader';
import LegalDocumentSection from '../components/legalDocument/LegalDocumentSection';
import Footer from '../sections/Footer';
import DownloadSection from '../sections/DownloadSection';

const PrivacyPolicy = () => {
  const { t } = useTranslation('privacyPolicy');
  const privacyPolicy: LegalDocument = t('legalDocument', { returnObjects: true });
  return (
    <ExpandableHeader>
      <LegalDocumentSection inputText={privacyPolicy} />
      <DownloadSection
        subtitle={t('home:download.subtitle')}
        title={t('home:download.title')}
        downloadTitle={t('home:download.download-title')}
      />
      <Footer />
    </ExpandableHeader>
  );
};

export default PrivacyPolicy;
