import { ReactComponent as FBIcon } from 'assets/images/fbIcon.svg';
import { ReactComponent as InstagramIcon } from 'assets/images/instagramIcon.svg';
import { ReactComponent as TwitterIcon } from 'assets/images/twitterIcon.svg';
import React from 'react';
import { openInNewTab } from '../../utils/links';
import SvgWithHoover from './SvgWithHoover';

const FACEBOOK_LINK = 'https://www.facebook.com/GiveoApp';
const INSTAGRAM_LINK = 'https://www.instagram.com/giveo_official/';
const TWITTER_LINK = 'https://twitter.com/GiveoApp';

interface Props {
  iconWidth: number;
  iconMarginRight: number;
}

const SocialMediaIcons = ({ iconWidth, iconMarginRight }: Props) => {
  const socialMediaIcons = [
    { src: FBIcon, onClick: () => openInNewTab(FACEBOOK_LINK), id: 1 },
    { src: InstagramIcon, onClick: () => openInNewTab(INSTAGRAM_LINK), id: 2 },
    { src: TwitterIcon, onClick: () => openInNewTab(TWITTER_LINK), id: 3 },
  ];

  return (
    <>
      {socialMediaIcons.map(icon => (
        <SvgWithHoover
          key={icon.id}
          Icon={icon.src}
          onClick={icon.onClick}
          iconWidth={iconWidth}
          iconMarginRight={iconMarginRight}
        />
      ))}
    </>
  );
};

export default SocialMediaIcons;
