import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import UserIcon from '../../../assets/images/faq/user-icon.svg';
import MessageIcon from '../../../assets/images/faq/message-icon.svg';
import FavoriteIcon from '../../../assets/images/faq/favorite-icon.svg';
import { refWidth } from '../../utils/dimensions';

const IconContainer = styled.img`
  margin-bottom: -${refWidth(6)}vw;
  width: ${refWidth(20)}vw;
  }
`;

interface Props {
  text: string;
}

const TranslationWithIcon = ({ text }: Props) => {
  return (
    <Trans
      defaults={text}
      components={[
        <IconContainer src={UserIcon} alt={'user'} />,
        <IconContainer src={MessageIcon} alt={'message'} />,
        <IconContainer src={FavoriteIcon} alt={'favorite'} />,
      ]}
    />
  );
};

export default TranslationWithIcon;
