import { ContributeDeviceContainer } from 'desktop/components/contribute/ContributeDeviceContainer';
import ContributeDeviceSecond from 'desktop/components/contribute/ContributeDeviceSecond';
import { useScrollbar } from 'desktop/components/containers/ScrollbarProvider';
import { animatePinDevice } from 'desktop/animations/animatePinDevice';
import { animateSimpleDevice } from 'desktop/animations/animateSimpleDevice';
import BulletTextSection from 'desktop/components/commons/BulletTextSection';
import { useTranslation } from 'react-i18next';
import CarouselContainer from 'desktop/components/containers/CarouselContainer';
import { animateCarouselIn } from 'desktop/animations/animateCarouselIn';
import { animateCarouselMain } from 'desktop/animations/animateCarouselMain';
import PinnedScreenSection from '../../components/containers/PinnedScreenSection';

const StartWithPictureSection = () => {
  const { t } = useTranslation('contribute');
  const { useScrollReady } = useScrollbar();

  useScrollReady(setupAnimations);

  return (
    <PinnedScreenSection id={'contribute-start-with-picture'}>
      <ContributeDeviceContainer id={'contribute-pin-second'}>
        <ContributeDeviceSecond id={'contribute-device-second'} />
        <CarouselContainer id={'contribute-carousel'}>
          <BulletTextSection
            title={t('start-with-picture.title')}
            description={t('start-with-picture.description')}
            sectionClassName={'contribute-start-with-picture'}
            bullet={{ ordinal: 1 }}
            reversed={true}
            variant={'contribute'}
            width={'big'}
            margin={'medium'}
          />
          <BulletTextSection
            title={t('set-price.title')}
            description={t('set-price.description')}
            sectionClassName={'contribute-set-price'}
            bullet={{ ordinal: 2 }}
            reversed={true}
            variant={'contribute'}
            width={'big'}
            margin={'medium'}
          />
          <BulletTextSection
            title={t('delivery-method.title')}
            description={t('delivery-method.description')}
            sectionClassName={'contribute-delivery-method'}
            bullet={{ ordinal: 3 }}
            reversed={true}
            variant={'contribute'}
            width={'big'}
            margin={'medium'}
          />
          <BulletTextSection
            title={t('choose-campaign.title')}
            description={t('choose-campaign.description')}
            sectionClassName={'contribute-choose-campaign'}
            bullet={{ ordinal: 4 }}
            reversed={true}
            variant={'contribute'}
            width={'big'}
            margin={'medium'}
          />
        </CarouselContainer>
      </ContributeDeviceContainer>
    </PinnedScreenSection>
  );
};

const setupAnimations = () => {
  const trigger = '#contribute-start-with-picture';

  const pinDevice = animatePinDevice(trigger, '#contribute-delivery-method', '#contribute-pin-second');
  const simpleDevice = animateSimpleDevice(trigger, '#device-camera');
  const carouselIn = animateCarouselIn(trigger, '#contribute-start-with-picture');
  const carouselMain = animateCarouselMain('#contribute-carousel', trigger, '#contribute-delivery-method', 4);

  return () => {
    pinDevice.kill();
    simpleDevice.kill();
    carouselIn.kill();
    carouselMain.kill();
  };
};

export default StartWithPictureSection;
