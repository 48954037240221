import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { fonts } from '../../../assets/styles/fonts';
import colors from '../../../assets/styles/colors';
import { FAQProvider } from '../../../commons/components/faq/FAQProvider';
import FAQImage from '../../../assets/images/faq/faq.png';
import { refFontSize, refFontSizeLandscape, refHeight, refWidth, refWidthLandscape } from '../../utils/dimensions';
import { mq } from '../../../assets/styles/mediaQuery';
import ShortButton from '../buttons/ShortButton';
import IssueCollector from '../../../commons/components/faq/IssueCollector';
import FAQContent from './FAQContent';

const ImageWrapper = styled.img`
  padding-top: ${refHeight(50)}vh;
  width: 100%;
  transform: translateX(-${refWidth(20)}vw) scale(1.2);
  position: relative;
  margin-bottom: ${refHeight(30)}vh;

  ${mq.landscape} {
    transform: translateX(-${refWidthLandscape(20)}vw) scale(1);
  }
`;

const FormSubtitle = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(14)}vw;
  margin-bottom: ${refHeight(20)}vh;
  line-height: 1.7em;
  color: ${colors.fadedJade};

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(14)}vw;
  }
`;

const FormLauncherWrapper = styled.div`
  margin-top: ${refHeight(40)}vh;
  margin-bottom: ${refHeight(40)}vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FAQSection = () => {
  const { t } = useTranslation('faq');
  const [modal, setModal] = useState(false);

  const closeModal = () => {
    setModal(false);
  };

  const openModal = () => {
    setModal(true);
  };

  const onScrollRequest = (elementId: string, isExpanded: boolean) =>
    setTimeout(() => isExpanded && document.getElementById(elementId)!.scrollIntoView({ behavior: 'smooth' }), 0);

  return (
    <>
      <ImageWrapper src={FAQImage} alt={'faq'} />
      <FAQProvider onScrollRequest={onScrollRequest}>
        <FAQContent />
      </FAQProvider>
      <FormLauncherWrapper>
        <FormSubtitle>{t('form-launcher')}</FormSubtitle>
        <ShortButton onClick={openModal} value={t('common:no-button')} />
      </FormLauncherWrapper>
      {modal && <IssueCollector isMobile={true} closeModal={closeModal} />}
    </>
  );
};

export default FAQSection;
