import image from 'assets/images/device/item/image.png';
import DeviceImage from './DeviceImage';

const Item = () => {
  return (
    <div id={'device-item'}>
      <DeviceImage src={image} left={27} top={24} width={375} height={824} />
    </div>
  );
};

export default Item;
