import styled from 'styled-components';
import { refFontSizeLandscape, refWidth } from 'mobile/utils/dimensions';
import { mq } from 'assets/styles/mediaQuery';
import { useEffect, useRef } from 'react';
import AnimationShadow from './AnimationShadow';

const Container = styled.div`
  position: relative;
`;

const DeviceFrame = styled.canvas`
  width: ${refWidth(340)}vw;
  height: ${refWidth(600)}vw;

  ${mq.landscape} {
    width: ${refFontSizeLandscape(340)}vw;
    height: ${refFontSizeLandscape(600)}vw;
  }
`;

interface Props {
  directory: string;
  firstFrame: number;
  lastFrame: number;
  backgroundColor: string;
}

const FrameAnimation = ({ directory, firstFrame, lastFrame, backgroundColor }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const frameCount = lastFrame - firstFrame + 1;

  const preloadImages = () => {
    const images: HTMLImageElement[] = [];

    for (let index = 0; index < frameCount; index++) {
      const image = new Image();
      image.src = currentFrame(index);
      images.push(image);
    }

    return images;
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const currentFrame = (index: number) => require(`assets/frames/${directory}/${index + firstFrame}.jpg`).default;

  const scrollListener = (images: HTMLImageElement[]) => () => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    if (canvas && container) {
      // canvas absolute top/bottom position and height
      const containerTop = container.offsetTop;
      const containerBottom = container.offsetTop + container.clientHeight;
      const containerHeight = container.clientHeight;

      // absolute position of animation start/end points
      const animationTop = containerTop + containerHeight * 0.3; // start on 30% of canvas
      const animationBottom = containerBottom - containerHeight * 0.1; // end on 90% of canvas
      const animationHeight = animationBottom - animationTop;

      // scroll absolute position (bottom edge of browser)
      const body = document.documentElement;
      const scrollPosition = body.scrollTop + body.clientHeight;

      // skip animating when far away from canvas, for performance reasons
      const animationThreshold = 200;
      if (scrollPosition < animationTop - animationThreshold || scrollPosition > animationBottom + animationThreshold) {
        return;
      }

      // interpolate animation based on scroll position
      const animationPosition = Math.min(Math.max(animationTop, scrollPosition), animationBottom);

      // calculate frame to display
      const scrollFraction = (animationPosition - animationTop) / animationHeight;
      const frameIndex = Math.min(frameCount - 1, Math.ceil(scrollFraction * frameCount));

      requestAnimationFrame(() => updateImage(images[frameIndex]));
    }
  };

  const updateImage = (image: HTMLImageElement) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d')!;
      context.drawImage(image, 0, 0);
    }
  };

  const initFirstFrame = (images: HTMLImageElement[]) => {
    const canvas = canvasRef.current;
    if (canvas) {
      // set correct quality of image based on size of jpg files - fixes bad quality of animation
      canvas.width = 600;
      canvas.height = 1060;

      // initial frame
      const firstFrame = images[0];
      firstFrame.onload = () => canvas.getContext('2d')!.drawImage(firstFrame, 0, 0);
    }
  };

  useEffect(() => {
    const images = preloadImages();
    initFirstFrame(images);

    const listener = scrollListener(images);
    window.addEventListener('scroll', listener);

    return () => window.removeEventListener('scroll', listener, false);
  }, []);

  return (
    <Container ref={containerRef}>
      <DeviceFrame ref={canvasRef} />
      <AnimationShadow color={backgroundColor} />
    </Container>
  );
};

export default FrameAnimation;
