import { useTranslation } from 'react-i18next';
import buyVideo from 'assets/videos/mobile_buy.mp4';
import colors from 'assets/styles/colors';
import VideoMainSection from '../VideoMainSection';

const PurchaseSection = () => {
  const { t } = useTranslation('buy');

  return (
    <VideoMainSection
      header={t('purchase.header')}
      title={t('purchase.title')}
      subtitle={t('purchase.subtitle')}
      description={t('purchase.description')}
      video={buyVideo}
      backgroundColor={colors.red}
    />
  );
};

export default PurchaseSection;
