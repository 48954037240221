import { dropShadow } from 'desktop/utils/dropShadow';
import gsap from 'gsap';
import { createShowHideTrigger } from './createShowHideTrigger';

export const animateMarketplace = (sectionId: string) => {
  const imagesShowFrom = { opacity: 0, scale: 1.5, duration: 1, stagger: 0.1, filter: dropShadow(10, 30, 5, 0.5) };

  const marketplaceMain = gsap.timeline({ paused: true });
  marketplaceMain.set('#loading-overlay', { display: 'none' });
  marketplaceMain.from('#device-marketplace img', imagesShowFrom);

  const marketplaceOut = gsap.timeline({ paused: true }).fromTo('#device-marketplace', { opacity: 1 }, { opacity: 0 });

  const triggerMarketplace = createShowHideTrigger(sectionId, marketplaceMain, marketplaceOut);

  return {
    kill: () => {
      triggerMarketplace.kill();
      marketplaceMain.kill();
      marketplaceOut.kill();
    },
  };
};
