import styled from 'styled-components';
import { refFontSize, refFontSizeLandscape, refWidth, refWidthLandscape } from 'mobile/utils/dimensions';
import { mq } from 'assets/styles/mediaQuery';
import React from 'react';
import { useTranslation } from 'react-i18next';
import homeVideo from 'assets/videos/mobile_home.mp4';
import colors from '../../../assets/styles/colors';
import { fonts } from '../../../assets/styles/fonts';
import AnimationShadow from '../../components/commons/AnimationShadow';
import InlineReactPlayer from '../../components/commons/InlineReactPlayer';

const Container = styled.div`
  padding-top: ${refWidth(35)}vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.landscape} {
    padding-top: ${refWidthLandscape(35)}vw;
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  width: ${refWidth(340)}vw;

  ${mq.landscape} {
    width: ${refFontSizeLandscape(340)}vw;
  }
`;

const Title = styled.div`
  width: ${refWidth(240)}vw;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.medium};
  font-size: ${refFontSize(18)}vw;
  margin-bottom: ${refWidth(25)}vw;
  line-height: 1.5em;
  color: ${colors.fadedJade};
  text-align: center;

  ${mq.landscape} {
    width: ${refFontSizeLandscape(240)}vw;
    font-size: ${refFontSizeLandscape(18)}vw;
    margin-bottom: ${refWidthLandscape(25)}vw;
  }
`;

const Description = styled.div`
  width: ${refWidth(332)}vw;
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.bold};
  font-size: ${refFontSize(38)}vw;
  margin-bottom: ${refWidth(25)}vw;
  line-height: 1em;
  padding-bottom: 0.2em;
  color: ${colors.cello};
  text-align: center;

  ${mq.landscape} {
    width: ${refFontSizeLandscape(332)}vw;
    font-size: ${refFontSizeLandscape(38)}vw;
    margin-bottom: ${refWidthLandscape(25)}vw;
  }
`;

const Subtitle = styled.div`
  width: ${refWidth(240)}vw;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.medium};
  font-size: ${refFontSize(18)}vw;
  margin-bottom: ${refWidth(25)}vw;
  line-height: 1.5em;
  color: ${colors.fadedJade};
  text-align: center;

  ${mq.landscape} {
    width: ${refFontSizeLandscape(240)}vw;
    font-size: ${refFontSizeLandscape(18)}vw;
    margin-bottom: ${refWidthLandscape(25)}vw;
  }
`;

const MainSection = () => {
  const { t } = useTranslation('home');

  return (
    <Container>
      <Title>{t('main.title')}</Title>
      <Description>{t('main.description')}</Description>
      <Subtitle>{t('main.subtitle')}</Subtitle>
      <VideoWrapper>
        <InlineReactPlayer
          url={homeVideo}
          width='100%'
          height='100%'
          controls={false}
          playing={true}
          muted={true}
          loop={true}
        />
        <AnimationShadow color={colors.white} multiplier={0.5} />
      </VideoWrapper>
    </Container>
  );
};

export default MainSection;
