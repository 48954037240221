import ExpandableHeader from 'desktop/components/commons/ExpandableHeader';
import SmoothScroll from 'desktop/components/containers/SmoothScroll';
import { useTranslation } from 'react-i18next';
import { ScrollbarProvider } from 'desktop/components/containers/ScrollbarProvider';
import LoadingOverlay from 'desktop/components/containers/LoadingOverlay';
import useOrphanPrevention from 'commons/utils/useOrphanPrevention';
import DownloadSection from '../sections/DownloadSection';
import Footer from '../sections/Footer';
import YourDonationsSection from '../sections/contribute/YourDonationsSection';
import UnwantedStuffSection from '../sections/contribute/UnwantedStuffSection';
import SetPriceSection from '../sections/contribute/SetPriceSection';
import ChooseCampaignSection from '../sections/contribute/ChooseCampaignSection';
import StartWithPictureSection from '../sections/contribute/StartWithPictureSection';
import DeliveryMethodSection from '../sections/contribute/DeliveryMethodSection';
import TaxDeductibleSection from '../sections/contribute/TaxDeductibleSection';
import GiveLittleSection from '../sections/contribute/GiveLittleSection';

const SNAP_POINTS = [
  '#contribute-your-donations',
  '#contribute-unwanted-stuff',
  '#contribute-start-with-picture',
  '#contribute-set-price',
  '#contribute-delivery-method',
  '#contribute-choose-campaign',
  '#contribute-tax-deductible',
  '#contribute-give-little',
  '#download-section',
];

const Contribute = () => {
  const { t } = useTranslation('contribute');
  useOrphanPrevention();

  return (
    <ScrollbarProvider>
      <ExpandableHeader />
      <LoadingOverlay />
      <SmoothScroll snapPointsConfig={{ elementIds: SNAP_POINTS, snapToEnd: true }}>
        <YourDonationsSection />
        <UnwantedStuffSection />
        <StartWithPictureSection />
        <SetPriceSection />
        <DeliveryMethodSection />
        <ChooseCampaignSection />
        <TaxDeductibleSection />
        <GiveLittleSection />
        <DownloadSection
          subtitle={t('download.subtitle')}
          title={t('download.title')}
          downloadTitle={t('download.download-title')}
        />
        <Footer />
      </SmoothScroll>
    </ScrollbarProvider>
  );
};

export default Contribute;
