import styled from 'styled-components';
import { PropsWithChildren } from 'react';
import { WithId } from 'commons/types/commonProps';
import { refWidth } from 'desktop/utils/dimensions';

const OverflowWrapper = styled.div`
  overflow: hidden;
  display: flex;
`;

const FlexWrapper = styled.div`
  margin-left: ${refWidth(120)}vw;
  display: flex;
  div {
    flex-shrink: 0;
  }
`;

const CarouselContainer = ({ children, id }: PropsWithChildren<WithId>) => {
  return (
    <OverflowWrapper>
      <FlexWrapper id={id}>{children}</FlexWrapper>
    </OverflowWrapper>
  );
};

export default CarouselContainer;
