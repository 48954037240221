import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { refFontSize, refFontSizeLandscape, refWidth } from 'mobile/utils/dimensions';
import colors from 'assets/styles/colors';
import { mq } from '../../../assets/styles/mediaQuery';
import { refWidthLandscape } from '../../utils/dimensions';
import { TECHNOLOGIES } from '../../../commons/utils/technologiesConst';
import { fonts } from '../../../assets/styles/fonts';

const Container = styled.div`
  padding-bottom: ${refWidth(50)}vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.landscape} {
    padding-bottom: ${refWidthLandscape(50)}vw;
  }
`;

const HeaderTextWrapper = styled.div`
  font-family: ${fonts.family.main}
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(24)}vw;
  line-height: 1.2em;
  color: ${colors.cello};

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(24)}vw;
  }
`;

const TechnologiesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: ${refWidth(315)}vw;
  margin-top: ${refWidth(24)}vw;

  ${mq.landscape} {
    width: ${refWidthLandscape(315)}vw;
    margin-top: ${refWidthLandscape(24)}vw;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${refWidth(150)}vw;

  ${mq.landscape} {
    width: ${refWidthLandscape(150)}vw;
  }
`;

const Technology = styled.div`
  display: flex;
  flex-direction: row;
  color: ${colors.fadedJade};
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.medium};
  font-size: ${refFontSize(14)}vw;
  line-height: 2em;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(14)}vw;
  }
`;

const HyphenWrapper = styled.div`
  white-space: pre;
`;

const TechnologiesSection = () => {
  const { t } = useTranslation('about');

  const renderTechnologiesColumn = (technologies: string[]) => {
    return (
      <Column>
        {technologies.map(value => (
          <Technology key={value}>
            <HyphenWrapper>― </HyphenWrapper>
            <div>{value}</div>
          </Technology>
        ))}
      </Column>
    );
  };

  return (
    <Container>
      <HeaderTextWrapper>{t('technologies.header')}</HeaderTextWrapper>
      <TechnologiesWrapper>
        {renderTechnologiesColumn(TECHNOLOGIES.slice(0, TECHNOLOGIES.length / 2 + 1))}
        {renderTechnologiesColumn(TECHNOLOGIES.slice(TECHNOLOGIES.length / 2 + 1))}
      </TechnologiesWrapper>
    </Container>
  );
};

export default TechnologiesSection;
