import fundraiseSupport from 'assets/images/fundraise/fundraiseSupport.png';
import { useTranslation } from 'react-i18next';
import BulletDeviceSection from '../BulletDeviceSection';

const SupportSection = () => {
  const { t } = useTranslation('fundraise');

  return (
    <BulletDeviceSection
      title={t('support.title')}
      description={t('support.description')}
      bullet={{ ordinal: 3 }}
      image={fundraiseSupport}
      variant={'fundraise'}
      hasTopPadding={true}
    />
  );
};

export default SupportSection;
