import React from 'react';
import styled from 'styled-components';
import colors from 'assets/styles/colors';
import { fonts } from 'assets/styles/fonts';
import { mq } from 'assets/styles/mediaQuery';
import { refFontSize, refFontSizeLandscape } from '../../utils/dimensions';
import { Answer as AnswerObject } from '../../../commons/components/faq/faqObjects';
import TranslationWithIcon from './TranslationWithIcon';

const Container = styled.div`
  overflow: hidden;
`;

const StyledH3 = styled.h3`
  font-weight: ${fonts.weight.bold};
  color: ${colors.fadedJade};
`;

const StyledP = styled.p`
  color: ${colors.fadedJade};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(14)}vw;
  line-height: 1.5em;
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(12)}vw;
  }
`;

const StyledUl = styled.ul`
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(14)}vw;
  line-height: 1.5em;
  color: ${colors.fadedJade};
  margin-block-start: 0.2em;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(12)}vw;
  }
`;

interface Props {
  answer: AnswerObject;
}

const Answer = (props: Props) => {
  const { answers, subtitle, list } = props.answer;

  const renderSubtitle = () => subtitle && <StyledH3>{subtitle}</StyledH3>;

  const renderAnswers = () =>
    answers.map((answer, index) => (
      <StyledP key={index}>
        <TranslationWithIcon text={answer} />
      </StyledP>
    ));

  const renderList = () =>
    list && (
      <StyledUl>
        {list.map((element, index) => (
          <li key={index}>{<TranslationWithIcon text={element} />}</li>
        ))}
      </StyledUl>
    );

  return (
    <Container>
      {renderSubtitle()}
      {renderAnswers()}
      {renderList()}
    </Container>
  );
};

export default Answer;
