import { createContext, EffectCallback, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import Scrollbar from 'smooth-scrollbar';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

interface SmoothScrollContextType {
  isScrollbarReady: boolean;
  useScrollReady: (effect: EffectCallback) => void;
  setScrollbar: (scrollbar: Scrollbar | null) => void;
  scrollIntoView: (selector: string) => void;
  refreshTriggers: () => void;
  scrollToTop: () => void;
}

const SmoothScrollContext = createContext<SmoothScrollContextType>(null!);

interface Props {
  children: ReactNode;
}

const ScrollbarProvider = ({ children }: Props) => {
  const [scrollbarRef, setScrollbarRef] = useState<Scrollbar | null>(null);
  const [isScrollbarReady, setScrollbarReady] = useState(false);

  const getScrollbar = useCallback(() => scrollbarRef, [scrollbarRef]);

  const setScrollbar = (ref: Scrollbar | null) => {
    setScrollbarRef(ref);
    setScrollbarReady(!!ref);
  };

  const scrollIntoView = (selector: string) => {
    setTimeout(() => {
      getScrollbar()?.update();
      ScrollTrigger.refresh();
      getScrollbar()?.scrollIntoView(document.querySelector(selector)!);
    }, 0);
  };

  const refreshTriggers = () => {
    setTimeout(() => {
      getScrollbar()?.update();
      ScrollTrigger.refresh();
    }, 0);
  };

  const useScrollReady = (effect: EffectCallback) =>
    useEffect(() => {
      if (isScrollbarReady) {
        return effect();
      }
    }, [isScrollbarReady]);

  const scrollToTop = () => getScrollbar()?.scrollTo(0, 0, 300);

  return (
    <SmoothScrollContext.Provider
      value={{ isScrollbarReady, useScrollReady, setScrollbar, scrollIntoView, refreshTriggers, scrollToTop }}>
      {children}
    </SmoothScrollContext.Provider>
  );
};

const useScrollbar = () => useContext(SmoothScrollContext);

export { ScrollbarProvider, useScrollbar };
