import { initDimensionUtils } from 'commons/utils/dimensions';
import { isTablet } from 'react-device-detect';

const REFERENCE_SCREEN_WIDTH = 375.0;
const REFERENCE_SCREEN_HEIGHT = 930.0;
const LANDSCAPE_WIDTH_MULTIPLIER = 0.75;
const LANDSCAPE_HEIGHT_MULTIPLIER = 1.5;
const TABLET_SCALE = isTablet ? 0.7 : 1;

const screenUtils = initDimensionUtils(REFERENCE_SCREEN_WIDTH, REFERENCE_SCREEN_HEIGHT, TABLET_SCALE);

export const refWidthLandscape = (width: number) => screenUtils.refWidthCalc(width, LANDSCAPE_WIDTH_MULTIPLIER);

export const refHeightLandscape = (height: number) => screenUtils.refHeightCalc(height, LANDSCAPE_HEIGHT_MULTIPLIER);

export const refFontSizeLandscape = (fontSize: number) =>
  screenUtils.refFontSizeCalc(fontSize, LANDSCAPE_WIDTH_MULTIPLIER);

export const { refWidth, refWidthCalc, refHeight, refFontSizeCalc, refFontSize } = screenUtils;

export const NAVBAR_HEIGHT = refWidth(70);
