import shape1 from 'assets/images/home/shapes/device/shape1.svg';
import shape2 from 'assets/images/home/shapes/device/shape2.svg';
import shape3 from 'assets/images/home/shapes/device/shape3.svg';
import shape4 from 'assets/images/home/shapes/device/shape4.svg';
import shape5 from 'assets/images/home/shapes/device/shape5.svg';
import Shape from './Shape';

const Shapes = () => {
  return (
    <div id={'home-main-shapes'}>
      <Shape src={shape1} left={-83} top={80} width={65} height={65} />
      <Shape src={shape2} left={-81} top={165} width={206} height={207} />
      <Shape src={shape3} left={-134} top={287} width={437} height={534} />
      <Shape src={shape4} left={135} top={95} width={461} height={289} />
      <Shape src={shape5} left={312} top={529} width={211} height={279} />
    </div>
  );
};

export default Shapes;
