import styled from 'styled-components';
import logo from 'assets/images/logo-full-border.svg';
import { refWidth } from 'desktop/utils/dimensions';

const Logo = styled.img`
  width: ${refWidth(130)}vw;
  height: ${refWidth(50)}vw;
  cursor: pointer;
`;

interface Props {
  className?: string;
  onClick?: () => void;
}

const LogoButton = ({ className, onClick }: Props) => {
  return <Logo onClick={onClick} src={logo} className={className} />;
};

export default LogoButton;
