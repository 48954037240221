import { createContext, ReactNode, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMobileVersion } from '../../commons/utils/useMobileVersion';

interface NavigationProviderContextType {
  navigateOrScrollToTop: (path: string, scrollToTop: () => void) => void;
}

interface Props {
  children: ReactNode;
}

const NavigationProviderContext = createContext<NavigationProviderContextType>(null!);

const NavigationProvider = ({ children }: Props) => {
  const useMobile = useMobileVersion();
  const { pathname } = useLocation();
  const history = useHistory();

  const navigateOrScrollToTop = (path: string, scrollToTop: () => void) => {
    if (pathname !== path) {
      history.push(path);
      useMobile && scrollToTop();
    } else scrollToTop();
  };

  return (
    <NavigationProviderContext.Provider value={{ navigateOrScrollToTop }}>
      {children}
    </NavigationProviderContext.Provider>
  );
};
const useGlobalNavigation = () => useContext(NavigationProviderContext);

export { NavigationProvider, useGlobalNavigation };
