import { useTranslation } from 'react-i18next';
import buyPurchasesTranslate from 'assets/images/buy/buyPurchasesTranslate.png';
import shape1 from 'assets/images/buy/shapes/purchasesTranslate/shape1.svg';
import shape2 from 'assets/images/buy/shapes/purchasesTranslate/shape2.svg';
import shape3 from 'assets/images/buy/shapes/purchasesTranslate/shape3.svg';
import shape4 from 'assets/images/buy/shapes/purchasesTranslate/shape4.svg';
import BulletPhotoSection from '../BulletPhotoSection';

const EveryDollarSection = () => {
  const { t } = useTranslation('buy');

  return (
    <BulletPhotoSection
      title={t('every-dollar.title')}
      description={t('every-dollar.description')}
      image={{ source: buyPurchasesTranslate, dimensions: { width: 315, height: 240 } }}
      shapes={[
        { source: shape1, dimensions: { left: 221, top: 75, width: 187, height: 217 } },
        { source: shape2, dimensions: { left: 178, top: 217, width: 79, height: 79 } },
        { source: shape3, dimensions: { left: -66, top: -47, width: 126, height: 121 } },
        { source: shape4, dimensions: { left: 41, top: -42, width: 29, height: 28 } },
      ]}
    />
  );
};

export default EveryDollarSection;
