import { AxiosPromise } from 'axios';
import { apiService, apiUrl } from '../utils/apiService';
import { PhoneDto } from './notificationService.dto';

class NotificationService {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  BASE_URL = `${apiUrl}/api/notification`;

  sendAppLink(phone: PhoneDto, responseToken: string, appArea?: string, appAreaObjectId?: number): AxiosPromise {
    const url = `${this.BASE_URL}/public/app-link`;
    return apiService.post(url, {
      phone,
      appArea: appArea ? appArea : null,
      appAreaObjectId: appAreaObjectId ? appAreaObjectId : null,
      captchaResponseToken: responseToken,
    });
  }
}

const notificationService = new NotificationService();

export { notificationService, NotificationService };
