import styled from 'styled-components';
import { fonts } from 'assets/styles/fonts';
import React from 'react';
import { refFontSize, refFontSizeLandscape, refWidth, refWidthLandscape } from '../utils/dimensions';
import colors from '../../assets/styles/colors';
import { mq } from '../../assets/styles/mediaQuery';
import AnimationShadow from '../components/commons/AnimationShadow';
import InlineReactPlayer from '../components/commons/InlineReactPlayer';

const Container = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  padding-top: ${refWidth(40)}vw;
  padding-bottom: ${refWidth(50)}vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.landscape} {
    padding-top: ${refWidthLandscape(40)}vw;
    padding-bottom: ${refWidthLandscape(50)}vw;
  }
`;

const TextWrapper = styled.div`
  width: ${refWidth(315)}vw;

  ${mq.landscape} {
    width: ${refFontSizeLandscape(315)}vw;
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  width: ${refWidth(340)}vw;

  ${mq.landscape} {
    width: ${refFontSizeLandscape(340)}vw;
  }
`;

const Header = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(12)}vw;
  line-height: 1.5em;
  color: ${colors.shadedBlack};
  text-align: center;
  margin-bottom: ${refWidth(15)}vw;
  text-transform: uppercase;
  letter-spacing: 0.2em;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(12)}vw;
    margin-bottom: ${refWidthLandscape(15)}vw;
  }
`;

const Title = styled.div`
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(40)}vw;
  line-height: 1em;
  color: ${colors.white};
  text-align: center;
  padding-bottom: 0.2em; //line height 1em cuts letters like "g"

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(40)}vw;
  }
`;

const Subtitle = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(20)}vw;
  line-height: 1.2em;
  color: ${colors.shadedWhite};
  text-align: center;
  margin-bottom: ${refWidth(25)}vw;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(20)}vw;
    margin-bottom: ${refWidthLandscape(25)}vw;
  }
`;

const Description = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(14)}vw;
  line-height: 1.71em;
  color: ${colors.shadedWhite};
  text-align: center;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(14)}vw;
  }
`;

interface Props {
  header: string;
  title: string;
  subtitle: string;
  description: string;
  video: string;
  backgroundColor: string;
}

const VideoMainSection = ({ header, title, subtitle, description, video, backgroundColor }: Props) => {
  return (
    <Container color={backgroundColor}>
      <TextWrapper>
        <Header>{header}</Header>
        <Title>{title}</Title>
      </TextWrapper>
      <VideoWrapper>
        <InlineReactPlayer url={video} width='100%' height='100%' controls={false} playing={true} muted={true} />
        <AnimationShadow color={backgroundColor} />
      </VideoWrapper>
      <TextWrapper>
        <Subtitle>{subtitle}</Subtitle>
        <Description>{description}</Description>
      </TextWrapper>
    </Container>
  );
};

export default VideoMainSection;
