import fundraiseShare from 'assets/images/fundraise/fundraiseShare.png';
import shape1 from 'assets/images/fundraise/shapes/share/shape1.svg';
import shape2 from 'assets/images/fundraise/shapes/share/shape2.svg';
import shape3 from 'assets/images/fundraise/shapes/share/shape3.svg';
import { useTranslation } from 'react-i18next';
import BulletPhotoSection from '../BulletPhotoSection';

const ShareSection = () => {
  const { t } = useTranslation('fundraise');

  return (
    <BulletPhotoSection
      title={t('share.title')}
      description={t('share.description')}
      bullet={{ ordinal: 2 }}
      image={{ source: fundraiseShare, dimensions: { width: 315, height: 240 } }}
      shapes={[
        { source: shape1, dimensions: { left: -51, top: -53, width: 120, height: 116 } },
        { source: shape2, dimensions: { left: 317, top: -35, width: 39, height: 33 } },
        { source: shape3, dimensions: { left: 132, top: 122, width: 240, height: 175 } },
      ]}
    />
  );
};
export default ShareSection;
