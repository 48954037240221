import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import colors from 'assets/styles/colors';
import { animations } from 'assets/styles/animations';
import { fonts } from 'assets/styles/fonts';
import { mq } from '../../../assets/styles/mediaQuery';
import { refFontSize, refFontSizeLandscape, refHeight, refHeightLandscape, refWidth } from '../../utils/dimensions';
import SearchInput from '../inputs/SearchInput';
import { useFAQContext } from '../../../commons/components/faq/FAQProvider';
import { StyledHr } from '../../../commons/components/faq/StyledHr';
import Section from './Section';

const Container = styled.div`
  font-family: ${fonts.family.description};
  display: flex;
  flex-direction: column;
  padding: ${refHeight(50)}vh ${refWidth(30)}vw;

  ${mq.landscape} {
    padding: ${refHeightLandscape(50)}vh ${refWidth(60)}vw;
  }
`;

const SectionsWrapper = styled.div`
  margin-top: ${refHeight(80)}vh;
`;

const Title = styled.div`
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.bold};
  font-size: ${refFontSize(40)}vw;
  margin-bottom: ${refHeight(30)}vh;
  line-height: 1em;
  color: ${colors.fadedJade};
  text-align: center;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(40)}vw;
  }
`;

const Subtitle = styled.div`
  font-weight: ${fonts.weight.medium};
  font-size: ${refFontSize(18)}vw;
  line-height: 1.7em;
  color: ${colors.cello};

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(18)}vw;
  }
`;

const HeaderSubtitle = styled(Subtitle)`
  text-align: center;
  margin-bottom: ${refHeight(20)}vh;
`;

const NotFoundWrapper = styled.div<{ shouldDisplay: boolean }>`
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(14)}vw;
  line-height: 2em;
  color: ${colors.red};
  transition-duration: ${animations.standardDuration};
  text-align: center;
  opacity: ${({ shouldDisplay }) => (shouldDisplay ? 1 : 0)};

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(14)}vw;
  }
`;

const FAQContent = () => {
  const { t } = useTranslation('faq');
  const [searchText, setSearchText] = useState<string>('');
  const [shouldDisplayNotFoundMessage, setShouldDisplayNotFoundMessage] = useState(false);
  const { sections, search } = useFAQContext();

  const applySearch = () => {
    const found = search(searchText);
    setShouldDisplayNotFoundMessage(!found);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLImageElement>) => {
    if (e.key === 'Enter') {
      applySearch();
    }
  };

  const changeSearchValue = (value: string) => {
    setSearchText(value);
    setShouldDisplayNotFoundMessage(false);
  };

  return (
    <Container onKeyPress={handleKeyPress} id={'FAQContainer'}>
      <Title>{t('title')}</Title>
      <HeaderSubtitle>{t('subtitle')}</HeaderSubtitle>
      <SearchInput value={searchText} onChange={changeSearchValue} onIconClick={applySearch} />
      <NotFoundWrapper shouldDisplay={shouldDisplayNotFoundMessage}>{t('not-found')}</NotFoundWrapper>
      <SectionsWrapper>
        {sections.map((section, index) => {
          return (
            <div key={index}>
              {index !== 0 && <StyledHr />}
              <Section section={section} />
            </div>
          );
        })}
        <StyledHr />
      </SectionsWrapper>
    </Container>
  );
};

export default FAQContent;
