import styled from 'styled-components';
import { refFontSize, refWidth } from 'desktop/utils/dimensions';
import colors from 'assets/styles/colors';
import { WithClassName, WithId } from 'commons/types/commonProps';
import { forwardRef, Ref } from 'react';
import { mq } from 'assets/styles/mediaQuery';
import { fonts } from '../../../assets/styles/fonts';
import { animations } from '../../../assets/styles/animations';

const Container = styled.div<{ color: string }>`
  position: absolute;
  border-radius: ${refWidth(30)}vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: ${props => props.color};
  cursor: pointer;
  transition-duration: ${animations.shortDuration};
  ${mq.desktopSmall} {
    border-radius: ${refWidth(35)}vw;
  }
  ${mq.phone} {
    border-radius: ${refWidth(40)}vw;
  }

  &:hover {
    box-shadow: 0 0 0 ${refWidth(1.8)}vw ${props => props.color};
    div {
      transition-duration: ${animations.shortDuration};
      font-size: ${refFontSize(14.6)}vw;
      ${mq.desktopSmall} {
        font-size: ${refFontSize(18.8)}vw;
      }
      ${mq.phone} {
        font-size: ${refFontSize(22)}vw;
      }
    }
  }
`;

const Text = styled.div`
  color: ${colors.white};
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.bold};
  font-size: ${refFontSize(14)}vw;
  text-transform: uppercase;
  ${mq.desktopSmall} {
    font-size: ${refFontSize(18)}vw;
  }
  ${mq.phone} {
    font-size: ${refFontSize(21)}vw;
  }
`;

interface Props extends WithId, WithClassName {
  onClick: () => void;
  text: string;
  color: string;
  children?: any;
}

const HomeButton = forwardRef(({ id, className, onClick, text, color, children }: Props, ref: Ref<HTMLDivElement>) => {
  return (
    <Container ref={ref} id={id} className={className} onClick={onClick} color={color}>
      {children}
      <Text>{text}</Text>
    </Container>
  );
});

export default HomeButton;
