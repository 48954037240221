import PageWrapper from 'mobile/components/containers/PageWrapper';
import { useTranslation } from 'react-i18next';
import ExpandableHeader from '../components/commons/ExpandableHeader';
import Footer from '../sections/Footer';
import DownloadSection from '../sections/DownloadSection';
import HelpEnvironmentSection from '../sections/saveThePlanet/HelpEnvironmentSection';
import GivingSecondLifeSection from '../sections/saveThePlanet/GivingSecondLifeSection';

const SaveThePlanet = () => {
  const { t } = useTranslation('saveThePlanet');

  return (
    <ExpandableHeader>
      <PageWrapper>
        <HelpEnvironmentSection />
        <GivingSecondLifeSection />
        <DownloadSection
          subtitle={t('download.subtitle')}
          title={t('download.title')}
          downloadTitle={t('download.download-title')}
        />
        <Footer />
      </PageWrapper>
    </ExpandableHeader>
  );
};

export default SaveThePlanet;
