import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import scrollToTop from '../../utils/scrollToTop';
import Navbar from './Navbar';
import DownloadMenu from './DownloadMenu';
import Header from './Header';

const ChildrenWrapper = styled.div<{ visible: boolean }>`
  transition: ${({ visible }) => (visible ? '1s' : '0.3s ease-in')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

interface Props {
  children: ReactNode;
}

const ExpandableHeader = ({ children }: Props) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
    scrollToTop();
  };

  return (
    <>
      <Navbar visible={!menuVisible} />
      <Header onToggleMenu={toggleMenu} isOpenedMenuVariant={menuVisible} />
      <DownloadMenu visible={menuVisible} onSectionClick={toggleMenu} />
      <ChildrenWrapper visible={!menuVisible}>{children}</ChildrenWrapper>
    </>
  );
};
export default ExpandableHeader;
