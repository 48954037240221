import { isTablet } from 'react-device-detect';

export const TABLET_MULTIPLIER = isTablet ? 1.3 : 1;

export const initDimensionUtils = (referenceScreenWidth: number, referenceScreenHeight: number, scale = 1) => ({
  refWidth: (width: number, refWidth?: number) => scale * (width / (refWidth || referenceScreenWidth)) * 100.0,

  refWidthCalc: (width: number, multiplier: number, refWidth?: number) =>
    scale * (width / (refWidth || referenceScreenWidth)) * 100.0 * multiplier,

  refHeightCalc: (height: number, multiplier: number, refHeight?: number) =>
    scale * (height / (refHeight || referenceScreenHeight)) * 100.0 * multiplier,

  refHeight: (height: number, refHeight?: number) => scale * (height / (refHeight || referenceScreenHeight)) * 100.0,

  refFontSize: (fontSize: number) => scale * (fontSize / referenceScreenWidth) * 100,

  refFontSizeCalc: (fontSize: number, multiplier: number) =>
    scale * (fontSize / referenceScreenWidth) * 100 * multiplier,
});
