import styled from 'styled-components';
import { zIndex } from 'assets/styles/zIndex';
import ScreenSection from './ScreenSection';

// if pinned section contains clickable elements, then it should have higher z-index than next sections
const PinnedScreenSection = styled(ScreenSection)`
  z-index: ${zIndex.input};
`;

export default PinnedScreenSection;
