import ExpandableHeader from 'mobile/components/commons/ExpandableHeader';
import PageWrapper from 'mobile/components/containers/PageWrapper';
import MainSection from 'mobile/sections/home/MainSection';
import FundraiseSection from 'mobile/sections/home/FundraiseSection';
import Footer from 'mobile/sections/Footer';
import { useTranslation } from 'react-i18next';
import DonateSection from 'mobile/sections/home/DonateSection';
import ShopSection from 'mobile/sections/home/ShopSection';
import DownloadSection from '../sections/DownloadSection';
import SaveSection from '../../mobile/sections/home/SaveSection';
import SeeSection from '../../mobile/sections/home/SeeSection';

const Home = () => {
  const { t } = useTranslation('home');
  return (
    <ExpandableHeader>
      <PageWrapper>
        <MainSection />
        <DonateSection />
        <ShopSection />
        <FundraiseSection />
        <SeeSection />
        <SaveSection />

        <DownloadSection
          subtitle={t('download.subtitle')}
          title={t('download.title')}
          downloadTitle={t('download.download-title')}
        />
        <Footer />
      </PageWrapper>
    </ExpandableHeader>
  );
};

export default Home;
