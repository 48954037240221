import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRef, useState } from 'react';
import { notificationService } from 'services/Notification/notificationService';
import { useTranslation } from 'react-i18next';
import { refFontSize } from 'desktop/utils/dimensions';
import { mq } from '../../../assets/styles/mediaQuery';
import { fonts } from '../../../assets/styles/fonts';
import { ERROR_CODE_NOT_SUPPORTED } from '../../../commons/utils/phoneInputConst';
import PhoneInputField from './PhoneInputField';

const Container = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
`;

const InfoMsg = styled.div<{ color?: string }>`
  margin: 10px 0;
  color: ${({ color }) => (color ? color : 'white')};
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.medium};
  font-size: ${refFontSize(14)}vw;
  line-height: ${refFontSize(32)}vw;
`;

const StyledRecaptcha = styled(ReCAPTCHA)`
  transform-origin: left top;

  ${mq.desktopSmall} {
    transform: scale(0.8);
  }
`;

interface Props {
  className?: string;
  id?: string;
  messageColor?: string;
  buttonColor: string;
}

const PhoneInputSection = ({ className, id, messageColor, buttonColor }: Props) => {
  const recaptcha = useRef<ReCAPTCHA>(null);
  const { t } = useTranslation('common');
  const [responseToken, setResponseToken] = useState('');
  const [infoMsg, setInfoMsg] = useState('');
  const [isSending, setIsSending] = useState(false);

  const sendLink = (phoneNumber: string) => {
    if (responseToken) {
      if (!isSending) {
        setIsSending(true);
        const phone = {
          countryCode: phoneNumber.substring(0, 1),
          areaCode: phoneNumber.substring(1, 4),
          number: phoneNumber.substring(4, phoneNumber.length),
        };
        notificationService
          .sendAppLink(phone, responseToken)
          .then(() => setInfoMsg(t('phone.msg-sent')))
          .catch(error => {
            if (
              error.response &&
              error.response.status === 400 &&
              error.response.data.code === ERROR_CODE_NOT_SUPPORTED
            ) {
              setInfoMsg(t('phone.not-supported'));
            } else {
              setInfoMsg(t('phone.captcha-error'));
            }
          })
          .finally(() => {
            recaptcha.current?.reset();
            onExpireCaptcha();
            setIsSending(false);
          });
      }
    } else {
      setInfoMsg(t('phone.captcha-alert'));
    }
  };

  const onVerifyCaptcha = (response: string) => {
    setResponseToken(response);
  };

  const onExpireCaptcha = () => {
    setResponseToken('');
  };

  return (
    <Container className={className} id={id}>
      <PhoneInputField onGetLinkPress={sendLink} buttonColor={buttonColor} />
      <InfoMsg color={messageColor}>{infoMsg}</InfoMsg>
      <StyledRecaptcha
        ref={recaptcha}
        sitekey={process.env.REACT_APP_CAPTCHA_KEY || ''}
        onChange={value => value && onVerifyCaptcha(value)}
        onExpired={onExpireCaptcha}
      />
    </Container>
  );
};

export default PhoneInputSection;
