import { useTranslation } from 'react-i18next';
import BulletPhotoSection from '../BulletPhotoSection';
import beEco from '../../../assets/images/beEco.png';
import shape31 from '../../../assets/images/buy/shapes/wasteLess/shape1.svg';
import shape32 from '../../../assets/images/buy/shapes/wasteLess/shape2.svg';

const WasteLessSection = () => {
  const { t } = useTranslation('buy');

  return (
    <BulletPhotoSection
      title={t('waste-less.title')}
      description={t('waste-less.description')}
      image={{ source: beEco, dimensions: { width: 285, height: 262 } }}
      shapes={[
        { source: shape31, dimensions: { left: 232, top: 56, width: 43, height: 68 } },
        { source: shape32, dimensions: { left: 105, top: 209, width: 33, height: 19 } },
      ]}
      hasTopPadding={true}
    />
  );
};

export default WasteLessSection;
