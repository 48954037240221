import styled from 'styled-components';
import MouseIcon from 'desktop/components/commons/MouseIcon';
import { refFontSize, refFontSizeCalc, refWidth } from 'desktop/utils/dimensions';
import colors from 'assets/styles/colors';
import { useTranslation } from 'react-i18next';
import { mq } from 'assets/styles/mediaQuery';
import { fonts } from '../../../assets/styles/fonts';
import { MouseVariant } from './types';

const Container = styled.div`
  height: ${refWidth(32)}vw;
  position: relative;
  display: flex;
  align-items: center;
`;

const Text = styled.div<{ variant: MouseVariant }>`
  color: ${({ variant }) => (variant === 'red' ? colors.towerGray : colors.shadedBlack)};
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.bold};
  font-size: ${refFontSize(13)}vw;
  text-transform: uppercase;
  margin-left: ${refWidth(26)}vw;
  ${mq.desktopSmall} {
    font-size: ${refFontSizeCalc(13, 1.2)}vw;
  }
  ${mq.tablet} {
    font-size: ${refFontSizeCalc(13, 1.4)}vw;
  }
  ${mq.phone} {
    font-size: ${refFontSizeCalc(13, 1.5)}vw;
  }
`;

interface Props {
  className?: string;
  variant?: MouseVariant;
}

const ScrollDownInfo = ({ className, variant = 'red' }: Props) => {
  const { t } = useTranslation('home');

  return (
    <Container className={className}>
      <MouseIcon id={'home-main-scroll-icon'} variant={variant} />
      <Text variant={variant} id={'home-main-scroll-description'}>
        {t('main.scroll-help')}
      </Text>
    </Container>
  );
};

export default ScrollDownInfo;
