import styled from 'styled-components';
import colors from 'assets/styles/colors';
import flagUSImg from 'assets/images/download/flag-us.png';
import flagCanadaImg from 'assets/images/download/flag-canada.png';
import { useState } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { refFontSize, refFontSizeLandscape, refWidth, refWidthLandscape } from '../../utils/dimensions';
import { fonts } from '../../../assets/styles/fonts';
import * as inputConst from '../../../commons/utils/phoneInputConst';
import { mq } from '../../../assets/styles/mediaQuery';
import { animations } from '../../../assets/styles/animations';

const Container = styled.div`
  width: 100%;
  height: ${refWidth(60)}vw;
  background-color: ${colors.white};
  position: relative;
  border-radius: ${refWidth(50)}vw;
  display: flex;
  align-items: center;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(12)}vw;
  line-height: 1.8em;

  ${mq.landscape} {
    height: ${refWidthLandscape(60)}vw;
    font-size: ${refFontSizeLandscape(12)}vw;
  }
`;

const GetLinkButton = styled.div<{ isDisabled?: boolean; color: string }>`
  position: absolute;
  display: flex;
  width: ${refWidth(109)}vw;
  right: ${refWidth(6)}vw;
  top: ${refWidth(5)}vw;
  bottom: ${refWidth(5)}vw;
  background-color: ${props => (props.isDisabled ? colors.towerGray : props.color)};
  border-radius: ${refWidth(30)}vw;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.bold};
  color: ${colors.white};
  font-size: ${refFontSize(14)}vw;
  line-height: 1.4em;
  align-items: center;
  justify-content: center;
  transition: ${animations.standardDuration};

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(14)}vw;
    width: ${refWidthLandscape(109)}vw;
  }
`;

const CountryFlag = styled.img<{ isActive?: boolean }>`
  border-radius: 50%;
  padding: ${refWidth(2)}vw;
  width: ${refWidth(24)}vw;
  height: ${refWidth(24)}vw;
  border-color: ${props => (props.isActive ? colors.red : colors.transparent)};
  opacity: ${props => (props.isActive ? 1 : 0.5)};
  border-style: solid;
  border-width: ${refWidth(1.5)}vw;
  transition: ${animations.standardDuration};

  ${mq.landscape} {
    height: ${refWidthLandscape(24)}vw;
    width: ${refWidthLandscape(24)}vw;
  }
`;

const FlagsWrapper = styled.div`
  margin-left: ${refWidth(10)}vw;
  margin-top: ${refWidth(14)}vw;
  margin-bottom: ${refWidth(14)}vw;
  margin-right: ${refWidth(2)}vw;
  display: flex;
  align-items: center;
`;

const PhoneInput = styled(NumberFormat)`
  width: 40%;
  border: 0;
  margin-left: ${refWidth(2)}vw;
  padding: ${refWidth(2)}vw;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(12)}vw;
  line-height: 1.8em;
  outline: none;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(12)}vw;
  }
`;

interface Props {
  className?: string;
  onGetLinkPress: (phoneNumber: string) => void;
  buttonColor: string;
}

const PhoneInputField = ({ className, onGetLinkPress, buttonColor }: Props) => {
  const [isUS, setIsUS] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');

  const { t } = useTranslation('common');
  const onGetLinkButtonPress = () => {
    phoneNumber.length === inputConst.MIN_PHONE_LENGTH && onGetLinkPress(inputConst.US_COUNTRY_CODE + phoneNumber);
  };

  return (
    <Container className={className}>
      <FlagsWrapper>
        <CountryFlag src={flagUSImg} isActive={isUS} onClick={() => setIsUS(true)} />
        <CountryFlag src={flagCanadaImg} isActive={!isUS} onClick={() => setIsUS(false)} />
      </FlagsWrapper>
      +{inputConst.US_COUNTRY_CODE}
      <PhoneInput
        value={phoneNumber}
        onValueChange={(values: NumberFormatValues) => setPhoneNumber(values.value)}
        format={inputConst.FORMAT}
        mask={inputConst.MASK}
        allowEmptyFormatting={inputConst.ALLOW_EMPTY_FORMATTING}
      />
      <GetLinkButton
        color={buttonColor}
        isDisabled={phoneNumber.length < inputConst.MIN_PHONE_LENGTH}
        onClick={onGetLinkButtonPress}>
        {t('phone.get-link')}
      </GetLinkButton>
    </Container>
  );
};

export default PhoneInputField;
