import DeviceMockup from 'desktop/components/device/DeviceMockup';
import { WithId } from 'commons/types/commonProps';
import { DeviceContainer } from '../device/DeviceContainer';
import Campaign from '../device/Campaign';

const ContributeDeviceFirst = ({ id }: WithId) => {
  return (
    <DeviceContainer id={id}>
      <DeviceMockup />
      <Campaign />
    </DeviceContainer>
  );
};

export default ContributeDeviceFirst;
