import React from 'react';
import styled from 'styled-components';
import { fonts } from '../../../assets/styles/fonts';
import { NAVBAR_HEIGHT, refFontSize, refFontSizeLandscape } from '../../utils/dimensions';
import { animations } from '../../../assets/styles/animations';
import colors from '../../../assets/styles/colors';
import { mq } from '../../../assets/styles/mediaQuery';
import Sign from './Sign';

const Wrapper = styled.div`
  scroll-margin-top: ${NAVBAR_HEIGHT * 1.6}vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`;

const Header = styled.div<{ isActive: boolean }>`
  line-height: 1.4em;
  padding: 0.4em 0;
  transition: ${animations.standardDuration};
  transition-property: color;
  width: 90%;
  color: ${({ isActive }) => (isActive ? colors.red : colors.fadedJade)};
`;

const SectionHeader = styled(Header)`
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.bold};
  font-size: ${refFontSize(24)}vw;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(22)}vw;
  }
`;

const QuestionHeader = styled(Header)`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(16)}vw;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(14)}vw;
  }
`;

interface Props {
  id: string;
  header: string;
  expanded: boolean;
  toggleAction: (sectionId: string) => void;
  isQuestion?: boolean;
}

const HeaderWrapper = ({ id, expanded, header, isQuestion, toggleAction }: Props) => {
  return (
    <Wrapper id={id} onClick={() => toggleAction(id)}>
      {isQuestion ? (
        <QuestionHeader isActive={expanded}>{header}</QuestionHeader>
      ) : (
        <SectionHeader isActive={expanded}>{header}</SectionHeader>
      )}
      <Sign isRolledDown={expanded} />
    </Wrapper>
  );
};

export default HeaderWrapper;
