import gsap from 'gsap';
import { dropShadow } from 'desktop/utils/dropShadow';
import { createShowHideTrigger } from './createShowHideTrigger';

export const animateSimpleDevice = (sectionId: string, deviceId: string) => {
  const imagesShowFrom = { opacity: 0, scale: 1.5, duration: 1, stagger: 0.1, filter: dropShadow(10, 30, 5, 0.5) };

  const main = gsap.timeline({ paused: true }).from(deviceId + ' img', imagesShowFrom);
  const out = gsap.timeline({ paused: true }).fromTo(deviceId, { opacity: 1 }, { opacity: 0 });

  const showHideTrigger = createShowHideTrigger(sectionId, main, out);

  return {
    kill: () => {
      showHideTrigger.kill();
      main.kill();
      out.kill();
    },
  };
};
