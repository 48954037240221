import { useTranslation } from 'react-i18next';
import contributeChooseCampaign from 'assets/images/contribute/contributeChooseCampaign.png';
import BulletDeviceSection from '../BulletDeviceSection';

const ChooseCampaignSection = () => {
  const { t } = useTranslation('contribute');

  return (
    <BulletDeviceSection
      title={t('choose-campaign.title')}
      description={t('choose-campaign.description')}
      bullet={{ ordinal: 4 }}
      image={contributeChooseCampaign}
      variant={'contribute'}
    />
  );
};

export default ChooseCampaignSection;
