import image from 'assets/images/device/description/image.png';
import DeviceImage from './DeviceImage';

const Description = () => {
  return (
    <div id={'device-description'}>
      <DeviceImage src={image} left={27} top={24} width={375} height={824} />
    </div>
  );
};

export default Description;
