import React from 'react';
import { useTranslation } from 'react-i18next';
import joinTeamImg from 'assets/images/about/join-team.png';
import PhotoTextSection from '../../components/commons/PhotoTextSection';
import ShortSectionContainer from '../../components/containers/ShortSectionContainer';
import StyledScrollDownInfo from '../../components/commons/StyledScrollDownInfo';

const JoinTeamSection = () => {
  const { t } = useTranslation('about');

  return (
    <ShortSectionContainer id={'about-join-team'}>
      <PhotoTextSection
        sectionClassName={'about-join-team'}
        title={t('join-team.title')}
        description={t('join-team.description')}
        image={{ source: joinTeamImg, dimensions: { height: 468, width: 679 } }}
        margin={'medium'}
        titleFont={'big'}
      />
      <StyledScrollDownInfo />
    </ShortSectionContainer>
  );
};

export default JoinTeamSection;
