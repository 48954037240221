import ScreenSection from 'desktop/components/containers/ScreenSection';
import gsap from 'gsap';
import colors from 'assets/styles/colors';
import { animateGoal } from 'desktop/animations/animateGoal';
import { useScrollbar } from '../../components/containers/ScrollbarProvider';

const FundraiseSection = () => {
  const { useScrollReady } = useScrollbar();

  useScrollReady(setupAnimations);

  return <ScreenSection id={'home-fundraise'} />;
};

const setupAnimations = () => {
  const animBackground = gsap
    .timeline({ scrollTrigger: { trigger: '#home-fundraise', start: 'top center', end: 'center center', scrub: true } })
    .to('#home-app-description', { backgroundColor: colors.blue });

  const descIn = gsap
    .timeline({ scrollTrigger: { trigger: '#home-fundraise', start: '20% center', end: '50% center', scrub: true } })
    .from('#home-fundraise-title', { display: 'none' })
    .fromTo('#home-fundraise-title', { opacity: 0, yPercent: 30 }, { opacity: 1, yPercent: 0 }, '<')
    .from('#home-fundraise-description', { display: 'none' })
    .fromTo('#home-fundraise-description', { opacity: 0, yPercent: 30 }, { opacity: 1, yPercent: 0 }, '<')
    .from('#home-share-to-save', { display: 'none' })
    .fromTo('#home-share-to-save', { opacity: 0, yPercent: 30 }, { opacity: 1, yPercent: 0 }, '<');

  const deviceOut = gsap
    .timeline({ scrollTrigger: { trigger: '#home-fundraise', start: '70% top', end: '100% top', scrub: true } })
    .fromTo('#home-device', { yPercent: 0 }, { yPercent: -15 })
    .to('#home-share-to-save', { opacity: 0, display: 'none' }, '<');

  const goal = animateGoal('#home-fundraise');

  return () => {
    animBackground.kill();
    descIn.kill();
    deviceOut.kill();
    goal.kill();
  };
};

export default FundraiseSection;
