import styled from 'styled-components';
import { ReactNode, useEffect, useRef, useState } from 'react';
import Scrollbar from 'smooth-scrollbar';
import ScrollTrigger from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import ScaleSpeedPlugin from '../../utils/ScaleSpeedPlugin';
import { useScrollbar } from './ScrollbarProvider';

const Container = styled.div`
  width: 100%;
  height: 100vh;
`;

interface Props {
  children: ReactNode;
  snapPointsConfig?: { elementIds: string[]; snapToEnd: boolean };
  childrenFadeIn?: boolean;
}

const SmoothScroll = ({ children, snapPointsConfig, childrenFadeIn }: Props) => {
  const { setScrollbar } = useScrollbar();
  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollableHeight, setScrollableHeight] = useState(0);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    Scrollbar.use(ScaleSpeedPlugin);
    const scrollbar = Scrollbar.init(containerRef.current!, {
      damping: 0.1,
      plugins: { scaleSpeed: { speed: 0.8 } },
    });

    ScrollTrigger.scrollerProxy(containerRef.current, {
      scrollTop: (value?: number) => {
        if (value != null) {
          scrollbar.scrollTop = value;
        }
        return scrollbar.scrollTop;
      },
    });

    const listener = () => ScrollTrigger.update();
    scrollbar.addListener(listener);
    scrollbar.setPosition(0, 0);

    ScrollTrigger.defaults({ scroller: containerRef.current });
    ScrollTrigger.update();

    setScrollbar(scrollbar);

    return () => {
      scrollbar.removeListener(listener);
      scrollbar.destroy();
      setScrollbar(null);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const scrollContent = document.querySelector<HTMLDivElement>('.scroll-content');
      if (scrollContent) {
        // if content has lower height than window, then there is no scroll at all
        // this is the reason why we need to subtract window height, when calculating scrollable height
        setScrollableHeight(scrollContent.offsetHeight - window.innerHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (scrollableHeight > 0 && snapPointsConfig) {
      const { elementIds, snapToEnd } = snapPointsConfig;

      const normalizedPoints = elementIds
        .map(elementId => document.querySelector<HTMLDivElement>(elementId))
        .filter(element => !!element)
        .map(element => element!.offsetTop + (element!.offsetHeight - window.innerHeight) / 2)
        .map(adjustedOffset => adjustedOffset / scrollableHeight);

      const snapTrigger = ScrollTrigger.create({
        snap: {
          snapTo: snapToEnd ? [...normalizedPoints, 1] : normalizedPoints,
          duration: { min: 0.2, max: 0.8 },
          delay: 0,
        },
      });

      return () => {
        snapTrigger.kill();
      };
    }
  }, [scrollableHeight]);

  useEffect(() => {
    if (childrenFadeIn) {
      const inAnimation = gsap.fromTo(containerRef.current, { opacity: 0 }, { opacity: 1, duration: 1, delay: 0.5 });
      return () => {
        inAnimation.kill();
      };
    }
  }, [childrenFadeIn]);

  return <Container ref={containerRef}>{children}</Container>;
};

export default SmoothScroll;
