import React, { FunctionComponent, SVGProps } from 'react';
import styled from 'styled-components';
import colors from '../../../assets/styles/colors';
import PointerDiv from '../../../desktop/components/commons/PointerDiv';
import { animations } from '../../../assets/styles/animations';

const Wrapper = styled(PointerDiv)<{ iconWidth: number; iconMarginRight: number }>`
  width: ${props => props.iconWidth}vw;
  height: ${props => props.iconWidth}vw;
  margin-right: ${props => props.iconMarginRight}vw;

  > * {
    fill: ${colors.red};
    width: 100%;
    height: 100%;
    transition-duration: ${animations.standardDuration};
  }

  > :hover {
    fill: ${colors.cello};
  }
`;

interface Props {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  onClick: () => void;
  iconWidth: number;
  iconMarginRight: number;
}

const SvgWithHoover = ({ Icon, onClick, iconWidth, iconMarginRight }: Props) => {
  return (
    <Wrapper iconWidth={iconWidth} iconMarginRight={iconMarginRight} onClick={onClick}>
      <Icon />
    </Wrapper>
  );
};

export default SvgWithHoover;
