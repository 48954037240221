import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import joinTeamImg from 'assets/images/about/join-team.png';
import BulletPhotoSection from '../BulletPhotoSection';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const JoinTeamSection = () => {
  const { t } = useTranslation('about');

  return (
    <Container>
      <BulletPhotoSection
        title={t('join-team.title')}
        description={t('join-team.description')}
        image={{ source: joinTeamImg, dimensions: { width: 345, height: 275 } }}
      />
    </Container>
  );
};

export default JoinTeamSection;
