import React from 'react';
import styled from 'styled-components';
import { refFontSize, refFontSizeCalc, refHeight, refWidth } from 'desktop/utils/dimensions';
import { useTranslation } from 'react-i18next';
import { mq } from 'assets/styles/mediaQuery';
import { fonts } from 'assets/styles/fonts';
import { variantConfig, VariantType } from 'commons/types/bulletTextVariant';
import { FontSizeType, MarginType, WidthType } from './types';

const Container = styled.div<{ reversed?: boolean; width: WidthType; margin: MarginType }>`
  width: ${({ width }) => refWidth(width === 'big' ? 600 : 360)}vw;
  margin-right: ${({ reversed, margin }) => refWidth(reversed ? 0 : margin === 'big' ? 240 : 120)}vw;
  margin-left: ${({ reversed, margin }) => refWidth(reversed ? (margin === 'big' ? 240 : 120) : 0)}vw;

  ${mq.desktopSmall} {
    margin-right: ${({ reversed, margin }) => refWidth(reversed ? 0 : margin === 'big' ? 270 : 135)}vw;
    margin-left: ${({ reversed, margin }) => refWidth(reversed ? (margin === 'big' ? 270 : 135) : 0)}vw;
  }

  ${mq.tablet} {
    margin-right: ${({ reversed, margin }) => refWidth(reversed ? 0 : margin === 'big' ? 300 : 150)}vw;
    margin-left: ${({ reversed, margin }) => refWidth(reversed ? (margin === 'big' ? 300 : 150) : 0)}vw;
  }
`;

const BulletWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${refHeight(36)}vh;
`;

const BulletOrdinalWrapper = styled.div<{ color: string }>`
  width: ${refWidth(48)}vw;
  height: ${refWidth(48)}vw;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${refWidth(20)}vw;
`;

const BulletOrdinalTextWrapper = styled.div<{ color: string }>`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(18)}vw;
  line-height: ${refFontSize(32)}vw;
  color: ${({ color }) => color};

  ${mq.tablet} {
    font-size: ${refFontSizeCalc(18, 1.2)}vw;
  }
`;

const BulletTextWrapper = styled.div<{ color: string }>`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(14)}vw;
  line-height: 1.5em;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: ${({ color }) => color};

  ${mq.desktopSmall} {
    font-size: ${refFontSizeCalc(14, 1.1)}vw;
  }

  ${mq.tablet} {
    font-size: ${refFontSizeCalc(14, 1.2)}vw;
  }

  ${mq.phone} {
    font-size: ${refFontSizeCalc(14, 1.4)}vw;
  }
`;

const TitleTextWrapper = styled.div<{ color: string; fontType: FontSizeType }>`
  white-space: pre-line;
  margin-bottom: ${refWidth(60)}vw;
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.regular};
  font-size: ${({ fontType }) => refFontSize(fontType === 'big' ? 65 : 40)}vw;
  line-height: 1em;
  color: ${({ color }) => color};

  ${mq.desktopSmall} {
    font-size: ${({ fontType }) => refFontSize(fontType === 'big' ? 70 : 45)}vw;
  }

  ${mq.tablet} {
    font-size: ${({ fontType }) => refFontSize(fontType === 'big' ? 85 : 50)}vw;
  }

  ${mq.phone} {
    font-size: ${({ fontType }) => refFontSize(fontType === 'big' ? 90 : 55)}vw;
  }
`;

const DescriptionTextWrapper = styled.div<{ color: string }>`
  white-space: pre-line;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(22)}vw;
  line-height: 1.5em;
  color: ${({ color }) => color};

  ${mq.desktopSmall} {
    font-size: ${refFontSizeCalc(22, 1.1)}vw;
  }
`;

interface Props {
  title: string;
  description: string;
  sectionClassName: string;
  bullet?: { text: string; ordinal?: number } | { text?: string; ordinal: number };
  reversed?: boolean;
  variant?: VariantType;
  width?: WidthType;
  margin?: MarginType;
  titleFont?: FontSizeType;
}

const BulletTextSection = ({ title, description, sectionClassName, bullet, ...options }: Props) => {
  const variantColors = variantConfig.get(options.variant || 'mixed')!;
  const { t } = useTranslation();

  return (
    <Container reversed={options.reversed} width={options.width || 'big'} margin={options.margin || 'big'}>
      {bullet && (
        <BulletWrapper className={sectionClassName + '-text'}>
          {bullet.ordinal && (
            <BulletOrdinalWrapper color={variantColors.bullet}>
              <BulletOrdinalTextWrapper color={variantColors.ordinal}>{bullet.ordinal}</BulletOrdinalTextWrapper>
            </BulletOrdinalWrapper>
          )}
          <BulletTextWrapper color={variantColors.bullet}>{bullet.text || t('how-it-works')}</BulletTextWrapper>
        </BulletWrapper>
      )}
      <TitleTextWrapper
        color={variantColors.title}
        fontType={options.titleFont || 'medium'}
        className={sectionClassName + '-text'}>
        {title}
      </TitleTextWrapper>
      <DescriptionTextWrapper color={variantColors.description} className={sectionClassName + '-text'}>
        {description}
      </DescriptionTextWrapper>
    </Container>
  );
};

export default BulletTextSection;
