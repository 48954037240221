import { animateSimpleDevice } from 'desktop/animations/animateSimpleDevice';
import ScreenSection from '../../components/containers/ScreenSection';
import { useScrollbar } from '../../components/containers/ScrollbarProvider';
import { animateCarouselIn } from '../../animations/animateCarouselIn';
import { animateCarouselOut } from '../../animations/animateCarouselOut';

const BuyWithClickSection = () => {
  const { useScrollReady } = useScrollbar();

  useScrollReady(setupAnimations);

  return <ScreenSection id={'buy-buy-with-click'} />;
};

const setupAnimations = () => {
  const trigger = '#buy-buy-with-click';

  const simpleDevice = animateSimpleDevice(trigger, '#device-item');
  const carouselIn = animateCarouselIn(trigger, '.buy-buy-with-click-text');
  const carouselOut = animateCarouselOut(trigger, '.buy-search-items-text');

  return () => {
    simpleDevice.kill();
    carouselIn.kill();
    carouselOut.kill();
  };
};

export default BuyWithClickSection;
