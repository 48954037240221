import { useTranslation } from 'react-i18next';
import BulletDeviceSection from '../BulletDeviceSection';
import buyWithClick from '../../../assets/images/buy/buyWithClick.png';

const BuyWithClickSection = () => {
  const { t } = useTranslation('buy');

  return (
    <BulletDeviceSection
      title={t('buy-with-click.title')}
      description={t('buy-with-click.description')}
      bullet={{ ordinal: 2 }}
      image={buyWithClick}
      variant={'buy'}
    />
  );
};

export default BuyWithClickSection;
