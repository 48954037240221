import fundraiseWithdraw from 'assets/images/fundraise/fundraiseWithdraw.png';
import { useTranslation } from 'react-i18next';
import BulletDeviceSection from '../BulletDeviceSection';

const WithdrawSection = () => {
  const { t } = useTranslation('fundraise');

  return (
    <BulletDeviceSection
      title={t('withdraw.title')}
      description={t('withdraw.description')}
      bullet={{ ordinal: 4 }}
      image={fundraiseWithdraw}
      variant={'fundraise'}
    />
  );
};

export default WithdrawSection;
