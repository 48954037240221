import { useTranslation } from 'react-i18next';
import contributeItsSimple from 'assets/images/contribute/contributeItsSimple.png';
import BulletDeviceSection from '../BulletDeviceSection';

const StartWithPictureSection = () => {
  const { t } = useTranslation('contribute');

  return (
    <BulletDeviceSection
      title={t('start-with-picture.title')}
      description={t('start-with-picture.description')}
      bullet={{ ordinal: 1 }}
      image={contributeItsSimple}
      variant={'contribute'}
      hasTopPadding={true}
    />
  );
};

export default StartWithPictureSection;
