import { v4 as uuid } from 'uuid';
import i18n from '../../../translations/config';
import { Section } from './faqObjects';

export const loadFAQContent = () => {
  const sections: Section[] = i18n.t('faq:faq', { returnObjects: true });
  return sections.map(section => ({
    ...section,
    id: 'section-' + uuid(),
    expanded: false,
    questions: section.questions.map(question => ({
      ...question,
      id: 'question-' + uuid(),
      expanded: false,
    })),
  }));
};
