import styled from 'styled-components';
import appStoreImg from 'assets/images/download/app-store-button.svg';
import googlePlayImg from 'assets/images/download/google-play-button.svg';
import { refWidth, refWidthLandscape } from '../../utils/dimensions';
import { openInNewTab, useStoreLink } from '../../../commons/utils/links';
import { mq } from '../../../assets/styles/mediaQuery';

const StoreButton = styled.img<{ width?: number }>`
  width: ${({ width }) => refWidth(width ? width : 167)}vw;

  ${mq.landscape} {
    width: ${({ width }) => refWidthLandscape(width ? width : 167)}vw;
  }
`;

interface Props {
  width?: number;
}

export const AppStoreButton = ({ width }: Props) => {
  const { appStoreLink } = useStoreLink();
  return <StoreButton src={appStoreImg} width={width} onClick={() => openInNewTab(appStoreLink())} />;
};

export const GooglePlayButton = ({ width }: Props) => {
  const { googlePlayLink } = useStoreLink();
  return <StoreButton src={googlePlayImg} width={width} onClick={() => openInNewTab(googlePlayLink())} />;
};
