import { useTranslation } from 'react-i18next';
import unwantedStuffImg from 'assets/images/contribute/contributeUnwantedStuff.png';
import React from 'react';
import shape1 from 'assets/images/contribute/shapes/unwantedStuff/shape1.svg';
import shape2 from 'assets/images/contribute/shapes/unwantedStuff/shape2.svg';
import shape3 from 'assets/images/contribute/shapes/unwantedStuff/shape3.svg';
import shape4 from 'assets/images/contribute/shapes/unwantedStuff/shape4.svg';
import shape5 from 'assets/images/contribute/shapes/unwantedStuff/shape5.svg';
import PhotoTextSection from '../../components/commons/PhotoTextSection';
import ShortSectionContainer from '../../components/containers/ShortSectionContainer';

const UnwantedStuffSection = () => {
  const { t } = useTranslation('contribute');

  return (
    <ShortSectionContainer id={'contribute-unwanted-stuff'}>
      <PhotoTextSection
        sectionClassName={'contribute-unwanted-stuff'}
        title={t('unwanted-stuff.title')}
        description={t('unwanted-stuff.description')}
        image={{ source: unwantedStuffImg, dimensions: { height: 550, width: 720 } }}
        margin={'medium'}
        shapes={[
          { source: shape1, dimensions: { left: -92, top: 421, width: 19, height: 15 } },
          { source: shape2, dimensions: { left: -66, top: -55, width: 178, height: 245 } },
          { source: shape3, dimensions: { left: -88, top: 418, width: 74, height: 93 } },
          { source: shape4, dimensions: { left: 389, top: 279, width: 451, height: 394 } },
          { source: shape5, dimensions: { left: 83, top: -84, width: 88, height: 75 } },
        ]}
      />
    </ShortSectionContainer>
  );
};

export default UnwantedStuffSection;
