import styled from 'styled-components';
import { zIndex } from 'assets/styles/zIndex';
import { refHeight, refWidth } from 'desktop/utils/dimensions';

export interface ShapeDimensions {
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface ImageDimensions {
  width: number;
  height: number;
}

export const ImageAdjacentShape = styled.img<{ shapeDimensions: ShapeDimensions; imageDimensions: ImageDimensions }>`
  position: absolute;
  z-index: ${zIndex.under};
  top: ${props => refHeight(props.shapeDimensions.top, props.imageDimensions.height)}%;
  left: ${props => refWidth(props.shapeDimensions.left, props.imageDimensions.width)}%;
  width: ${props => refWidth(props.shapeDimensions.width, props.imageDimensions.width)}%;
  height: ${props => refHeight(props.shapeDimensions.height, props.imageDimensions.height)}%;
`;
