import PageWrapper from 'mobile/components/containers/PageWrapper';
import UnwantedStuffSection from 'mobile/sections/contribute/UnwantedStuffSection';
import YourDonationsSection from 'mobile/sections/contribute/YourDonationsSection';
import SetPriceSection from 'mobile/sections/contribute/SetPriceSection';
import ChooseCampaignSection from 'mobile/sections/contribute/ChooseCampaignSection';
import TaxDeductibleSection from 'mobile/sections/contribute/TaxDeductibleSection';
import GiveLittleSection from 'mobile/sections/contribute/GiveLittleSection';
import { useTranslation } from 'react-i18next';
import StartWithPictureSection from 'mobile/sections/contribute/StartWithPictureSection';
import DeliveryMethodSection from 'mobile/sections/contribute/DeliveryMethodSection';
import ExpandableHeader from '../components/commons/ExpandableHeader';
import Footer from '../sections/Footer';
import DownloadSection from '../sections/DownloadSection';

const Fundraise = () => {
  const { t } = useTranslation('contribute');
  return (
    <ExpandableHeader>
      <PageWrapper>
        <YourDonationsSection />
        <UnwantedStuffSection />
        <StartWithPictureSection />
        <SetPriceSection />
        <DeliveryMethodSection />
        <ChooseCampaignSection />
        <TaxDeductibleSection />
        <GiveLittleSection />
        <DownloadSection
          subtitle={t('download.subtitle')}
          title={t('download.title')}
          downloadTitle={t('download.download-title')}
        />
        <Footer />
      </PageWrapper>
    </ExpandableHeader>
  );
};

export default Fundraise;
