import styled from 'styled-components';
import { refHeight, refWidth } from 'desktop/utils/dimensions';
import { REFERENCE_DEVICE_HEIGHT, REFERENCE_DEVICE_WIDTH } from './dimensions';

const DeviceImage = styled.img<{ top: number; left: number; width: number; height: number }>`
  position: absolute;
  top: ${props => refHeight(props.top, REFERENCE_DEVICE_HEIGHT)}%;
  left: ${props => refWidth(props.left, REFERENCE_DEVICE_WIDTH)}%;
  width: ${props => refWidth(props.width, REFERENCE_DEVICE_WIDTH)}%;
  height: ${props => refHeight(props.height, REFERENCE_DEVICE_HEIGHT)}%;
`;

export default DeviceImage;
