import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FAQProvider } from '../../../commons/components/faq/FAQProvider';
import SmoothScroll from '../containers/SmoothScroll';
import DownloadSection from '../../sections/DownloadSection';
import Footer from '../../sections/Footer';
import IssueCollector from '../../../commons/components/faq/IssueCollector';
import { refHeight, refWidth } from '../../utils/dimensions';
import { Subtitle } from '../legalDocument/LegalDocumentTypography';
import ShortButton from '../buttons/ShortButton';
import { useScrollbar } from '../containers/ScrollbarProvider';
import FAQContent from './FAQContent';

const FormLauncherWrapper = styled.div`
  margin-left: ${refWidth(300)}vw;
  display: flex;
  align-items: center;
  margin-top: ${refHeight(50)}vh;
  margin-bottom: ${refHeight(50)}vh;
`;

const FormSubtitle = styled(Subtitle)`
  margin-top: 0;
  margin-right: ${refWidth(20)}vw;
`;

const FAQSection = () => {
  const [modal, setModal] = useState(false);
  const { t } = useTranslation('home');
  const { scrollIntoView, refreshTriggers } = useScrollbar();

  const closeModal = () => {
    setModal(false);
  };

  const openModal = () => {
    setModal(true);
  };

  const onScrollRequest = (elementId: string, isExpanded: boolean) =>
    isExpanded ? scrollIntoView('#' + elementId) : refreshTriggers();

  return (
    <SmoothScroll childrenFadeIn={true}>
      <FAQProvider onScrollRequest={onScrollRequest}>
        <FAQContent />
      </FAQProvider>
      <FormLauncherWrapper>
        <FormSubtitle>{t('faq:form-launcher')}</FormSubtitle>
        <ShortButton onClick={openModal} value={t('common:no-button')} />
      </FormLauncherWrapper>
      <DownloadSection
        subtitle={t('download.subtitle')}
        title={t('download.title')}
        downloadTitle={t('download.download-title')}
      />
      <Footer />
      {modal && <IssueCollector closeModal={closeModal} />}
    </SmoothScroll>
  );
};

export default FAQSection;
