import colors from 'assets/styles/colors';

export type VariantType = 'fundraise' | 'buy' | 'contribute' | 'mixed';

interface VariantConfig {
  background: string;
  title: string;
  description: string;
  bullet: string;
  ordinal: string;
}

export const variantConfig: Map<VariantType, VariantConfig> = new Map([
  [
    'mixed',
    {
      background: colors.transparent,
      title: colors.cello,
      description: colors.fadedJade,
      bullet: colors.red,
      ordinal: colors.white,
    },
  ],
  [
    'fundraise',
    {
      background: colors.blue,
      title: colors.white,
      description: colors.geyser,
      bullet: colors.shadedBlack,
      ordinal: colors.white,
    },
  ],
  [
    'buy',
    {
      background: colors.red,
      title: colors.white,
      description: colors.white,
      bullet: colors.shadedBlack,
      ordinal: colors.white,
    },
  ],
  [
    'contribute',
    {
      background: colors.pancho,
      title: colors.white,
      description: colors.white,
      bullet: colors.shadedBlack,
      ordinal: colors.white,
    },
  ],
]);
