import fundraiseStartCampaign from 'assets/images/fundraise/fundraiseStartCampaign.png';
import shape1 from 'assets/images/fundraise/shapes/startCampaign/shape1.svg';
import shape2 from 'assets/images/fundraise/shapes/startCampaign/shape2.svg';
import shape3 from 'assets/images/fundraise/shapes/startCampaign/shape3.svg';
import { useTranslation } from 'react-i18next';
import BulletPhotoSection from '../BulletPhotoSection';

const StartCampaignSection = () => {
  const { t } = useTranslation('fundraise');

  return (
    <BulletPhotoSection
      title={t('start-campaign.title')}
      description={t('start-campaign.description')}
      bullet={{ ordinal: 1 }}
      image={{ source: fundraiseStartCampaign, dimensions: { width: 315, height: 240 } }}
      shapes={[
        { source: shape1, dimensions: { left: 243, top: -39, width: 32, height: 25 } },
        { source: shape2, dimensions: { left: 273, top: -28, width: 72, height: 74 } },
        { source: shape3, dimensions: { left: -74, top: 109, width: 234, height: 188 } },
      ]}
      hasTopPadding={true}
    />
  );
};

export default StartCampaignSection;
