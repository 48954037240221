import { useGlobalNavigation } from '../../navigation/utils/NavigationProvider';
import scrollToTop from './scrollToTop';

export const useNavigation = () => {
  const { navigateOrScrollToTop } = useGlobalNavigation();

  return {
    navigate: (path: string) => navigateOrScrollToTop(path, scrollToTop),
  };
};
