import QRCode from 'qrcode.react';
import { refWidth } from 'desktop/utils/dimensions';
import styled from 'styled-components';

const DOWNLOAD_SUFFIX = '/download';

const ResponsiveSvgWrapper = styled.div`
  svg {
    display: block;
    height: auto;
    width: ${refWidth(110)}vw;
  }
`;

const QRCodeWrapper = () => {
  return (
    <ResponsiveSvgWrapper>
      <QRCode renderAs={'svg'} value={`${process.env.REACT_APP_WEB_URL || ''}${DOWNLOAD_SUFFIX}`} />
    </ResponsiveSvgWrapper>
  );
};

export default QRCodeWrapper;
