import styled from 'styled-components';
import { refWidth } from 'desktop/utils/dimensions';
import appStoreImg from 'assets/images/download/app-store-button.svg';
import googlePlayImg from 'assets/images/download/google-play-button.svg';
import { openInNewTab, useStoreLink } from '../../../commons/utils/links';

const StoreButton = styled.img`
  width: ${refWidth(167)}vw;
  height: auto;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

interface Props {
  className?: string;
}

export const AppStoreButton = ({ className }: Props) => {
  const { appStoreLink } = useStoreLink();
  return <StoreButton src={appStoreImg} className={className} onClick={() => openInNewTab(appStoreLink())} />;
};

export const GooglePlayButton = ({ className }: Props) => {
  const { googlePlayLink } = useStoreLink();
  return <StoreButton src={googlePlayImg} className={className} onClick={() => openInNewTab(googlePlayLink())} />;
};
