import React, { useState } from 'react';
import gsap from 'gsap';
import { useTranslation } from 'react-i18next';
import playElipse from 'assets/images/home/seeApp/playElipse.svg';
import styled from 'styled-components';
import { refFontSize, refHeight, refWidth } from 'desktop/utils/dimensions';
import colors from 'assets/styles/colors';
import { mq } from 'assets/styles/mediaQuery';
import { fonts } from 'assets/styles/fonts';
import ShortSectionContainer from '../../components/containers/ShortSectionContainer';
import SectionContainer from '../../components/containers/SectionContainer';
import ExplainerPlayer from '../../components/home/ExplainerPlayer';
import { useScrollbar } from '../../components/containers/ScrollbarProvider';
import HomeButton from '../../components/buttons/HomeButton';

const Container = styled(SectionContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const TextSectionWrapper = styled.div`
  margin-right: ${refWidth(120)}vw;
  ${mq.tablet} {
    height: ${refWidth(200)}vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const SeeTitle = styled.div`
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(70)}vw;
  line-height: 1em;
  color: ${colors.cello};
  ${mq.tablet} {
    font-size: ${refFontSize(80)}vw;
  }
  ${mq.phone} {
    font-size: ${refFontSize(90)}vw;
  }
`;

const SeeDescription = styled.div`
  height: ${refHeight(96)}vh;
  margin-top: ${refHeight(69)}vh;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(18)}vw;
  line-height: 1.78em;
  color: ${colors.fadedJade};
  ${mq.tablet} {
    font-size: ${refFontSize(24)}vw;
  }
  ${mq.phone} {
    font-size: ${refFontSize(28)}vw;
  }
`;

const PlayButtonWrapper = styled.div`
  margin-top: ${refHeight(72.4)}vh;
  ${mq.tablet} {
    margin-top: ${refHeight(52.4)}vh;
  }
`;

const PlayButton = styled(HomeButton)`
  position: relative;
  justify-content: flex-start;
  width: ${refWidth(175)}vw;
  height: ${refWidth(60)}vw;
  ${mq.desktopSmall} {
    width: ${refWidth(200)}vw;
    height: ${refWidth(70)}vw;
  }
  ${mq.phone} {
    width: ${refWidth(210)}vw;
    height: ${refWidth(80)}vw;
  }
`;

const PlayImg = styled.img`
  width: ${refWidth(32)}vw;
  height: auto;
  padding: ${refHeight(14)}vh ${refWidth(15)}vw ${refHeight(14)}vh ${refWidth(18)}vw;
`;

const SeeSection = () => {
  const { t } = useTranslation('home');
  const { useScrollReady } = useScrollbar();

  const [fullScreenVideo, setFullScreenVideo] = useState(false);
  const [play, setPlay] = useState(false);

  const setupAnimations = () => {
    const descIn = gsap
      .timeline({
        scrollTrigger: {
          trigger: '#home-see',
          start: '20% center',
          end: '50% center',
          scrub: true,
          onEnter: () => setPlay(true),
          onLeaveBack: () => setPlay(false),
        },
      })
      .fromTo('#see-title', { y: -10, opacity: 0 }, { y: 0, opacity: 1 })
      .fromTo('.see-description-elem', { y: 50, opacity: 0 }, { y: 0, opacity: 1, stagger: 0.2 }, '-=0.3');

    const descOut = gsap.timeline({
      scrollTrigger: {
        trigger: '#home-see',
        start: '70% center',
        end: 'end center',
        scrub: true,
        onEnterBack: () => setPlay(true),
        onLeave: () => setPlay(false),
      },
    });
    return () => {
      descIn.kill();
      descOut.kill();
    };
  };

  useScrollReady(setupAnimations);

  return (
    <ShortSectionContainer id={'home-see'}>
      <Container id={'see-container'}>
        <ExplainerPlayer
          play={play}
          playFullScreen={fullScreenVideo}
          onFullScreenExit={() => setFullScreenVideo(false)}
        />

        {!fullScreenVideo && (
          <TextSectionWrapper>
            <SeeTitle id={'see-title'}>
              {t('see-app.title-line-1')} <br /> {t('see-app.title-line-2')}
            </SeeTitle>
            <SeeDescription className={'see-description-elem'}>
              <div>{t('see-app.post')}</div>
              <div>{t('see-app.buy')}</div>
              <div>{t('see-app.start-campaign')}</div>
            </SeeDescription>
            <PlayButtonWrapper>
              <PlayButton
                text={t('see-app.play')}
                color={colors.red}
                className={'see-description-elem'}
                onClick={() => setFullScreenVideo(true)}>
                <PlayImg src={playElipse} />
              </PlayButton>
            </PlayButtonWrapper>
          </TextSectionWrapper>
        )}
      </Container>
    </ShortSectionContainer>
  );
};

export default SeeSection;
