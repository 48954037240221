import image from 'assets/images/device/delivery/image.png';
import DeviceImage from './DeviceImage';

const Delivery = () => {
  return (
    <div id={'device-delivery'}>
      <DeviceImage src={image} left={27} top={24} width={375} height={824} />
    </div>
  );
};

export default Delivery;
