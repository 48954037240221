import { useTranslation } from 'react-i18next';
import colors from 'assets/styles/colors';
import AnimatedDeviceSection from '../AnimatedDeviceSection';
import { useNavigation } from '../../utils/useNavigation';

const DonateSection = () => {
  const { t } = useTranslation('home');
  const { navigate } = useNavigation();

  const onMainButtonClick = () => navigate('/contribute');

  return (
    <AnimatedDeviceSection
      id={'donate'}
      title={t('donate.title')}
      description={t('donate.description')}
      backgroundColor={colors.pancho}
      frames={{ directory: 'contribute', firstFrame: 100, lastFrame: 140 }}
      onMainButtonClick={onMainButtonClick}
      translation={t('give-to-earn')}
    />
  );
};

export default DonateSection;
