import DeviceMockup from 'desktop/components/device/DeviceMockup';
import { WithId } from 'commons/types/commonProps';
import { DeviceContainer } from '../device/DeviceContainer';
import Marketplace from '../device/Marketplace';

const BuyDeviceFirst = ({ id }: WithId) => {
  return (
    <DeviceContainer id={id}>
      <DeviceMockup />
      <Marketplace />
    </DeviceContainer>
  );
};

export default BuyDeviceFirst;
