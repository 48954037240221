import React, { useState } from 'react';
import styled from 'styled-components';
import colors from 'assets/styles/colors';
import { animations } from 'assets/styles/animations';
import { fonts } from 'assets/styles/fonts';
import { mq } from 'assets/styles/mediaQuery';
import FAQImage from 'assets/images/faq/faq.png';
import { useTranslation } from 'react-i18next';
import { refFontSize, refFontSizeCalc, refHeight, refWidth, refWidthCalc } from 'desktop/utils/dimensions';
import SearchInput from '../inputs/SearchInput';
import { useFAQContext } from '../../../commons/components/faq/FAQProvider';
import Section from './Section';

const Container = styled.div`
  font-family: ${fonts.family.description};
  margin-left: ${refWidth(300)}vw;
  display: flex;
  flex-direction: column;

  ${mq.desktopSmall} {
    margin-left: ${refWidthCalc(300, 0.8)}vw;
  }

  ${mq.tablet} {
    margin-left: ${refWidthCalc(300, 0.7)}vw;
  }
`;

const SectionsWrapper = styled.div`
  margin-top: ${refHeight(60)}vh;
  width: ${refWidth(800)}vw;

  ${mq.desktopSmall} {
    width: ${refWidthCalc(800, 1.4)}vw;
  }

  ${mq.tablet} {
    width: ${refWidthCalc(800, 1.5)}vw;
  }
`;

const Title = styled.div`
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.bold};
  font-size: ${refFontSize(70)}vw;
  line-height: 1.8em;
  margin-bottom: ${refHeight(40)}vh;
  color: ${colors.fadedJade};

  ${mq.tablet} {
    font-size: ${refFontSizeCalc(70, 1.15)}vw;
  }

  ${mq.phone} {
    font-size: ${refFontSizeCalc(70, 1.25)}vw;
  }
`;

const Subtitle = styled.div`
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(22)}vw;
  line-height: 1.8em;
  color: ${colors.cello};

  ${mq.tablet} {
    font-size: ${refFontSizeCalc(22, 1.15)}vw;
  }

  ${mq.phone} {
    font-size: ${refFontSizeCalc(22, 1.25)}vw;
  }
`;

const HeaderSubtitle = styled(Subtitle)`
  margin-bottom: ${refHeight(20)}vh;
`;

const StyledHr = styled.hr`
  border: 1px solid ${colors.geyser};
  border-bottom: none;
  width: 100%;
`;

const ImageWrapper = styled.img`
  position: relative;
  left: ${refWidth(450)}vw;
  top: ${refHeight(150)}vh;
  width: ${refWidth(700)}vw;

  ${mq.desktopSmall} {
    width: ${refWidthCalc(700, 1.2)}vw;
    left: ${refWidthCalc(450, 1.2)}vw;
  }

  ${mq.tablet} {
    width: ${refWidthCalc(700, 1.4)}vw;
    left: ${refWidthCalc(450, 1.4)}vw;
  }
`;

const NotFoundWrapper = styled.div<{ shouldDisplay: boolean }>`
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(14)}vw;
  line-height: 2em;
  color: ${colors.red};
  transition-duration: ${animations.standardDuration};
  opacity: ${({ shouldDisplay }) => (shouldDisplay ? 1 : 0)};

  ${mq.tablet} {
    font-size: ${refFontSizeCalc(14, 1.3)}vw;
  }

  ${mq.phone} {
    font-size: ${refFontSizeCalc(14, 1.4)}vw;
  }
`;

const FAQContent = () => {
  const { t } = useTranslation('faq');
  const [searchText, setSearchText] = useState<string>('');
  const [shouldDisplayNotFoundMessage, setShouldDisplayNotFoundMessage] = useState(false);
  const { sections, search } = useFAQContext();

  const applySearch = () => {
    const found = search(searchText);
    setShouldDisplayNotFoundMessage(!found);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLImageElement>) => {
    if (e.key === 'Enter') {
      applySearch();
    }
  };

  const changeSearchValue = (value: string) => {
    setSearchText(value);
    setShouldDisplayNotFoundMessage(false);
  };

  return (
    <>
      <Container onKeyPress={handleKeyPress} id={'FAQContainer'}>
        <ImageWrapper src={FAQImage} alt={'faq'} />
        <Title>{t('title')}</Title>
        <HeaderSubtitle>{t('subtitle')}</HeaderSubtitle>
        <SearchInput value={searchText} onChange={changeSearchValue} onIconClick={applySearch} />
        <NotFoundWrapper shouldDisplay={shouldDisplayNotFoundMessage}>{t('not-found')}</NotFoundWrapper>
        <SectionsWrapper>
          {sections.map((section, index) => (
            <div key={index}>
              {index !== 0 && <StyledHr />}
              <Section section={section} />
            </div>
          ))}
          <StyledHr />
        </SectionsWrapper>
      </Container>
    </>
  );
};

export default FAQContent;
