import { useTranslation } from 'react-i18next';
import ExpandableHeader from 'desktop/components/commons/ExpandableHeader';
import SmoothScroll from 'desktop/components/containers/SmoothScroll';
import { ScrollbarProvider } from 'desktop/components/containers/ScrollbarProvider';
import useOrphanPrevention from 'commons/utils/useOrphanPrevention';
import DownloadSection from '../sections/DownloadSection';
import Footer from '../sections/Footer';
import JoinTeamSection from '../sections/about/JoinTeamSection';
import OurStorySection from '../sections/about/OurStorySection';
import OurValuesSection from '../sections/about/OurValuesSection';
import StatsSection from '../sections/about/StatsSection';
import TechnologiesSection from '../sections/about/TechnologiesSection';

const About = () => {
  const { t } = useTranslation('about');
  useOrphanPrevention();

  return (
    <ScrollbarProvider>
      <ExpandableHeader />
      <SmoothScroll childrenFadeIn={true}>
        <JoinTeamSection />
        <OurValuesSection />
        <StatsSection />
        <OurStorySection />
        <TechnologiesSection />
        <DownloadSection
          subtitle={t('download.subtitle')}
          title={t('download.title')}
          downloadTitle={t('download.download-title')}
        />
        <Footer />
      </SmoothScroll>
    </ScrollbarProvider>
  );
};

export default About;
