import styled from 'styled-components';
import { refWidth, refWidthLandscape } from 'mobile/utils/dimensions';
import BulletTextSection from '../components/commons/BulletTextSection';
import ImageWithShapes from '../components/commons/ImageWithShapes';
import { ImageDimensions, ShapeDimensions } from '../components/commons/ImageAdjacentShape';
import { mq } from '../../assets/styles/mediaQuery';
import { FontSizeType } from '../components/commons/types';

const Container = styled.div<{ hasTopPadding: boolean }>`
  padding-top: ${({ hasTopPadding }) => refWidth(hasTopPadding ? 50 : 0)}vw;
  padding-bottom: ${refWidth(60)}vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.landscape} {
    padding-top: ${({ hasTopPadding }) => refWidthLandscape(hasTopPadding ? 50 : 0)}vw;
    padding-bottom: ${refWidthLandscape(60)}vw;
  }
`;

const Spacer = styled.div`
  height: ${refWidth(30)}vw;
  width: 100%;

  ${mq.landscape} {
    height: ${refWidthLandscape(30)}vw;
  }
`;

interface Props {
  title: string;
  description: string;
  bullet?: { text: string; ordinal?: number } | { text?: string; ordinal: number };
  image: { dimensions: ImageDimensions; source: string };
  shapes?: { dimensions: ShapeDimensions; source: string }[];
  hasTopPadding?: boolean;
  fontSizeType?: FontSizeType;
}

const BulletPhotoSection = ({ image, shapes, hasTopPadding = false, ...bulletTextProps }: Props) => {
  return (
    <Container hasTopPadding={hasTopPadding}>
      <ImageWithShapes image={image} shapes={shapes} />
      <Spacer />
      <BulletTextSection {...bulletTextProps} />
    </Container>
  );
};

export default BulletPhotoSection;
