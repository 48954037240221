import gsap from 'gsap';

export const animateDeviceTextSection = (sectionId: string, elementIds: string) => {
  const animIn = gsap
    .timeline({ scrollTrigger: { trigger: sectionId, start: '20% center', end: '50% center', scrub: true } })
    .from(elementIds, { display: 'none' })
    .fromTo(elementIds, { opacity: 0, yPercent: 30 }, { opacity: 1, yPercent: 0, stagger: 0.25 });

  const animOut = gsap
    .timeline({ scrollTrigger: { trigger: sectionId, start: '70% center', end: '100% center', scrub: true } })
    .to(elementIds, { opacity: 0, display: 'none' });

  return {
    kill: () => {
      animIn.kill();
      animOut.kill();
    },
  };
};
