import ScreenSection from 'desktop/components/containers/ScreenSection';
import { BuyDeviceContainer } from 'desktop/components/buy/BuyDeviceContainer';
import BuyDeviceFirst from 'desktop/components/buy/BuyDeviceFirst';
import { useScrollbar } from 'desktop/components/containers/ScrollbarProvider';
import { animateDeviceOut } from 'desktop/animations/animateDeviceOut';
import { animateMarketplace } from 'desktop/animations/animateMarketplace';
import { useTranslation } from 'react-i18next';
import DetailsTextSection from '../../components/commons/DetailsTextSection';
import { animateMainTextSection } from '../../animations/animateMainTextSection';
import { animatePageIn } from '../../animations/animatePageIn';

const PurchaseSection = () => {
  const { t } = useTranslation('buy');
  const { useScrollReady } = useScrollbar();

  useScrollReady(setupAnimations);

  return (
    <ScreenSection id={'purchase-things'}>
      <BuyDeviceContainer id={'buy-pin-first'}>
        <BuyDeviceFirst id={'purchase-first'} />
        <DetailsTextSection
          id={'purchase'}
          header={t('purchase.header')}
          title={t('purchase.title')}
          subtitle={t('purchase.subtitle')}
          description={t('purchase.description')}
        />
      </BuyDeviceContainer>
    </ScreenSection>
  );
};

const setupAnimations = () => {
  const page = animatePageIn('#purchase-things');
  const marketplace = animateMarketplace('#purchase-things');
  const deviceOut = animateDeviceOut('#buy-buy-things', '#purchase-first');
  const details = animateMainTextSection('#purchase');

  return () => {
    page.kill();
    marketplace.kill();
    deviceOut.kill();
    details.kill();
  };
};

export default PurchaseSection;
