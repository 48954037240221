import styled from 'styled-components';
import { refHeight, refWidth } from 'desktop/utils/dimensions';
import eclipse1 from 'assets/images/home/eclipses/eclipse1.svg';
import eclipse2 from 'assets/images/home/eclipses/eclipse2.svg';
import eclipse3 from 'assets/images/home/eclipses/eclipse3.svg';
import eclipse4 from 'assets/images/home/eclipses/eclipse4.svg';
import eclipse5 from 'assets/images/home/eclipses/eclipse5.svg';
import eclipse6 from 'assets/images/home/eclipses/eclipse6.svg';
import { REFERENCE_DEVICE_HEIGHT, REFERENCE_DEVICE_WIDTH } from './dimensions';
import DeviceImage from './DeviceImage';

const Container = styled.div`
  width: 100%;
  height: ${refHeight(750, REFERENCE_DEVICE_HEIGHT)}%;
  top: ${refHeight(80, REFERENCE_DEVICE_HEIGHT)}%;
  left: -${refWidth(104, REFERENCE_DEVICE_WIDTH)}%;
`;

const Eclipses = () => {
  return (
    <Container id={'home-main-eclipses'}>
      <DeviceImage src={eclipse1} left={123} top={294} width={180} height={180} />
      <DeviceImage src={eclipse2} left={68} top={239} width={290} height={290} />
      <DeviceImage src={eclipse3} left={11} top={182} width={404} height={404} />
      <DeviceImage src={eclipse4} left={-46} top={125} width={518} height={518} />
      <DeviceImage src={eclipse5} left={-103} top={68} width={632} height={632} />
      <DeviceImage src={eclipse6} left={-162} top={9} width={750} height={750} />
    </Container>
  );
};

export default Eclipses;
