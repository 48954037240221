import ExpandableHeader from 'desktop/components/commons/ExpandableHeader';
import SmoothScroll from 'desktop/components/containers/SmoothScroll';
import { useTranslation } from 'react-i18next';
import { ScrollbarProvider } from 'desktop/components/containers/ScrollbarProvider';
import LoadingOverlay from 'desktop/components/containers/LoadingOverlay';
import useOrphanPrevention from 'commons/utils/useOrphanPrevention';
import DownloadSection from '../sections/DownloadSection';
import Footer from '../sections/Footer';
import FindDealsSection from '../sections/buy/FindDealsSection';
import SearchItemsSection from '../sections/buy/SearchItemsSection';
import WasteLessSection from '../sections/buy/WasteLessSection';
import BuyWithClickSection from '../sections/buy/BuyWithClickSection';
import PurchaseSection from '../sections/buy/PurchaseSection';
import EveryDollarSection from '../sections/buy/EveryDollarSection';
import PickupGrabSection from '../sections/buy/PickupGrabSection';

const SNAP_POINTS = [
  '#buy-purchase',
  '#buy-find-deals',
  '#buy-purchases-translate',
  '#buy-search-items',
  '#buy-buy-with-click',
  '#buy-pickup-grab',
  '#buy-waste-less',
  '#download-section',
];

const Buy = () => {
  const { t } = useTranslation('buy');
  useOrphanPrevention();

  return (
    <ScrollbarProvider>
      <ExpandableHeader />
      <LoadingOverlay />
      <SmoothScroll snapPointsConfig={{ elementIds: SNAP_POINTS, snapToEnd: true }}>
        <PurchaseSection />
        <FindDealsSection />
        <EveryDollarSection />
        <SearchItemsSection />
        <BuyWithClickSection />
        <PickupGrabSection />
        <WasteLessSection />
        <DownloadSection
          subtitle={t('download.subtitle')}
          title={t('download.title')}
          downloadTitle={t('download.download-title')}
        />
        <Footer />
      </SmoothScroll>
    </ScrollbarProvider>
  );
};

export default Buy;
