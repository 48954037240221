export const PHONE_MIN_WIDTH = 310;
export const TABLET_MIN_WIDTH = 768;
export const DESKTOP_SMALL_MIN_WIDTH = 980;
export const DESKTOP_MIN_WIDTH = 1200;
export const DESKTOP_LARGE_MIN_WIDTH = 1440;

export const mq = {
  desktopLarge: `@media (min-width: ${DESKTOP_LARGE_MIN_WIDTH}px)`, // Large desktops
  desktopDefault: `@media (min-width: ${DESKTOP_MIN_WIDTH}px)`, // Medium desktops - default
  desktopSmall: `@media (max-width: ${DESKTOP_MIN_WIDTH - 0.0001}px)`, // Small laptops and desktops
  tablet: `@media (max-width: ${DESKTOP_SMALL_MIN_WIDTH - 0.0001}px)`, // Medium device (tablets)
  phone: `@media (max-width: ${TABLET_MIN_WIDTH - 0.0001}px) and (min-width: ${PHONE_MIN_WIDTH}px)`, // Small devices (landscape phones)
  landscape: '@media (orientation: landscape)',
};
