import styled from 'styled-components';
import { refWidth, refWidthLandscape } from 'mobile/utils/dimensions';
import { variantConfig, VariantType } from 'commons/types/bulletTextVariant';
import DeviceImage from '../components/commons/DeviceImage';
import BulletTextSection from '../components/commons/BulletTextSection';
import { mq } from '../../assets/styles/mediaQuery';

const Container = styled.div<{ color: string; hasTopPadding: boolean }>`
  background-color: ${({ color }) => color};
  padding-top: ${({ hasTopPadding }) => refWidth(hasTopPadding ? 50 : 0)}vw;
  padding-bottom: ${refWidth(60)}vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.landscape} {
    padding-top: ${({ hasTopPadding }) => refWidthLandscape(hasTopPadding ? 50 : 0)}vw;
    padding-bottom: ${refWidthLandscape(60)}vw;
  }
`;

const Spacer = styled.div`
  height: ${refWidth(42)}vw;
  width: 100%;

  ${mq.landscape} {
    height: ${refWidthLandscape(42)}vw;
  }
`;

interface Props {
  title: string;
  description: string;
  bullet: { ordinal: number; text?: string };
  variant: VariantType;
  image: string;
  hasTopPadding?: boolean;
}

const BulletDeviceSection = ({ image, hasTopPadding = false, ...bulletTextProps }: Props) => {
  const variantColors = variantConfig.get(bulletTextProps.variant)!;

  return (
    <Container color={variantColors.background} hasTopPadding={hasTopPadding}>
      <BulletTextSection {...bulletTextProps} />
      <Spacer />
      <DeviceImage src={image} />
    </Container>
  );
};

export default BulletDeviceSection;
