import styled from 'styled-components';
import colors from 'assets/styles/colors';
import { refFontSize, refFontSizeLandscape } from '../../utils/dimensions';
import { fonts } from '../../../assets/styles/fonts';
import { mq } from '../../../assets/styles/mediaQuery';

export const FooterText = styled.div`
  font-family: ${fonts.family.description};
  color: ${colors.fadedJade};
  font-weight: ${fonts.weight.medium};
  font-size: ${refFontSize(12)}vw;
  line-height: 2em;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(12)}vw;
  }
`;

export const FooterSubtext = styled(FooterText)`
  color: ${colors.gullGray};
`;
