import styled from 'styled-components';
import { mq } from 'assets/styles/mediaQuery';
import { MIN_SECTION_CONTAINER_HEIGHT } from './SectionContainer';

type ShortSectionVariant = 'normal' | 'bottom';
const ShortSectionContainer = styled.div<{ variant?: ShortSectionVariant }>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: ${props => (props?.variant === 'bottom' ? 'end' : 'center')};
  height: ${props => (props?.variant === 'bottom' ? '80' : '100')}vh;
  min-height: ${MIN_SECTION_CONTAINER_HEIGHT};

  ${mq.desktopSmall} {
    height: ${props => (props?.variant === 'bottom' ? '65' : '70')}vh;
  }
  ${mq.tablet} {
    height: 65vh;
  }
  ${mq.phone} {
    height: 50vh;
  }
`;

export default ShortSectionContainer;
