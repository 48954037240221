import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import img1 from 'assets/images/download/walkthrough-1.png';
import img2 from 'assets/images/download/walkthrough-2.png';
import img3 from 'assets/images/download/walkthrough-3.png';
import img4 from 'assets/images/download/walkthrough-4.png';
import img5 from 'assets/images/download/walkthrough-5.png';
import arrow from 'assets/images/arrow.svg';
import { refWidth, refWidthLandscape } from 'mobile/utils/dimensions';
import { useSwipeable } from 'react-swipeable';
import DeviceMockup from '../../../desktop/components/device/DeviceMockup';
import { mq } from '../../../assets/styles/mediaQuery';

const DEVICE_WIDTH = 242;
const DEVICE_HEIGHT = 468;
const ARROW_BUTTON_DIMENSION = 60;

const DeviceContainer = styled.div`
  position: relative;
  width: ${refWidth(DEVICE_WIDTH)}vw;
  height: ${refWidth(DEVICE_HEIGHT)}vw;
  margin-top: ${refWidth(40)}vw;
  margin-bottom: ${refWidth(20)}vw;

  ${mq.landscape} {
    width: ${refWidthLandscape(DEVICE_WIDTH)}vw;
    height: ${refWidthLandscape(DEVICE_HEIGHT)}vw;
    margin-top: ${refWidthLandscape(40)}vw;
    margin-bottom: ${refWidthLandscape(20)}vw;
  }
`;

const SlidesList = styled.div`
  position: absolute;
  overflow: hidden;
  left: ${refWidth(20)}vw;
  right: ${refWidth(20)}vw;
  top: ${refWidth(12)}vw;
  bottom: ${refWidth(12)}vw;
  border-radius: ${refWidth(20)}vw;

  ${mq.landscape} {
    left: ${refWidthLandscape(20)}vw;
    right: ${refWidthLandscape(20)}vw;
    top: ${refWidthLandscape(12)}vw;
    bottom: ${refWidthLandscape(12)}vw;
    border-radius: ${refWidthLandscape(20)}vw;
  }
`;

const SlidesListItem = styled.img`
  position: absolute;
  opacity: 0;
  left: -1%;
  right: -1%;
  width: 102%;
`;

const ArrowButton = styled.div`
  border-radius: 50%;
  width: ${refWidth(ARROW_BUTTON_DIMENSION)}vw;
  height: ${refWidth(ARROW_BUTTON_DIMENSION)}vw;
  cursor: pointer;
  position: absolute;
  transition: 0.1s;
  top: ${refWidth((DEVICE_HEIGHT - ARROW_BUTTON_DIMENSION) / 2)}vw;

  ${mq.landscape} {
    width: ${refWidthLandscape(ARROW_BUTTON_DIMENSION)}vw;
    height: ${refWidthLandscape(ARROW_BUTTON_DIMENSION)}vw;
    top: ${refWidthLandscape((DEVICE_HEIGHT - ARROW_BUTTON_DIMENSION) / 2)}vw;
  }
`;

const NextButton = styled(ArrowButton)`
  right: -${refWidth(ARROW_BUTTON_DIMENSION)}vw;

  ${mq.landscape} {
    right: -${refWidthLandscape(ARROW_BUTTON_DIMENSION)}vw;
  }
`;

const PrevButton = styled(ArrowButton)`
  left: -${refWidth(ARROW_BUTTON_DIMENSION)}vw;
  transform: rotate(180deg);

  ${mq.landscape} {
    left: -${refWidthLandscape(ARROW_BUTTON_DIMENSION)}vw;
  }
`;

const slides = [img1, img2, img3, img4, img5];

const SLIDE_TIME = 1;
const TRANSITION_TIME = 3;

const WalkthroughSlider = () => {
  const slideImgRefList = useRef<HTMLDivElement>(null);
  const activeSlideRef = useRef<number>(0);
  const prevButtonRef = useRef<HTMLDivElement>(null);
  const nextButtonRef = useRef<HTMLDivElement>(null);

  let sliderTimeline: gsap.core.Timeline;

  useEffect(() => {
    sliderTimeline = gsap.timeline({
      repeat: -1,
      onStart: () => {
        for (let i = 0; i < slides.length; i++) {
          slideImgRefList.current &&
            sliderTimeline.set(
              slideImgRefList.current.children[i],
              { xPercent: i === 0 ? 0 : 100, opacity: 1, duration: 0 },
              0
            );
        }
      },
    });

    const slideNext = (index: number) => {
      const nextIndex = index === slides.length - 1 ? 0 : index + 1;
      const timeline = gsap.timeline({
        onReverseComplete: () => {
          const label = `slide_${index}-=${TRANSITION_TIME - SLIDE_TIME}`;
          sliderTimeline.seek(label);
          sliderTimeline.play();
        },
        onStart: () => {
          activeSlideRef.current = nextIndex;
        },
      });
      slideImgRefList.current &&
        timeline.fromTo(
          slideImgRefList.current.children[index],
          { xPercent: 0 },
          { xPercent: -100, duration: SLIDE_TIME, ease: 'power3.inOut' },
          0
        );
      slideImgRefList.current &&
        timeline.fromTo(
          slideImgRefList.current.children[nextIndex],
          { xPercent: 100 },
          { xPercent: 0, duration: SLIDE_TIME, ease: 'power3.inOut' },
          0
        );
      return timeline;
    };

    for (let i = 0; i < slides.length; i++) {
      sliderTimeline.addLabel(`slide_${i}`, `>${TRANSITION_TIME}`);
      sliderTimeline.add(slideNext(i), `>${TRANSITION_TIME}`);
    }

    return () => {
      sliderTimeline.kill();
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const handlers = useSwipeable({
    onSwipedLeft: () => onNextClick(),
    onSwipedRight: () => onPrevClick(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const onNextClick = () => {
    if (sliderTimeline) {
      sliderTimeline.seek(`slide_${activeSlideRef.current}`);
      sliderTimeline.play();
    }
  };

  const onPrevClick = () => {
    if (sliderTimeline) {
      const transitionSlide =
        activeSlideRef.current > 0 ? `slide_${activeSlideRef.current}-=${TRANSITION_TIME}` : `slide_${slides.length}`;
      sliderTimeline.seek(transitionSlide);
      sliderTimeline.reverse();
      activeSlideRef.current = activeSlideRef.current > 0 ? activeSlideRef.current - 1 : slides.length - 1;
    }
  };

  return (
    <>
      <DeviceContainer {...handlers}>
        <DeviceMockup />
        <SlidesList ref={slideImgRefList}>
          {slides.map((slide, index) => {
            return <SlidesListItem key={index} alt={'walkthrough-img'} src={slide} />;
          })}
        </SlidesList>

        <PrevButton ref={prevButtonRef} onClick={onPrevClick}>
          <img alt='prev-button' src={arrow} width={'100%'} />
        </PrevButton>
        <NextButton ref={nextButtonRef} onClick={onNextClick}>
          <img alt='next-button' src={arrow} width={'100%'} />
        </NextButton>
      </DeviceContainer>
    </>
  );
};

export default WalkthroughSlider;
