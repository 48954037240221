import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export const animateCarouselMain = (carouselId: string, trigger: string, endTrigger: string, sections: number) => {
  const moveFrom = (1 / sections) * 100; // start from 25% when 4 sections
  const moveTo = -((sections - 1) / sections) * 100; // to -75% when 4 sections

  const animation = gsap.timeline().fromTo(carouselId, { xPercent: moveFrom }, { xPercent: moveTo, ease: 'linear' });

  const scrollTrigger = ScrollTrigger.create({ animation, trigger, endTrigger, scrub: true });

  return {
    kill: () => {
      scrollTrigger.kill();
      animation.kill();
    },
  };
};
