import styled from 'styled-components';
import { refWidth } from 'desktop/utils/dimensions';

export const LIST_DEFAULT_PADDING = 40;

export const StyledOl = styled.ol<{ listStyle?: string; addPadding?: number }>`
  list-style-position: inside;
  list-style-type: ${props => (props.listStyle ? props.listStyle : 'disc')};
  padding-inline-start: ${props => (props.addPadding ? refWidth(props.addPadding) : 0)}vw;
`;

export const SubPoint = styled.li`
  list-style-position: inside;

  ::marker {
    content: counter(list-item) ')';
  }

  ::before {
    content: '';
    width: ${refWidth(LIST_DEFAULT_PADDING / 2)}vw;
    display: inline-block;
  }
`;

export const ContentList = styled.ol`
  padding-inline-start: ${refWidth(LIST_DEFAULT_PADDING)}vw;
`;

export const TopicList = styled.ol`
  padding-inline-start: 0;
`;

export const TopicPoint = styled.li`
  padding-left: ${refWidth(LIST_DEFAULT_PADDING)}vw;
`;

export const LvlTwoLi = styled.li`
  padding-inline-start: ${refWidth(LIST_DEFAULT_PADDING)}vw;
`;
