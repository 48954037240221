import { BuyDeviceContainer } from 'desktop/components/buy/BuyDeviceContainer';
import BuyDeviceSecond from 'desktop/components/buy/BuyDeviceSecond';
import { useScrollbar } from 'desktop/components/containers/ScrollbarProvider';
import { animatePinDevice } from 'desktop/animations/animatePinDevice';
import { animateSimpleDevice } from 'desktop/animations/animateSimpleDevice';
import { useTranslation } from 'react-i18next';
import BulletTextSection from '../../components/commons/BulletTextSection';
import CarouselContainer from '../../components/containers/CarouselContainer';
import { animateCarouselIn } from '../../animations/animateCarouselIn';
import { animateCarouselMain } from '../../animations/animateCarouselMain';
import PinnedScreenSection from '../../components/containers/PinnedScreenSection';

const SearchItemsSection = () => {
  const { t } = useTranslation('buy');
  const { useScrollReady } = useScrollbar();

  useScrollReady(setupAnimations);

  return (
    <PinnedScreenSection id={'buy-search-items'}>
      <BuyDeviceContainer id={'buy-pin-second'}>
        <BuyDeviceSecond id={'buy-device-second'} />
        <CarouselContainer id={'buy-carousel'}>
          <BulletTextSection
            title={t('search-items.title')}
            description={t('search-items.description')}
            sectionClassName={'buy-search-items'}
            bullet={{ ordinal: 1 }}
            reversed={true}
            variant={'buy'}
            width={'big'}
            margin={'medium'}
          />
          <BulletTextSection
            title={t('buy-with-click.title')}
            description={t('buy-with-click.description')}
            sectionClassName={'buy-buy-with-click'}
            bullet={{ ordinal: 2 }}
            reversed={true}
            variant={'buy'}
            width={'big'}
            margin={'medium'}
          />
          <BulletTextSection
            title={t('pickup-grab.title')}
            description={t('pickup-grab.description')}
            sectionClassName={'pickup-grab'}
            bullet={{ ordinal: 3 }}
            reversed={true}
            variant={'buy'}
            width={'big'}
            margin={'medium'}
          />
        </CarouselContainer>
      </BuyDeviceContainer>
    </PinnedScreenSection>
  );
};

const setupAnimations = () => {
  const trigger = '#buy-search-items';

  const pinDevice = animatePinDevice(trigger, '#buy-buy-with-click', '#buy-pin-second');
  const simpleDevice = animateSimpleDevice(trigger, '#device-search');
  const carouselIn = animateCarouselIn(trigger, '.buy-search-items-text');
  const carouselMain = animateCarouselMain('#buy-carousel', trigger, '#buy-buy-with-click', 3);

  return () => {
    pinDevice.kill();
    simpleDevice.kill();
    carouselIn.kill();
    carouselMain.kill();
  };
};

export default SearchItemsSection;
