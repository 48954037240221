import React from 'react';
import styled from 'styled-components';
import { refWidth } from 'desktop/utils/dimensions';
import colors from '../../../assets/styles/colors';
import { animations } from '../../../assets/styles/animations';

export const DESKTOP_MENU_BETWEEN_DISTANCE = refWidth(10);

const Menu = styled.div<{ open: boolean; refBetweenDistance: number }>`
  height: ${({ refBetweenDistance }) => 2 * refBetweenDistance}vw;
  width: ${({ refBetweenDistance }) => 4 * refBetweenDistance}vw;
  cursor: pointer;
  &:hover {
    div {
      &:nth-child(3) {
        width: ${({ refBetweenDistance }) => 4 * refBetweenDistance}vw;
      }
    }
  }
  div {
    position: absolute;
    height: 0;
    width: ${({ refBetweenDistance }) => 4 * refBetweenDistance}vw;
    border: ${refWidth(1)}vw solid;
    border-color: ${({ open }) => (open ? colors.red : colors.brightGray)};
    transition: all ${animations.standardDuration} linear;
    &:nth-child(1) {
      top: ${({ open, refBetweenDistance }) => (open ? refBetweenDistance : 0)}vw;
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    &:nth-child(2) {
      top: ${({ refBetweenDistance }) => refBetweenDistance}vw;
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      top: ${({ open, refBetweenDistance }) => (open ? refBetweenDistance : 2 * refBetweenDistance)}vw;
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
      width: ${({ open, refBetweenDistance }) => (open ? 4 * refBetweenDistance : 2 * refBetweenDistance)}vw;
    }
  }
`;

interface Props {
  className?: string;
  onClick: () => void;
  open: boolean;
}

const MenuButton = ({ className, onClick, open }: Props) => {
  return (
    <Menu className={className} open={open} onClick={onClick} refBetweenDistance={DESKTOP_MENU_BETWEEN_DISTANCE}>
      <div />
      <div />
      <div />
    </Menu>
  );
};

export default MenuButton;
