import React from 'react';
import { useTranslation } from 'react-i18next';
import helpEnvironmentImg from 'assets/images/saveThePlanet/help-environment.png';
import PhotoTextSection from '../../components/commons/PhotoTextSection';
import ShortSectionContainer from '../../components/containers/ShortSectionContainer';
import StyledScrollDownInfo from '../../components/commons/StyledScrollDownInfo';

const HelpEnvironmentSection = () => {
  const { t } = useTranslation('saveThePlanet');

  return (
    <ShortSectionContainer id={'save-the-plenet-help-environment'}>
      <PhotoTextSection
        sectionClassName={'save-the-plenet-help-environment'}
        title={t('help-environment.title')}
        description={t('help-environment.description')}
        image={{ source: helpEnvironmentImg, dimensions: { height: 505, width: 920 } }}
        margin={'medium'}
        width={'medium'}
        titleFont={'big'}
      />
      <StyledScrollDownInfo />
    </ShortSectionContainer>
  );
};

export default HelpEnvironmentSection;
