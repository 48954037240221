import gsap from 'gsap';
import { createShowHideTrigger } from './createShowHideTrigger';

export const animateMainTextSection = (sectionId: string) => {
  const animMain = gsap
    .timeline()
    .fromTo(sectionId + ' > div', { opacity: 0, y: 20 }, { opacity: 1, y: 0, stagger: 0.25 });

  const animOut = gsap.timeline({ paused: true }).fromTo(sectionId, { opacity: 1 }, { opacity: 0 });

  const triggerGoal = createShowHideTrigger(sectionId, animMain, animOut);

  return {
    kill: () => {
      triggerGoal.kill();
      animMain.kill();
      animOut.kill();
    },
  };
};
