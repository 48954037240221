import React from 'react';
import styled from 'styled-components';
import { isPortrait } from '../../utils/isPortrait';
import colors from '../../../assets/styles/colors';
import { animations } from '../../../assets/styles/animations';
import { useScreenSize } from '../../utils/useScreenSize';

const Menu = styled.div<{ open: boolean; refBetweenDistance: number; scale: number }>`
  height: ${({ refBetweenDistance }) => 2 * refBetweenDistance}px;
  width: ${({ refBetweenDistance }) => 4 * refBetweenDistance}px;
  transform: scale(${({ scale }) => scale / 1000});
  transform-origin: right;

  div {
    position: absolute;
    height: ${({ refBetweenDistance }) => Math.floor(refBetweenDistance / 6)}px;
    width: ${({ refBetweenDistance }) => 4 * refBetweenDistance}px;
    background-color: ${({ open }) => (open ? colors.red : colors.brightGray)};
    transition: all ${animations.standardDuration} linear;

    &:nth-child(1) {
      top: ${({ open, refBetweenDistance }) => (open ? refBetweenDistance : 0)}px;
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      top: ${({ refBetweenDistance }) => refBetweenDistance}px;
      opacity: ${({ open }) => (open ? 0 : 1)};
    }

    &:nth-child(3) {
      top: ${({ open, refBetweenDistance }) => (open ? refBetweenDistance : 2 * refBetweenDistance)}px;
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
      width: ${({ open, refBetweenDistance }) => (open ? 4 * refBetweenDistance : 2 * refBetweenDistance)}px;
    }
  }
`;

interface Props {
  className?: string;
  onClick: () => void;
  open: boolean;
}

const MenuButton = ({ className, onClick, open }: Props) => {
  const { width } = useScreenSize();
  return (
    <Menu className={className} open={open} onClick={onClick} refBetweenDistance={isPortrait() ? 20 : 10} scale={width}>
      <div />
      <div />
      <div />
    </Menu>
  );
};

export default MenuButton;
