import styled from 'styled-components';
import { fonts } from 'assets/styles/fonts';
import { refFontSize, refFontSizeCalc, refHeight } from 'desktop/utils/dimensions';
import colors from 'assets/styles/colors';
import { mq } from 'assets/styles/mediaQuery';

export const Subtitle = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.bold};
  font-size: ${refFontSize(18)}vw;
  line-height: 1.77em;
  margin-top: ${refHeight(40)}vh;
  color: ${colors.cello};
  ${mq.desktopSmall} {
    font-size: ${refFontSizeCalc(18, 1.15)}vw;
  }
  ${mq.tablet} {
    font-size: ${refFontSizeCalc(18, 1.25)}vw;
  }
`;

export const Description = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  font-size: ${refFontSize(18)}vw;
  line-height: 1.77em;
  color: ${colors.fadedJade};
  margin-top: ${refHeight(10)}vh;
  ${mq.desktopSmall} {
    font-size: ${refFontSizeCalc(18, 1.15)}vw;
  }
  ${mq.tablet} {
    font-size: ${refFontSizeCalc(18, 1.25)}vw;
  }
`;
