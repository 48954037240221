import React from 'react';
import styled from 'styled-components';
import { animations } from 'assets/styles/animations';
import colors from 'assets/styles/colors';
import { mq } from 'assets/styles/mediaQuery';

const Container = styled.div`
  width: 22px;
  height: 22px;
  position: relative;
  overflow: hidden;
  align-self: center;

  ${mq.desktopSmall} {
    width: 20px;
    height: 20px;
  }
  ${mq.tablet} {
    width: 16px;
    height: 16px;
  }
  ${mq.phone} {
    width: 12px;
    height: 12px;
  }
`;

const StyledSpan = styled.div<{ rolledDown: boolean }>`
  position: absolute;
  width: 18px;
  height: 2px;
  background-color: ${colors.red};
  transition: ${animations.standardDuration} ease-in-out;
  top: 50%;

  ${mq.desktopSmall} {
    width: 16px;
  }
  ${mq.tablet} {
    width: 12px;
  }
  ${mq.phone} {
    width: 8px;
  }

  &:nth-child(2) {
    opacity: ${({ rolledDown }) => (rolledDown ? 0 : 1)};
    transform: ${({ rolledDown }) => (rolledDown ? 'rotate(0)' : 'rotate(90deg)')};
  }
`;

interface Props {
  isRolledDown: boolean;
}

const Sign = (props: Props) => {
  return (
    <Container>
      <StyledSpan rolledDown={props.isRolledDown} />
      <StyledSpan rolledDown={props.isRolledDown} />
    </Container>
  );
};

export default Sign;
